import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { sendCrmChangePasswordOTPPending, sendCrmChangePasswordOTPSuccess, sendCrmChangePasswordOTPFailure } from "../slice/crmChangePasswordOTPSubmitSlice";
import { config } from "../../../config/global";
const API_URL = config?.CRM_CHANGE_PASSWORD_SUBMIT;
const sendCRMChangePasswordOTPData = async otpData => {
  const data = {
    otpRefKey: otpData.otpRefKey,
    otp: otpData.otp,
    newPin: sessionStorage.getItem("newPin")
  };
  const headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    }
  };
  const response = await api.post(API_URL, data, headerData);
  return response;
};
function* sendOTPData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(sendCRMChangePasswordOTPData, payload);
    yield put(sendCrmChangePasswordOTPSuccess(response.data));
  } catch (error) {
    yield put(sendCrmChangePasswordOTPFailure(error));
  }
}
export function* watchCRMChangePasswordSendOTPData() {
  yield takeLatest(sendCrmChangePasswordOTPPending.type, sendOTPData);
}