import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { getExpiryMilesReviewPending, getExpiryMilesReviewSuccess, getExpiryMilesReviewFailure, extendMilesPaymentInitRequest, extendMilesPaymentInitSuccess, extendMilesPaymentInitFailure, getExtendMilesReceiptDownloadRequest, getExtendMilesReceiptDownloadSuccess, getExtendMilesReceiptDownloadFailed } from "../slice/fetchExtendExpiryMileSlice";
// import { MemberTierCalclulator } from "../models/models";
import { config } from "../../../config/global";
const EXPIRING_MILES_REVIEW_API_URL = config.EXPIRING_MILES_REVIEW;
const fetchExpiryMilesReviewData = async ExpiryMilesReviewPayload => {
  let headerData = {
    headers: {
      ExpiryMilesId: sessionStorage.getItem("ExpiryMilesId"),
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    isGenericErr: true,
    loader: true
  };
  const response = await api.post(EXPIRING_MILES_REVIEW_API_URL, ExpiryMilesReviewPayload, headerData);
  return response;
};
function* postExpiryMilesReviewData(action) {
  try {
    const response = yield call(fetchExpiryMilesReviewData, action.payload);
    yield put(getExpiryMilesReviewSuccess(response.data));
    console.log("response", response.data);
  } catch (error) {
    yield put(getExpiryMilesReviewFailure(error));
  }
}
const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const fetchExtendMilesPaymentInit = async payload => {
  const url = config.EXTEND_MILES_PAYMENT_INIT;
  const headerData = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: accessToken,
      memberId: memberId
    }
  };
  let payloadData = {
    miles: payload.miles,
    currency: payload.currency,
    paymentMethod: payload.paymentMethod
  };
  const response = await api.post(url, payloadData, headerData);
  return response;
};
const fetchExtendMilesReceiptDownload = async payload => {
  const DOWNLOAD_URL = config.EXTEND_MILES_DOWNLOAD_RECEIPT;
  const headerData = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: accessToken
      // memberId: memberId,
    }
  };
  const response = await api.post(DOWNLOAD_URL, payload, headerData);
  return response;
};
function* getExtendMilesReceiptDownload(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchExtendMilesReceiptDownload, payload);
    yield put(getExtendMilesReceiptDownloadSuccess(response?.data));
  } catch (error) {
    yield put(getExtendMilesReceiptDownloadFailed(error));
  }
}
function* getExtendMilesPaymentInit(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchExtendMilesPaymentInit, payload);
    yield put(extendMilesPaymentInitSuccess(response?.data));
  } catch (error) {
    yield put(extendMilesPaymentInitFailure(error));
  }
}
export function* watchExpiryMilesReviewData() {
  yield takeLatest(getExpiryMilesReviewPending.type, postExpiryMilesReviewData);
}
export function* watchExtendMilesPaymentInit() {
  yield takeEvery(extendMilesPaymentInitRequest.type, getExtendMilesPaymentInit);
}
export function* watchExtendMilesReceiptDownload() {
  yield takeEvery(getExtendMilesReceiptDownloadRequest.type, getExtendMilesReceiptDownload);
}