import { call, put, takeLatest } from "redux-saga/effects";
import { retrieveJourneyRequest, retrieveJourneySuccess, retrieveJourneyFailure } from "../../slice/checkin/checkinSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchRetrieveJourneyData = async retrieveCheckIn => {
  const RETRIVE_CHECKIN_API_URL = config.MY_TRIPS_CHECK_IN_API;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  let retrieveeJourneyPayload = {
    ref: retrieveCheckIn.pnr,
    lastName: retrieveCheckIn.lastName
  };
  const response = await api.post(`${RETRIVE_CHECKIN_API_URL}`, retrieveeJourneyPayload, data);
  return response;
};
function* getRetrieveJourneyData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchRetrieveJourneyData, payload);
    sessionStorage.setItem("checkintoken", response?.headers?.accesstoken);
    yield put(retrieveJourneySuccess(response.data));
  } catch (error) {
    yield put(retrieveJourneyFailure(error));
  }
}
export function* watchRetrieveJourneyData() {
  yield takeLatest(retrieveJourneyRequest.type, getRetrieveJourneyData);
}