import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getCrmLoginDataPending, getCrmLoginDataSuccess, getCrmLoginDataFailure, getCrmSocialMediaValidationPending, getCrmSocialMediaValidationSuccess, getCrmSocialMediaValidationFailure } from "../slice/crmLoginSlice";
import { config } from "../../../config/global";
const CRM_LOGIN_API_URL = config?.CRM_LOGIN;
const CRM_SOCIAL_VALIDATION_URL = config?.SOCIAL_MEDIA_AUTH;
const fetchCrmLoginData = async loginPayload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    }
  };
  let crmLoginPayloadData = {
    username: loginPayload.email,
    password: loginPayload.password
  };
  const response = await api.post(CRM_LOGIN_API_URL, crmLoginPayloadData);
  sessionStorage.setItem("temptoken", response.headers.authorization);
  return response;
};
function* getCrmLoginData(action) {
  try {
    const response = yield call(fetchCrmLoginData, action.payload);
    yield put(getCrmLoginDataSuccess(response.data));
  } catch (error) {
    yield put(getCrmLoginDataFailure(error));
  }
}
const fetchCrmSocialMediaValidationData = async loginValidationPayload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  let crmLoginPayloadData = {
    accessToken: loginValidationPayload.accessToken,
    socialMediaType: loginValidationPayload.socialMediaType
  };
  let response = await api.post(CRM_SOCIAL_VALIDATION_URL, crmLoginPayloadData, data);
  response.data.socialMediaType = loginValidationPayload.socialMediaType;
  response.data.socialMediaData = loginValidationPayload.socialMediaData;
  if (response.headers.authorization) {
    localStorage.setItem("isCRMLoggedIn", JSON.stringify(true));
    localStorage.setItem("accesstoken", response.headers.authorization);
  }
  return response;
};
function* getCrmSocialMediaValidationData(action) {
  try {
    const response = yield call(fetchCrmSocialMediaValidationData, action.payload);
    yield put(getCrmSocialMediaValidationSuccess(response.data));
  } catch (error) {
    yield put(getCrmSocialMediaValidationFailure(error));
  }
}
export function* watchCrmLoginDataValidation() {
  yield takeLatest(getCrmSocialMediaValidationPending.type, getCrmSocialMediaValidationData);
}
export function* watchCrmLoginData() {
  yield takeLatest(getCrmLoginDataPending.type, getCrmLoginData);
}