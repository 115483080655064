import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { fetchBaggageAllowanceFailure, fetchBaggageAllowanceRequest, fetchBaggageAllowanceSuccess, fetchSeatPreferenceFailure, fetchSeatPreferenceRequest, fetchSeatPreferenceSuccess } from "../slice/extraBaggageAllowanceSlice";
const EXTRA_BAGGAGE_URL = config?.BAGGAGE_ALLOWANCE;
const SEAT_PREFERENCE_URL = config?.SEAT_PREFERENCE;
const accessToken = localStorage.getItem("accesstoken");
const extraBaggageAllowanceCallback = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true
  };
  let baggagePayload = {
    origin: payload.origin,
    destination: payload.destination
  };
  const response = await api.post(EXTRA_BAGGAGE_URL, baggagePayload, data);
  return response;
};
function* getBaggageRateData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(extraBaggageAllowanceCallback, payload);
    yield put(fetchBaggageAllowanceSuccess(response.data));
  } catch (error) {
    yield put(fetchBaggageAllowanceFailure(error.message));
  }
}
export function* watchExtraBaggageAllowanceData() {
  yield takeLatest(fetchBaggageAllowanceRequest.type, getBaggageRateData);
}
const seatPreferenceCallback = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true
  };
  let seatPreferencePayload = {
    origin: payload.origin,
    destination: payload.destination,
    bookingClass: payload.bookingClass.toUpperCase()
  };
  const response = await api.post(SEAT_PREFERENCE_URL, seatPreferencePayload, data);
  return response;
};
function* getSeatPreferenceData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(seatPreferenceCallback, payload);
    yield put(fetchSeatPreferenceSuccess(response.data));
  } catch (error) {
    yield put(fetchSeatPreferenceFailure(error.message));
  }
}
export function* watchSeatPreferenceData() {
  yield takeLatest(fetchSeatPreferenceRequest.type, getSeatPreferenceData);
}