import { call, put, takeLatest } from "redux-saga/effects";
import { ffpGenerateOtpRefKey, ffpGenerateOtpRefKeySuccess, ffpGenerateOtpRefKeyFailure, ffpSubmitOtpRef, ffpSubmitOtpRefSuccess, ffpSubmitOtpRefFailure, generateFfpResendOtp, generateFfpResendOtpSuccess, generateFfpResendOtpFailure } from "../../slice/checkin/passengerDetails/ffpGenerateOtpSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchOtpRefKey = async ffpData => {
  const FETCH_OTP_REF_KEY = config.CHECKIN_SEND_OTP;
  const data = {
    mode: "no-cors",
    headers: {
      AccessToken: sessionStorage.getItem("checkintoken"),
      "Content-Type": "application/json",
      "Accept-Language": `${i18n.language}`
    },
    loader: true,
    isGenericErr: true
  };
  const ffpPayloadData = {
    companyCode: ffpData.companyCode,
    cardNumber: ffpData.cardNumber,
    journeyElementId: ffpData.journeyElementId
  };
  const response = await api.post(`${FETCH_OTP_REF_KEY}`, ffpPayloadData, data);
  if (response) {
    sessionStorage.setItem("refNumber", response?.data.otpRefKey);
    sessionStorage.setItem("mail", response?.data.email);
    sessionStorage.setItem("otptoken", response.headers["otptoken"]);
  }
  return response;
};
function* getGenerateOtpRefKeyData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchOtpRefKey, payload);
    yield put(ffpGenerateOtpRefKeySuccess(response.data));
  } catch (error) {
    yield put(ffpGenerateOtpRefKeyFailure(error));
  }
}
export function* watchGenerateOtpRefKeyData() {
  yield takeLatest(ffpGenerateOtpRefKey.type, getGenerateOtpRefKeyData);
}
const submitOtpRefKey = async otpData => {
  console.log(otpData, "otpData");
  const FETCH_OTP_REF_KEY = config.CHECKIN_ADD_FFP;
  const data = {
    mode: "no-cors",
    headers: {
      AccessToken: sessionStorage.getItem("checkintoken"),
      "Content-Type": "application/json",
      otpToken: sessionStorage.getItem("otptoken"),
      "Accept-Language": `${i18n.language}`
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(`${FETCH_OTP_REF_KEY}`, otpData, data);
  return response;
};
function* getFfpSubmitOtpData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(submitOtpRefKey, payload);
    yield put(ffpSubmitOtpRefSuccess(response.data));
  } catch (error) {
    yield put(ffpSubmitOtpRefFailure(error));
  }
}
export function* watchFfpSubmitOtp() {
  yield takeLatest(ffpSubmitOtpRef.type, getFfpSubmitOtpData);
}
const resendFfpOtp = async emailId => {
  const FETCH_OTP_REF_KEY = config.CHECKIN_RESEND_OTP;
  const data = {
    mode: "no-cors",
    headers: {
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json",
      AccessToken: sessionStorage.getItem("checkintoken"),
      otpToken: sessionStorage.getItem("otptoken")
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(`${FETCH_OTP_REF_KEY}`, emailId, data);
  return response;
};
function* getCheckinResendOtp(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(resendFfpOtp, payload);
    yield put(generateFfpResendOtpSuccess(response.data));
  } catch (error) {
    yield put(generateFfpResendOtpFailure(error));
  }
}
export function* watchGenerateFfpResendOtp() {
  yield takeLatest(generateFfpResendOtp.type, getCheckinResendOtp);
}