import { call, put, takeEvery } from "redux-saga/effects";
import { flightInfoFail, flightInfoRequest, flightInfoSuccess } from "../slice/flightInfoSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
let API_URL;
const fetchFlightInfo = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  if (!payload?.isMultiCity) {
    API_URL = config?.FLIGHT_INFO_API;
  } else {
    API_URL = `${config?.FLIGHT_INFO_API}?multicity=true`;
  }
  let searchPayload = {
    flightInfo: {
      departure: payload?.info?.flightInfo?.departure,
      arrival: payload?.info?.flightInfo?.arrival,
      departureDate: payload?.info?.flightInfo?.departureDate
    },
    tripType: payload?.tripType
  };
  const response = await api.post(API_URL, searchPayload, headerData);
  response.data.flightLength = response.data?.flightList?.length;
  return response;
};
const starAirportSearchCallback = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  let searchPayload = {
    flightInfo: {
      departure: payload?.info?.flightInfo?.departure,
      arrival: payload?.info?.flightInfo?.arrival,
      departureDate: payload?.info?.flightInfo?.departureDate
    },
    zoneDirection: payload?.info?.zoneDirection,
    tripType: payload?.tripType
  };
  const response = await api.post(config.STAR_ALLIANCE_SEARCH, searchPayload, data);
  response.data.flightLength = response.data?.flightList?.length;
  return response;
};
function* fetchFlightInfoSaga(action) {
  const {
    payload
  } = action;
  try {
    let response;
    if (!payload?.isStarAlliance) {
      response = yield call(fetchFlightInfo, payload);
    } else {
      response = yield call(starAirportSearchCallback, payload);
    }
    yield put(flightInfoSuccess({
      ...response,
      moreInfo: payload.moreInfo,
      id: payload.id
    }));
  } catch (error) {
    yield put(flightInfoFail({
      ...error?.response,
      moreInfo: payload.moreInfo,
      id: payload.id
    }));
  }
}
export function* watchFlightInfo() {
  yield takeEvery(flightInfoRequest.type, fetchFlightInfoSaga);
}