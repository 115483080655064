import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { getgoldtrackFromDataSuccess, getgoldtrackFromDataFailure, goldtrackFromDataRequest, goldtrackrequest, goldtracksuccess, goldtrackFailure } from "../slice/goldtrackSlice";
const accessToken = localStorage.getItem("accesstoken");
const headerData = {
  mode: "no-cors",
  headers: {
    Authorization: accessToken,
    "Content-Type": "application/json"
  },
  loader: true
  // isGenericErr: true,
};
const API_URL = config.GOLD_TRACK + '?isStarEnabled=true&isActive=true';
const API_URLS = config.GOLT_TRACK_INFORMATION + '?airportCode=';
const goldtrackInit = async payload => {
  const response = await api.get(API_URL, headerData);
  return response;
};
const goldtrackinfo = async payload => {
  // payload?.airportCode
  const response = await api.get(API_URLS + payload?.airportCode, headerData);
  return response;
};
function* getgoldtrackinformation(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(goldtrackinfo, payload);
    yield put(goldtracksuccess(response.data));
  } catch (error) {
    yield put(goldtrackFailure(error.message));
  }
}
function* getgoldtrackFromData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(goldtrackInit, payload);
    yield put(getgoldtrackFromDataSuccess(response.data));
  } catch (error) {
    yield put(getgoldtrackFromDataFailure(error.message));
  }
}
export function* watchgoldtrackFromData() {
  yield takeLatest(goldtrackFromDataRequest.type, getgoldtrackFromData);
}
export function* watchgoldtrackinformationdata() {
  yield takeLatest(goldtrackrequest.type, getgoldtrackinformation);
}