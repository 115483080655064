import { call, put, takeLatest } from "redux-saga/effects";
import { cancelCheckinRequest, cancelCheckinSuccess, cancelCheckinFailure } from "../../slice/cancelCheckin/cancelCheckinSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchcancelCheckin = async cancelCheckin => {
  const API_URL = config.DAPI_CANCEL_CHECKIN;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("checkintoken"),
      "Content-Type": "application/json",
      "Accept-Language": `${i18n.language}`
    },
    loader: true,
    isGenericErr: true
  };
  let cancelAllCheckinPayload = {
    journeyId: cancelCheckin?.journeyId,
    isCancelAll: cancelCheckin?.isCancelAll
  };
  let cancelCheckinPayload = {
    journeyId: cancelCheckin?.journeyId,
    journeyElementIds: cancelCheckin?.travelerIds,
    isCancelAll: cancelCheckin?.isCancelAll
  };
  let payload = cancelCheckin?.travelerIds !== null ? cancelCheckinPayload : cancelAllCheckinPayload;
  const response = await api.post(API_URL, payload, data);
  return response;
};
function* getcancelCheckin(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchcancelCheckin, payload);
    yield put(cancelCheckinSuccess(response.data));
  } catch (error) {
    yield put(cancelCheckinFailure(error));
  }
}
export function* watchcancelCheckin() {
  yield takeLatest(cancelCheckinRequest.type, getcancelCheckin);
}