import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  countryList: [],
  isLoading: false,
  error: "",
  hasData: false,
  subscriberDetailsData: "",
  subscriberDetailsError: ""
};
const preferenceFromSlice = createSlice({
  name: "preferenceFrom",
  initialState,
  reducers: {
    preferenceFromDataRequest: state => {
      state.isLoading = true;
    },
    getPreferenceFromDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      let preference = [];
      preference = action.payload.map(item => ({
        city: item.city,
        country: item.city + "-" + item.country,
        code: item.code,
        airportCityCode: item.airportCityCode
      }));
      state.countryList = preference;
    },
    getPreferenceFromDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    getSubscriberTermsCondistionRequest: state => {
      state.isLoading = true;
    },
    getSubscriberTermsCondistioSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.subscriberDetailsData = action.payload;
      state.subscriberDetailsError = "";
    },
    getSubscriberTermsCondistioFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.subscriberDetailsError = action.payload;
    }
  }
});
export const {
  preferenceFromDataRequest,
  getPreferenceFromDataSuccess,
  getPreferenceFromDataFailure,
  getSubscriberTermsCondistioSuccess,
  getSubscriberTermsCondistionRequest,
  getSubscriberTermsCondistioFailure
} = preferenceFromSlice.actions;
export default preferenceFromSlice.reducer;