import { call, put, takeEvery } from "redux-saga/effects";
import { config } from "../../../config/global";
import { mealsListRequest, mealsListSuccess, mealsListFailure } from "../slice/mealsListSlice";
import api from "../../../config/api";
const MEALS_LIST_API_URL = config.MEALS_LIST;
const mealsListData = async payload => {
  const data = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.post(MEALS_LIST_API_URL, payload, data);
  return response;
};
function* fetchMealsList(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(mealsListData, payload);
    yield put(mealsListSuccess(response));
  } catch (error) {
    yield put(mealsListFailure(error?.response));
  }
}
export function* watchMealsList() {
  yield takeEvery(mealsListRequest.type, fetchMealsList);
}