import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  nationalityCountryData: {},
  isLoading: false,
  error: "",
  hasData: false
};
const fetchNationalityCountrySlice = createSlice({
  name: "nationalityCountry",
  initialState,
  reducers: {
    nationalityCountryRequest: state => {
      state.isLoading = true;
    },
    nationalityCountrySuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.nationalityCountryData = action?.payload;
    },
    nationalityCountryFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = action?.payload?.response?.data;
      state.nationalityCountryData = {};
    }
  }
});
export const {
  nationalityCountryRequest,
  nationalityCountrySuccess,
  nationalityCountryFailure
} = fetchNationalityCountrySlice.actions;
export default fetchNationalityCountrySlice.reducer;