import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getTripsPnrDetailsFailure, getTripsPnrDetailsRequest, getTripsPnrDetailsSuccess } from "../slice/pnrDetailsSlice";
const PNR_URL = "/trip/retrieve-pnr";
const accessToken = localStorage.getItem("accesstoken");
const PnrData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true
  };
  let pnrPayload = {
    pnr: payload
  };
  const response = await api.post(PNR_URL, pnrPayload, data);
  return response;
};
const RetrievePnrData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true
  };
  let retrivePayload = {
    pnr: payload?.pnr,
    lastName: payload?.lastName,
    pastTrip: false
  };
  const response = await api.post(PNR_URL, retrivePayload, data);
  return response;
};
function* getPNRDetailsData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(PnrData, payload);
    yield put(getTripsPnrDetailsSuccess(response.data));
  } catch (error) {
    yield put(getTripsPnrDetailsFailure(error.message));
  }
}
export function* watchPNRDetailsSaga() {
  yield takeLatest(getTripsPnrDetailsRequest.type, getPNRDetailsData);
}