import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { claimMissingRouteFailure, claimMissingRouteSuccess, claimMissingRouteRequest, getClaimDataSuccess, getClaimDataFailure, getClaimDataRequest, getClaimDataStarSuccess, getClaimDataStarFailure, getClaimDataStarRequest, claimAirlineSuccess, claimAirlineFailure, claimAirlineRequest } from "../slice/claimMissingMilesSlice";
import { config } from "../../../../src/config/global";
const CLAIM_STATUS_NUMBER_URL = config?.CLAIM_MISSING_MILES_API;
const CLAIMS_STATUS_TG_URL = config?.CLAIM_MISSING_STAR_API;
const CLAIM_STATUS_ROUTE_URL = config?.CLAIM_STATUS_ROUTE_API;
const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const ClaimMissingData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
      memberId
    },
    loader: true,
    isGenericErr: true
  };
  let ClaimMissingPayload = {
    memberId: payload?.memberId,
    airlineCode: payload?.airlineCode,
    flightNumber: payload?.flightNumber,
    flightDate: payload?.flightDate,
    origin: payload?.origin,
    destination: payload?.destination,
    seatNumber: payload?.seatNumber,
    name: payload?.name,
    surname: payload?.surname,
    ticketNumber: payload?.ticketNumber,
    bookingClass: payload?.bookingClass
  };
  const response = await api.post(CLAIM_STATUS_NUMBER_URL, ClaimMissingPayload, data);
  return response;
};
const ClaimMissingStartData = async payload => {
  let starData = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
      memberId
    },
    loader: true,
    isGenericErr: true
  };
  let ClaimMissingStarPayload = {
    memberId: payload?.memberId,
    airlineCode: payload?.airlineCode,
    flightNumber: payload?.flightNumber,
    flightDate: payload?.flightDate,
    origin: payload?.origin,
    destination: payload?.destination,
    seatNumber: payload?.seatNumber,
    name: payload?.name,
    surname: payload?.surname,
    ticketNumber: payload?.ticketNumber,
    bookingClass: payload?.bookingClass
  };
  const response = await api.post(CLAIMS_STATUS_TG_URL, ClaimMissingStarPayload, starData);
  return response;
};
const ClaimStatusRouteData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  let claimStatusPayload = {
    origin: payload?.from?.airportCode,
    destination: payload?.to?.airportCode
  };
  const response = await api.post(CLAIM_STATUS_ROUTE_URL, claimStatusPayload, data);
  return response;
};
function* getclaimStatusRouteData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(ClaimStatusRouteData, payload);
    yield put(claimMissingRouteSuccess(response.data));
  } catch (error) {
    yield put(claimMissingRouteFailure(error.message));
  }
}
const FETCH_API_URL = config?.CLAIM_AIRLINE_API;
const fetchAirlineTripData = async payload => {
  const accessToken = sessionStorage.getItem("accesstoken");
  const memberId = sessionStorage.getItem("memberId");
  let airlineData = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: accessToken,
      memberId
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.get(FETCH_API_URL, airlineData);
  return response;
};
function* getClaimAirlinesData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirlineTripData, payload);
    yield put(claimAirlineSuccess(response));
  } catch (error) {
    yield put(claimAirlineFailure(error.response));
  }
}
export function* watchAirlineMiles() {
  yield takeLatest(claimAirlineRequest.type, getClaimAirlinesData);
}
function* getClaimMissingMilesStartData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(ClaimMissingStartData, payload);
    yield put(getClaimDataStarSuccess(response.data));
  } catch (error) {
    yield put(getClaimDataStarFailure(error));
  }
}
function* getClaimMissingMilesData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(ClaimMissingData, payload);
    yield put(getClaimDataSuccess(response.data));
  } catch (error) {
    yield put(getClaimDataFailure(error));
  }
}
export function* watchClaimMissingMiles() {
  yield takeLatest(getClaimDataRequest.type, getClaimMissingMilesData);
}
export function* watchClaimMissingMilesStar() {
  yield takeLatest(getClaimDataStarRequest.type, getClaimMissingMilesStartData);
}
export function* watchFlightStatusByRoute() {
  yield takeLatest(claimMissingRouteRequest.type, getclaimStatusRouteData);
}