import { call, put, takeLatest } from "redux-saga/effects";
import { miceOffersDataRequest, getMiceOffersDataSuccess, getMiceOffersDataFailure, eventListRequest, eventListSuccess, eventListFailure, submitEventRequest, submitEventSuccess, submitEventFailure } from "../slice/addMiceOffersSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const addMiceOffers = async payload => {
  const API_URL = config?.VIEW_MICE_OFFERS;
  const response = await api.post(API_URL, payload);
  return response;
};
function* getMiceOffersData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(addMiceOffers, payload);
    yield put(getMiceOffersDataSuccess(response.data));
  } catch (error) {
    yield put(getMiceOffersDataFailure(error));
  }
}
export function* watchMiceOffersData() {
  yield takeLatest(miceOffersDataRequest.type, getMiceOffersData);
}
const eventList = async () => {
  const API_URL = config?.EVENTS_LIST;
  const response = await api.get(API_URL);
  return response;
};
function* fetchEventList() {
  try {
    const response = yield call(eventList);
    yield put(eventListSuccess(response?.data));
  } catch (error) {
    yield put(eventListFailure(error));
  }
}
export function* watchEventList() {
  yield takeLatest(eventListRequest.type, fetchEventList);
}
const submitEvent = async payload => {
  const API_URL = config?.SUBMIT_EVENT;
  const response = await api.post(API_URL, payload);
  return response;
};
function* postSubmitEvent(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(submitEvent, payload);
    yield put(submitEventSuccess(response?.data));
  } catch (error) {
    yield put(submitEventFailure(error));
  }
}
export function* watchSubmitEvent() {
  yield takeLatest(submitEventRequest.type, postSubmitEvent);
}