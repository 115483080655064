import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  hasData: false,
  error: "",
  formStatus: {
    status: false,
    message: ""
  },
  charterFormError: "",
  charterFormHasData: false,
  successcharterFormData: "",
  isCRMShowFormEnabled: false
};
const charterRequestFormSlice = createSlice({
  name: "charterRequestForm",
  initialState,
  reducers: {
    submitCharterFormRequest: (state, action) => {
      state.isLoading = true;
    },
    submitCharterFormSuccess: (state, action) => {
      state.isLoading = false;
      state.charterFormError = "";
      state.charterFormHasData = true;
      state.successcharterFormData = action.payload;
    },
    submitCharterFormFailure: (state, action) => {
      state.isLoading = false;
      state.charterFormError = action.payload;
      state.charterFormHasData = false;
      state.successcharterFormData = "";
    },
    clearCharterFormResponse: state => {
      state.isLoading = false;
      state.charterFormError = "";
      state.charterFormHasData = false;
      state.successcharterFormData = "";
    }
  }
});
export const {
  submitCharterFormRequest,
  submitCharterFormFailure,
  submitCharterFormSuccess,
  clearCharterFormResponse
} = charterRequestFormSlice.actions;
export default charterRequestFormSlice.reducer;