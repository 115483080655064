import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  preSelectMeals: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const preSelectSubmitSlice = createSlice({
  name: "preSelectSubmit",
  initialState,
  reducers: {
    preSelectSubmitMealRequest: state => {
      state.isLoading = true;
    },
    preSelectSubmitMealSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.preSelectMeals = action.payload;
    },
    preSelectSubmitMealFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  preSelectSubmitMealRequest,
  preSelectSubmitMealSuccess,
  preSelectSubmitMealFailure
} = preSelectSubmitSlice.actions;
export default preSelectSubmitSlice.reducer;