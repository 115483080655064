import { call, put, takeLatest } from "redux-saga/effects";
import { addPassengerRequest, addPassengerSuccess, addPassengerFailure } from "../../slice/checkin/addPassengerSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchAddPassengerData = async addPaxCheckIn => {
  const ADD_PAX_API_URL = config.ADD_PAX_CHECK_IN_API;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json"
    },
    loader: false
  };
  const response = await api.post(`${ADD_PAX_API_URL}`, addPaxCheckIn, data);
  return response;
};
function* getAddPassengerData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAddPassengerData, payload);
    yield put(addPassengerSuccess(response.data));
  } catch (error) {
    yield put(addPassengerFailure(error));
  }
}
export function* watchAddPassengerData() {
  yield takeLatest(addPassengerRequest.type, getAddPassengerData);
}