import { call, put, takeEvery } from "redux-saga/effects";
import { redeemMilesRequest, redeemMilesSuccess, redeemMilesFailure } from "../slice/redeemMilesSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config?.REDEEM_MILES_API;
const RedeemMilesDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.post(API_URL, payload, headerData);
  return response;
};
function* fetchRedeemMiles(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(RedeemMilesDetail, payload);
    yield put(redeemMilesSuccess(response));
  } catch (error) {
    yield put(redeemMilesFailure(error?.response));
  }
}
export function* watchRedeemMiles() {
  yield takeEvery(redeemMilesRequest.type, fetchRedeemMiles);
}