import { all } from "redux-saga/effects";
import { watchRetrieveJourneyData } from "./checkin/checkinSaga";
import { watchAddPassengerData } from "./checkin/addPassengerSaga";
import { watchcancelCheckin } from "./cancelCheckinSaga/cancelCheckinSaga";
import { watchRetrieveCancelCheckin } from "./cancelCheckinSaga/cancelRetrieveJourneySaga";
import { watchRemoveSelectPassengerData } from "./selectpassenger/removeSelectPassengerSaga";
import { watchSelectPassengerData } from "./selectpassenger/selectPassengerSaga";
import { watchUpdatePassengerData } from "./selectpassenger/updatePassenerSaga";
import { watchFrequentFlyerProgramData } from "./passengerDetails/frequentFlyerProgramSaga";
import { watchNationalityCountryData } from "./passengerDetails/nationalityCountrySaga";
import { watchAutoSeatSelectionData } from "./selectpassenger/autoSeatSaga";
import { watchSeatMapData } from "./seatMap/seatMapSaga";
import { watchCheckinBoardingPassData, watchCheckinBoardingPassGoogleWallet, watchCheckinBoardingPassAppleWallet, watchCheckinBoardingPassSavePdf } from "./checkin/checkinBoardingPassSaga";
import { watchCompleteCheckinData } from "./completeCheckin/completeCheckinSaga";
import { watchGenerateOtpRefKeyData, watchFfpSubmitOtp, watchGenerateFfpResendOtp } from "./passengerDetails/ffpGenerateOtpSaga";
import { watchRegulatoryData } from "./passengerDetails/regulatorySaga";
export default function* rootSaga() {
  yield all([watchRetrieveJourneyData(), watchAddPassengerData(), watchcancelCheckin(), watchRetrieveCancelCheckin(), watchRemoveSelectPassengerData(), watchSelectPassengerData(), watchGenerateOtpRefKeyData(), watchUpdatePassengerData(), watchFrequentFlyerProgramData(), watchNationalityCountryData(), watchAutoSeatSelectionData(), watchSeatMapData(), watchCheckinBoardingPassData(), watchCheckinBoardingPassGoogleWallet(), watchCheckinBoardingPassAppleWallet(), watchGenerateFfpResendOtp(), watchFfpSubmitOtp(), watchCompleteCheckinData(), watchRegulatoryData(), watchCheckinBoardingPassSavePdf()]);
}