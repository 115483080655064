import { call, put, takeLatest } from "redux-saga/effects";
import { selectPassengerRequest, selectPassengerSuccess, selectPassengerFailure } from "../../slice/checkin/selectPassengerDetailsSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchSelectPassengerData = async selectPassengers => {
  const SELECTION_PASSENGER_API_URL = config.SELECT_PASSENGER_REGULATORY_CHECKIN;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(`${SELECTION_PASSENGER_API_URL}`, selectPassengers, data);
  return response;
};
function* getSelectPassengerData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchSelectPassengerData, payload);
    yield put(selectPassengerSuccess(response.data));
  } catch (error) {
    yield put(selectPassengerFailure(error));
  }
}
export function* watchSelectPassengerData() {
  yield takeLatest(selectPassengerRequest.type, getSelectPassengerData);
}