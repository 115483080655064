import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  airportListData: null,
  starAirportListData: null,
  multiCityAirportListData: null,
  multiCityAirportListLoading: false,
  multiCityAirportListError: null,
  isLoading: false,
  error: null,
  enablePnrReviewPassenger: false
};
const starAllianceRedemptionSlice = createSlice({
  name: "starAllianceRedemptionSlice",
  initialState,
  reducers: {
    getStarAllianceAirportListRequest: state => {
      state.isLoading = true;
    },
    getStarAllianceAirportListSuccess: (state, action) => {
      state.isLoading = false;
      state.airportListData = action.payload;
    },
    getStarAllianceAirportListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMainStarAllianceAirportListRequest: state => {
      state.isLoading = true;
    },
    getMainStarAllianceAirportListSuccess: (state, action) => {
      state.isLoading = false;
      state.starAirportListData = action.payload;
    },
    getMainStarAllianceAirportListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getStarAllianceMultiCityAirportListRequest: state => {
      state.multiCityAirportListLoading = true;
    },
    getStarAllianceMultiCityAirportListSuccess: (state, action) => {
      state.multiCityAirportListLoading = false;
      state.multiCityAirportListData = action.payload;
    },
    getStarAllianceMultiCityAirportListFailure: (state, action) => {
      state.multiCityAirportListLoading = false;
      state.multiCityAirportListError = action.payload;
    },
    updateEnablePnrPassenger: (state, action) => {
      state.enablePnrReviewPassenger = action.payload;
    }
  }
});
export const {
  getStarAllianceAirportListRequest,
  getStarAllianceAirportListSuccess,
  getStarAllianceAirportListFailure,
  getStarAllianceMultiCityAirportListRequest,
  getStarAllianceMultiCityAirportListSuccess,
  getStarAllianceMultiCityAirportListFailure,
  getMainStarAllianceAirportListRequest,
  getMainStarAllianceAirportListSuccess,
  getMainStarAllianceAirportListFailure,
  updateEnablePnrPassenger
} = starAllianceRedemptionSlice.actions;
export default starAllianceRedemptionSlice.reducer;