import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  hasData: false,
  isError: false,
  error: "",
  flightupgradeinfo: {},
  paxInfo: [],
  flightDetails: [],
  pnrErrorResposne: "",
  reviewinformation: {},
  upgradeFlightMiles: {},
  upgradeSeatErrorResponse: "",
  upgradeSeatError: false
};
const flightUpgradeinfoSlice = createSlice({
  name: "flightUpgradeinfoSlice",
  initialState,
  reducers: {
    verifyBookingRequest: (state, action) => {
      state.isLoading = true;
    },
    flightupgradeinforequest: (state, action) => {
      state.isLoading = true;
    },
    flightupgradeinfosuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.flightupgradeinfo = action.payload;
      state.error = "";
      console.log(action.payload);
    },
    flightupgradeinfofailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
    },
    verifyBookingSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.flightupgradeinfo = action.payload.flightUpgradeInformation;
      state.paxInfo = state.flightupgradeinfo?.paxInfo;
      state.flightDetails = state.flightupgradeinfo?.flightDetails;
      state.error = "";
    },
    verifyBookingFailure: (state, action) => {
      state.error = action.payload;
      state.pnrErrorResposne = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.isError = true;
      //   if(state.error.response.data.code ==="S500"){

      //   }
      console.log(action.payload, "ff");
    },
    //Get Miles info
    upgradeSeatMilesRequest: (state, action) => {
      state.isLoading = true;
    },
    getUpgradeSeatMilesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.upgradeSeatError = false;
      state.upgradeFlightMiles = action.payload;
      state.error = "";
    },
    getUpgradeSeatMilesFailure: (state, action) => {
      state.error = action.payload;
      state.upgradeSeatErrorResponse = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.upgradeSeatError = true;
      //   if(state.error.response.data.code ==="S500"){

      //   }
      console.log(action.payload, "fail");
    },
    //review information changes

    reviewinformationrequest: state => {
      state.isLoading = true;
    },
    reviewinformationsuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.reviewinformation = action.payload;
      state.error = "";
    },
    reviewinformationfailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
    }
  }
});
export const {
  flightupgradeinfofailure,
  verifyBookingRequest,
  flightupgradeinfosuccess,
  flightupgradeinforequest,
  verifyBookingSuccess,
  verifyBookingFailure,
  reviewinformationrequest,
  reviewinformationsuccess,
  reviewinformationfailure,
  upgradeSeatMilesRequest,
  getUpgradeSeatMilesSuccess,
  getUpgradeSeatMilesFailure
} = flightUpgradeinfoSlice.actions;
export default flightUpgradeinfoSlice.reducer;