import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  updatePassengerInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  updatePassengerStatus: {
    status: false,
    message: ""
  }
};
const updatePassengerSlice = createSlice({
  name: "updatePassenger",
  initialState,
  reducers: {
    updatePassengerRequest: (state, action) => {
      state.isLoading = true;
    },
    updatePassengerSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.updatePassengerInfo = action.payload;
      state.updatePassengerStatus.status = true;
      state.updatePassengerStatus.message = action?.payload?.message;
    },
    updatePassengerFailure: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.response?.data;
      state.updatePassengerInfo = {};
      state.updatePassengerStatus.status = false;
      state.updatePassengerStatus.message = "";
    },
    resetUpdatePassengers: state => {
      state.updatePassengerInfo = {};
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.updatePassengerStatus.status = false;
      state.updatePassengerStatus.message = "";
    }
  }
});
export const {
  updatePassengerRequest,
  updatePassengerSuccess,
  updatePassengerFailure,
  resetUpdatePassengers
} = updatePassengerSlice.actions;
export default updatePassengerSlice.reducer;