import api from "../../../config/api";
import { call, put, takeEvery } from "redux-saga/effects";
import { sendCalendarFareData, sendCalendarFareFailure, sendCalendarFareSuccess } from "../slice/calendarPricingSlice";
import { config } from "../../../config/global";
const CALENDAR_FARE_API_URL = config?.CALENDER_PRICING;
const sendCalendarData = async fare => {
  const data = {
    journeyType: fare?.journeyType,
    origin: fare?.origin,
    destination: fare?.destination,
    classes: fare?.classes,
    dateRange: {
      startDate: fare?.dateRange.startDate,
      endDate: fare?.dateRange.endDate
    },
    histogramType: fare?.histogramType
  };
  const HeaderData = {
    headers: {
      "content-type": "application/json"
    },
    loader: false
  };
  const response = await api.post(CALENDAR_FARE_API_URL, data, HeaderData);
  return response;
};
function* sendCalendarPriceData(action) {
  const {
    payload
  } = action;
  try {
    // yield put(sendCalendarFareData());
    const response = yield call(sendCalendarData, payload);
    yield put(sendCalendarFareSuccess(response.data));
  } catch (error) {
    yield put(sendCalendarFareFailure(error));
  }
}
export function* watchSendCalendarPriceData() {
  yield takeEvery(sendCalendarFareData.type, sendCalendarPriceData);
}