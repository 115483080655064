import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  checkinDetails: {},
  isLoading: false,
  hasData: false,
  checkinCompleted: {
    status: false,
    message: ""
  },
  error: ""
};
const completeCheckInSlice = createSlice({
  name: "completeCheckin",
  initialState,
  reducers: {
    retrieveCompleteCheckin: (state, action) => {
      state.isLoading = true;
      state.checkinCompleted.status = false;
      state.error = "";
    },
    retrieveCompleteCheckinSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.checkinCompleted.status = true;
      state.checkinDetails = action.payload;
    },
    retrieveCompleteCheckinFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      state.checkinCompleted.status = false;
      state.checkinDetails = {};
    },
    resetCompleteCheckin: state => {
      state.isLoading = false;
      state.checkinDetails = {};
      state.checkinCompleted.status = false;
      state.hasData = false;
      state.error = "";
    }
  }
});
export const {
  retrieveCompleteCheckin,
  retrieveCompleteCheckinSuccess,
  retrieveCompleteCheckinFailure,
  resetCompleteCheckin
} = completeCheckInSlice.actions;
export default completeCheckInSlice.reducer;