import { call, put, takeEvery } from "redux-saga/effects";
import { airLineListRequest, airLineListSuccess, airLineListFailure } from "../slice/starAllianceAirlineListSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config?.STAR_ALLIANCE_AIR_LINE_LIST_API;
const airLineListDetail = async () => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.get(API_URL, headerData);
  return response;
};
function* fetchAirLineList() {
  try {
    const response = yield call(airLineListDetail);
    yield put(airLineListSuccess(response));
  } catch (error) {
    yield put(airLineListFailure(error?.response));
  }
}
export function* watchAirLineList() {
  yield takeEvery(airLineListRequest.type, fetchAirLineList);
}