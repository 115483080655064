import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  homepageDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
  getMyprofileDetails: ""
};
const homeSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    getHomePageDataPending: state => {
      state.isLoading = true;
    },
    getHomePageDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.homepageDetails = action.payload;
    },
    getHomePageDataFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMyprofileDataPending: state => {
      state.isLoading = true;
    },
    getMyprofileDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.getMyprofileDetails = action.payload;
    },
    getMyprofileDataFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  getHomePageDataPending,
  getHomePageDataSuccess,
  getHomePageDataFail
} = homeSlice.actions;
export default homeSlice.reducer;