import api from "../../../config/api";
import { call, put, takeEvery } from "redux-saga/effects";
import cmsApi from "../../../config/cmsApi";
import { config } from "../../../config/global";
export const FETCH_DATA_CONSTANT = "FETCH_DATA";
function* fetchData(action) {
  const configData = {
    engine: action?.isTeamsite ? "teamsite" : action?.isAemContentAuthored ? "aemContentAuthored" : action?.isAemConfigured ? "aemConfigured" : action?.isAemServlet ? "aemServlet" : ""
  };
  try {
    const response = yield call(action?.isTeamsite || action?.isAemContentAuthored || action?.isAemConfigured || action?.isAemServlet ? cmsApi.get : api.get, action.url, configData);
    yield put(action.successAction(response.data));
  } catch (error) {
    yield put(action.errorAction(error));
  }
}
export function* watchFetchData() {
  yield takeEvery(config.FETCH_DATA_CONSTANT, fetchData);
}
export default watchFetchData;