import { call, put, takeLatest } from "redux-saga/effects";
import { retrieveCompleteCheckin, retrieveCompleteCheckinSuccess, retrieveCompleteCheckinFailure } from "../../slice/checkin/completeCheckin/completeCheckinSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchCompleteCheckinData = async journeyId => {
  const COMPLETE_CHECKIN_API_URL = config.COMPLETE_CHECKIN;
  const data = {
    mode: "no-cors",
    headers: {
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(`${COMPLETE_CHECKIN_API_URL}`, journeyId, data);
  return response;
};
function* getCompleteCheckinData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchCompleteCheckinData, payload);
    yield put(retrieveCompleteCheckinSuccess(response.data));
  } catch (error) {
    yield put(retrieveCompleteCheckinFailure(error));
  }
}
export function* watchCompleteCheckinData() {
  yield takeLatest(retrieveCompleteCheckin.type, getCompleteCheckinData);
}