import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import { freqFlyerPgmRequest, freqFlyerPgmSuccess, freqFlyerPgmFailure } from "../../slice/checkin/passengerDetails/frequentFlyerProgramSlice";
const data = {
  mode: "no-cors",
  headers: {
    Authorization: localStorage.getItem("checkintoken"),
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true
};
const fetchFreqFlyerData = async () => {
  const API_URL = config.FFP_PROGRAM_LIST;
  const response = await api.get(API_URL, data);
  return response;
};
function* getFrequentFlyerProgramData() {
  try {
    const response = yield call(fetchFreqFlyerData);
    yield put(freqFlyerPgmSuccess(response.data));
  } catch (error) {
    yield put(freqFlyerPgmFailure(error.message));
  }
}
export function* watchFrequentFlyerProgramData() {
  yield takeLatest(freqFlyerPgmRequest.type, getFrequentFlyerProgramData);
}