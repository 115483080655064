import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  memberId: null,
  airlineCode: null,
  flightNumber: null,
  flightDate: null,
  origin: null,
  destination: null,
  seatNumber: null,
  name: null,
  surname: null,
  ticketNumber: null,
  bookingClass: null,
  termsConditionData: "",
  termsConditionError: "",
  claimAirlineData: "",
  claimAirlineError: "",
  reviewSummary: [],
  successResponse: {},
  claimRouteInputs: null,
  isLoading: false,
  isError: false,
  error: false
};
const claimMissingMilesSlice = createSlice({
  name: "claimMissingMiles",
  initialState,
  reducers: {
    claimMissingRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    claimMissingSuccess: (state, action) => {
      state.memberId = action.payload;
      state.airlines = action.payload;
      state.airlineCode = action.payload;
      state.flightNumber = action.payload;
      state.origin = action.payload;
      state.destination = action.payload;
      state.seatNumber = action.payload;
      state.flightDate = action.payload;
      state.name = action.payload;
      state.surname = action.payload;
      state.ticketNumber = action.payload;
      state.bookingClass = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    claimMissingFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    claimMissingInputSave: (state, action) => {
      state.memberId = action.payload;
      state.airlines = action.payload;
      state.airlineCode = action.payload;
      state.flightNumber = action.payload;
      state.origin = action.payload;
      state.destination = action.payload;
      state.flightDate = action.payload;
      state.seatNumber = action.payload;
      state.name = action.payload;
      state.surname = action.payload;
      state.ticketNumber = action.payload;
      state.bookingClass = action.payload;
    },
    claimMissingClear: state => {
      state.memberId = null;
      state.airlineCode = null;
      state.flightNumber = null;
      state.flightDate = null;
      state.origin = null;
      state.destination = null;
      state.seatNumber = null;
      state.name = null;
      state.surname = null;
      state.ticketNumber = null;
      state.bookingClass = null;
      state.isLoading = false;
      state.error = false;
      state.isError = false;
    },
    getClaimDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getClaimDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successResponse = action.payload;
    },
    getClaimDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.response?.data;
      state.isError = true;
    },
    updateClaimSelectedCardData: (state, action) => {
      const {
        cardData
      } = action.payload;
      state.selectedRecentSearchData = cardData;
    },
    /*TG airline*/
    getClaimDataStarRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    getClaimDataStarSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.memberId = action.payload;
      state.airlineCode = action.payload;
      state.flightNumber = action.payload;
      state.flightDate = action.payload;
      state.origin = action.payload;
      state.destination = action.payload;
      state.seatNumber = action.payload;
      state.name = action.payload;
      state.surname = action.payload;
      state.ticketNumber = action.payload;
      state.bookingClass = action.payload;
    },
    getClaimDataStarFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.response?.data;
      state.isError = true;
    },
    /*terms and condition */
    claimMissingTermsConditionRequest: state => {
      state.isLoading = true;
    },
    claimMissingTermsConditionSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsConditionData = action.payload;
      state.termsConditionError = "";
    },
    claimMissingTermsConditionFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsConditionError = action.payload;
    },
    /*  airline Data */
    claimAirlineRequest: state => {
      state.isLoading = true;
      state.hasData = true;
    },
    claimAirlineSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.claimAirlineData = action.payload;
      state.claimAirlineError = "";
    },
    claimAirlineFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.claimAirlineError = action.payload;
    },
    /* from and to */
    claimMissingRouteSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.origin = action.payload;
      state.error = "";
    },
    claimMissingRouteFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = action.payload;
    },
    claimMissingRouteRequest: (state, action) => {
      state.isLoading = true;
      state.claimRouteInputs = action.payload;
    },
    claimMissingRouteInputSave: (state, action) => {
      state.claimRouteInputs = action.payload;
    },
    reviewSummaryRequest: (state, action) => {
      state.isLoading = true;
      state.reviewData = action.payload;
    },
    reviewSummarySuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.reviewSummary = action.payload;
      state.error = "";
    },
    reviewSummaryFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.response.data;
    }
  }
});
export const {
  claimMissingRouteRequest,
  claimMissingRouteSuccess,
  claimMissingRouteFailure,
  claimMissingRouteInputSave,
  claimMissingClear,
  getClaimDataSuccess,
  getClaimDataFailure,
  updateClaimSelectedCardData,
  getClaimDataRequest,
  getClaimDataStarSuccess,
  getClaimDataStarFailure,
  getClaimDataStarRequest,
  claimMissingTermsConditionRequest,
  claimMissingTermsConditionSuccess,
  claimMissingTermsConditionFailure,
  claimAirlineSuccess,
  claimAirlineFailure,
  claimAirlineRequest,
  reviewSummaryRequest,
  reviewSummarySuccess,
  reviewSummaryFailure
} = claimMissingMilesSlice.actions;
export default claimMissingMilesSlice.reducer;