import { call, put, takeEvery } from "redux-saga/effects";
import { upgradeSeatRequest, upgradeSeatSuccess, upgradeSeatFailure } from "../slice/upgradeSeatSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config?.UPGRADE_SEAT_API;
const UpgradeSeatDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.post(API_URL, payload, headerData);
  console.log("test", response);
  return response;
};
function* fetchUpgradeSeatDetails(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(UpgradeSeatDetail, payload);
    yield put(upgradeSeatSuccess(response));
  } catch (error) {
    yield put(upgradeSeatFailure(error?.response));
  }
}
export function* watchUpgradeSeat() {
  yield takeEvery(upgradeSeatRequest.type, fetchUpgradeSeatDetails);
}