import { call, put, takeLatest } from "redux-saga/effects";
import { getEReceiptDataRequest, getEReceiptDataSuccess, getEReceiptDataFailure, updateEReceiptDataRequest, updateEReceiptDataSuccess, updateEReceiptDataFailure } from "../slice/eReceiptSlice";
import api from "../../../config/api";
function* fetchEReceiptSaga(action) {
  try {
    const response = yield call(api.get, action.payload.url, {
      method: "GET",
      responseType: "blob"
    });
    const blob = new Blob([response.data], {
      type: "application/pdf"
    });
    var blobUrl = URL.createObjectURL(blob);
    const limitDetails = {
      printCount: response.headers.printcount,
      sponsorUpdated: response.headers.sponsorupdated,
      ticketNumber: response.headers.ticketnumber
    };
    yield put(getEReceiptDataSuccess({
      blobUrl: blobUrl,
      actualUrl: action.payload.url,
      profileInfo: action.payload.profileInfo,
      limitDetails: limitDetails
    }));
  } catch (error) {
    let errorCode;
    if (error?.response?.data instanceof Blob) {
      const errorText = yield error?.response?.data?.text();
      const errorData = JSON.parse(errorText);
      errorCode = errorData?.code || errorCode;
    } else {
      errorCode = error?.code || errorCode;
    }
    yield put(getEReceiptDataFailure(errorCode));
  }
}
function* updateEReceiptSaga(action) {
  try {
    const response = yield call(api.get, action.payload, {
      method: "GET"
    });
    yield put(updateEReceiptDataSuccess(response.data));
  } catch (error) {
    yield put(updateEReceiptDataFailure(error));
  }
}
export function* watchFetchEReceiptSaga() {
  yield takeLatest(getEReceiptDataRequest.type, fetchEReceiptSaga);
}
export function* watchUpdateEReceiptSaga() {
  yield takeLatest(updateEReceiptDataRequest.type, updateEReceiptSaga);
}