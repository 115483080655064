import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  freqflyerpgmData: {},
  isLoading: false,
  error: "",
  hasData: false
};
const fetchFrequentFlyerProgramSlice = createSlice({
  name: "frequentFlyerProgram",
  initialState,
  reducers: {
    freqFlyerPgmRequest: state => {
      state.isLoading = true;
    },
    freqFlyerPgmSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.freqflyerpgmData = action.payload;
    },
    freqFlyerPgmFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = action?.payload?.response?.data;
      state.freqflyerpgmData = {};
    }
  }
});
export const {
  freqFlyerPgmRequest,
  freqFlyerPgmSuccess,
  freqFlyerPgmFailure
} = fetchFrequentFlyerProgramSlice.actions;
export default fetchFrequentFlyerProgramSlice.reducer;