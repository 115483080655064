import axios from "axios";
import i18n from "i18next";
//process.env variable to be added
//apiconfig/ apiconstants to be added

//ToDo:This can be uncommented once the CMS supports all country lang codes.
// Now only handling english and thai
const countryLanguageCodeContent = i18n.language?.split("-")[1] + "/" + i18n.language?.split("-")[0] || "th/en";
const countryLanguageCode = i18n.language || "en-th";
// const countryLanguageCodeContent: string = 'th/en';
// const countryLanguageCode: string = 'en-th';

function getAemBaseUrl() {
  var rootdomain = window.location.origin;
  if (rootdomain.includes("localhost")) {
    return process.env.REACT_APP_AEM_ROOT_DOMAIN;
  } else {
    return rootdomain + "/";
  }
}
const base_urls = {
  teamsite: process.env.REACT_APP_TG_TEAMSITE_URL + "en-th",
  aemContentAuthored: getAemBaseUrl() + process.env.REACT_APP_TG_AEM_BASE_URL + countryLanguageCodeContent,
  aemConfigured: getAemBaseUrl(),
  aemServlet: getAemBaseUrl() + process.env.REACT_APP_TG_AEM_SERVLET_URL
};
const cmsApi = axios.create();

// Request interceptor
cmsApi.interceptors.request.use(config => {
  // Modify the request config here (e.g., add headers, authentication tokens)
  config.baseURL = base_urls?.[config?.engine];
  if (config?.headers?.Authorization?.includes("Basic")) {
    delete config.headers.Authorization;
  }
  if (config?.engine == "aemServlet") {
    config.url += "?" + countryLanguageCode;
  }
  return config;
}, error => {
  // Handle request errors here

  return Promise.reject(error);
});

// Response interceptor
cmsApi.interceptors.response.use(response => {
  // Modify the response data here (e.g., parse, transform)

  return response;
}, error => {
  // Handle response errors here

  return Promise.reject(error);
});
export default cmsApi;