import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  departure: "",
  arrival: "",
  departureDate: "",
  mc: "",
  flightNum: "",
  flightRequest: "",
  savedTravelCategory: {},
  successResponse: {},
  formStatus: {
    status: false,
    message: ""
  },
  isLoading: false,
  isError: false,
  error: false
};
const travelClassFormSlice = createSlice({
  name: "travelClassForm",
  initialState,
  reducers: {
    travelClassRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
      state.flightRequest = action.payload;
    },
    travelClassSuccess: (state, action) => {
      state.savedTravelCategory = action.payload;
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.isError = false;
      state.successResponse = action.payload;
    },
    travelClassFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    clearFormSuccessResponse: state => {
      state.formStatus.message = "";
      state.formStatus.status = false;
    },
    travelClassInputSave: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    travelClassClear: state => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
      state.savedTravelCategory = null;
      state.successData = null;
      state.formStatus.message = "";
      state.formStatus.status = false;
    },
    submitFormSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successResponse = action.payload;
    }
  }
});
export const {
  travelClassRequest,
  travelClassSuccess,
  travelClassFailure,
  travelClassInputSave,
  travelClassClear,
  submitFormSuccess,
  clearFormSuccessResponse
} = travelClassFormSlice.actions;
export default travelClassFormSlice.reducer;