export const config = {
  AEM_DAM_URL: "content/dam/thaiairways/",
  AIRPORT_LIST: "common/airport/list",
  CMS_BANNER_CAROUSEL_HOME: "/homepage/json/bannercarousel/jcr:content/root/container/container/bannercarousel.model.json",
  // ROP_URL: "osci/json/rop.json",
  COUNTRY_LIST: "common/country/getAllRegionList",
  BOARDING_PASS: "/checkin/boardingPass",
  ADDTO_GOOGLE_WALLET: "/checkin/wallet/google/add-boarding-pass",
  ADDTO_IPHONE_WALLET: "/checkin/generatePass",
  CHECKIN_BOARDING_PASS: "/check-in/boarding-passes",
  CHECKIN_BOARDING_PASS_GOOGLE_WALLET: "/check-in/boarding-passes/google-wallet",
  CHECKIN_BOARDING_PASS_APPLE_WALLET: "/check-in/boarding-passes/apple-wallet",
  GEOLOCATION: "common/geolocation",
  // TEAMSITE_ROH_LIST: "./json/roh.json",
  BOOKING_API: "refx/booking",
  MULTI_API: "multi/booking",
  ROP: "/osci/json/ropData.json",
  PROMO_API: "validatePromoCode",
  MANAGE_BOOKING_API: "managebooking",
  CHECKIN_API: "checkin",
  FLIGHTSTATUS_API: "flightstatus",
  UNLOCK_ACCOUNT_API: "profile/user/resetPinSubmit",
  TEAMSITE_PRIVILEGE_LIST: "/homepage/json/privilege/jcr:content/root/responsivegrid/privilege.model.json",
  TEAMSITE_HEADER_ENDPOINT: "/headerDetails.json",
  CMS_HEADER_ENDPOINT: "/modal/header.json",
  //TEAMSITE_FOOTER_ENDPOINT: "/footer.json",
  CMS_FOOTER_ENDPOINT: "/homepage/json/footer/jcr:content/root/container/footer.model.json",
  // REACT_APP_TG_UAT_URL : 'http://13.215.223.124:8080/'
  CMS_ROPMEMBERJOIN_API: "/joinnow.json",
  CMS_SAWASDEECARD_ENDPOINT: "/sawasdee.json",
  CMS_LIFESTYLE_DESTINATION_CONTENT_API: "/homepage/json/lifestyle/jcr:content/root/container/container/lifestyle.model.json",
  CMS_PARTNERS_API: "/homepage/json/partner/jcr:content/root/container/container/partners.model.json",
  TEAMSITE_APPSTORE_API: "/appStore.json",
  CMS_APPSTORE_API: "/content/dam/thaiairways/json/en-th/appStore.json",
  CMS_NOTICE_BANNER_URL: "/banner/notification.json",
  CMS_MODAL_URL: "/modal/notification.json",
  CMS_MORE_SERVICES_API: "/homepage/json/moreservices/_jcr_content/root/container/container/moreservices.model.json",
  TEAMSITE_GLOBAL_SEARCH_API: "osci/json/globalSearch.json",
  TEAMSITE_GLOBAL_SEARCH_API1: "/homepage/json/globalsearch/jcr:content/root/responsivegrid/globalsearch.model.json",
  TEAMSITE_SEARCH: "/content/search",
  CMS_PROMOTION: "/homepage/json/promotion/jcr:content/root/container/container/promotion.model.json",
  // TEAMSITE_ADDITIONAL_SERVICES_API: "./additionalServices.json",
  TEAMSITE_UPCOMING_TRIPS_API: "/trip/retrieve-trips?category=upcoming",
  TEAMSITE_RETRIEVE_PNR_API: "/trip/retrieve-pnr?isUpcoming=true",
  //TEAMSITE_NOMINEE_TERMS: "/nomineeTerms.json",
  CMS_NOMINEE_TERMS: "/homepage/json/nomineeterms/jcr:content/root/responsivegrid/nomineeterms.model.json",
  REACT_APP_AMADEUS_URL: "pss_router/",
  CRM_TEAMSITE_DELETE_ACCOUNT_TERMS: "/homepage/json/deleteaccountterms/jcr:content/root/responsivegrid/deleteaccount.model.json",
  CRM_TEAMSITE_EXTRA_BAGGAGE_DETAILS: "/homepage/json/extrabaggagedetails/jcr:content/root/responsivegrid/extrabaggagedetails.model.json",
  CRM_TEAMSITE_SEAT_PREFERENCE_DETAILS: "/homepage/json/preferredseatdetails/jcr:content/root/responsivegrid/preferredseatdetails.model.json",
  FETCH_PROFILE: "profile/fetchProfile",
  FLIGHT_INFO_API: "/airaward-flights/get-flight-info",
  FETCH_NIOMINEE: "/loyalty/nominee",
  // TEAMSITE_INSPIRATION_DEALS_DISCOVER_API: "/inspirationDeals.json",
  TEAMSITE_INSPIRATION_DEALS_DISCOVER_CONTENT_API: "./inspirationDealsCarousel.json",
  AIRPORT_MAIN_URL: "/common/airport/list",
  CALENDER_PRICING: "common/calendarPricing",
  CLAIM_STATUS_ROUTE_API: "flight/flight-status/by-route",
  FLIGHT_FARE_API: "/airaward-flights/get-fare",
  CMS_SOCIAL_PLATFORMS: "/content/dam/thaiairways/json/en-th/socialMedia.json",
  CRM_MANAGE_MILES: "/homepage/json/managemilesdata/jcr:content/root/responsivegrid/managemilesdata.model.json",
  CMS_NEWSANNOUNCEMENT: "/newsandannouncement.json",
  NEWSLETTER_PROMOTIONS_FROM_OPTIONS_API: "/custom_config/country-city-state/en/newsletterPreferenceList.json",
  PROFILE_CONTACT_UPDATE: "profile/user/update",
  FETCH_MAIN_MEMBER_PROFILE: "profile/getMainMemberProfile",
  FETCH_CRM_MEMBER_PROFILE: "mythai/profile",
  FETCH_MAIN_MEMBER_MILES: "loyalty/miles",
  FLIGHT_SCHEDULE: "flight-timetable",
  AIR_AWARD_BOOKING_API: "/airaward/booking",
  AIR_AWARD_PNR_API: "/airaward/pnr",
  AIR_AWARD_PRICE_API: "/airaward/price",
  AIR_AWARD_TST_API: "/airaward/tst",
  RETRIVE_PNR: "/airaward-review/pnr/review",
  CANCEL_BOOKING_PNR: "/airaward-review/booking/cancel",
  AIR_AWARD_REVIEW_PNR_API: "/airaward-review/pnr/rf",
  AIR_AWARD_REVIEW_PNR_RM_API: "/airaward-review/pnr/rm",
  AIR_AWARD_REVIEW_PNR_CANCEL_API: "/airaward-review/pnr/cancel",
  FETCH_MY_LIFESTYLE: "loyalty/lifestyle",
  CRM_FETCH_PROFILE: "mythai/profile",
  DELETE_TRIP_CARD: "/trip/delete",
  AIR_AWARD_AIRPORT_API: "/airaward-flights/airports",
  PROFILE_PERSONAL_INFO_UPDATE: "/profile/user/update",
  CRM_LOGIN: "mythai/login",
  CRM_ENROLL: "mythai/v2/enrol",
  CRM_LOGIN_OTP: "mythai/login/otp/submit",
  SOCIAL_MEDIA_AUTH: "/mythai/social-media/auth",
  SOCIAL_MEDIA_SIGN_UP: "mythai/social-media/signup",
  FETCH_DATA_CONSTANT: "FETCH_DATA",
  CRM_PROFILE_UPDATE: "mythai/profile/update",
  MILEAGE_STATEMENT: "loyalty/miles/info",
  MILEAGE_AWARD_LIST: "loyalty/member-award/list",
  MILEAGE_AWARD_DETAIL: "loyalty/member-award/detail",
  MILEAGE_REPORT: "loyalty/miles/report",
  E_RECEIPT_API: "ereceipt/printEreceipt",
  UPDATE_SPONSOR_API: "ereceipt/updateSponsor",
  ROP_CHANGE_PASSWORD_API: "/profile/change-pin/otp",
  ROP_CHANGE_PASSWORD_OTP_RESEND_API: "/profile/change-pin/otp/resend",
  ROP_CHANGE_PASSWORD_SUBMIT_API: "/profile/change-pin/otp/submit",
  CRM_RESEND_OTP: "/mythai/login/otp/resend",
  CRM_LOGOUT: "mythai/logout",
  IOS_APP_QR: "/assets/app_download_qr/ios_app_download.png",
  ANDROID_APP_QR: "/assets/app_download_qr/android_app_download.png",
  AIR_AWARD_PAYMENT_INIT: "/airaward-review/payment",
  TEAMSITE_MY_TRIPS_UPCOMING_TRIPS_API: "/trip/retrieve-trips",
  MY_TRIPS_RETRIEVE_API: "/trip/retrieve-pnr",
  BACK_TRACKING_AIRPORT_API: "/airaward-flights/airports/backtracking",
  CRM_TEAMSITE_HELP_ENDPOINT: "/homepage/json/helpmenu/jcr:content/root/responsivegrid/helpmenu.model.json",
  ADD_MY_TRIP_API: "/trip/add",
  CRM_ERECEIPT_TERMS_CONDITION: "/homepage/json/ereceipt-termscondition/jcr:content/root/responsivegrid/ereceipt.model.json",
  CRM_NATIONALITY_API: "/custom_config/country-city-state/en/crmnationality.json",
  MY_TRIPS_CHECK_IN_API: "check-in/journeys",
  MY_TRIPS_CHECK_IN_CPR_API: "checkin/cpr",
  COUNTRY_DATA: "/custom_config/country-city-state/en/country-nationality-code-flag.json",
  MARKET_LIST: "/custom_config/lang_rediection_json.json",
  CITY_DATA: "/custom_config/country-city-state/en/city_state_mapping.json",
  STATE_DATA: "/custom_config/country-city-state/en/state_country_mapping.json",
  CURRENCY_DATA: "/custom_config/country-city-state/currencies.json",
  PAID_NOMINEE_SEND_OTP: "loyalty/nominee/paidnominee/otp",
  PAID_NOMINEE_RESEND_OTP: "loyalty/nominee/paidnominee/resend-otp",
  PAID_NOMINEE_SUBMIT_OTP: "loyalty/nominee/paidnominee/submit-otp",
  FETCH_ELIGIBILITY: "loyalty/membertier/award/eligibility",
  CRM_TEAMSITE_MEMBER_STATUS_TERMS: "/homepage/json/memberstatuscalculator/jcr:content/root/responsivegrid/membercalculator.model.json",
  CRM_TEAMSITE_EXTEND_EXPIRY_MILES_TERMS_CONDITION: "/homepage/json/extendexpirymile/jcr:content/root/responsivegrid/extendexpirymile.model.json",
  CRM_COUNTRYCODE_JSON: "/custom_config/country-city-state/en/crmcountryphonecode.json",
  CRM_NEWSLETTERPREFERENCE_JSON: "/custom_config/country-city-state/en/newsletterPreferenceList.json",
  CRM_RESIDENCE_JSON: "/custom_config/country-city-state/en/crmresidence.json",
  MEMBER_TIER_CALCULATOR: "/loyalty/membertier/calculate",
  CRM_TEAMSITE_GIFT_MILES_TERMS_CONDITION: "/homepage/json/giftmilesterms/jcr:content/root/responsivegrid/giftmiles.model.json",
  FETCH_GIFT_MILES_DROPDOWN: "/loyalty/mileage-purchase/listMileage",
  CLAIM_MISSING_MILES_API: "/loyalty/retro-claim/star-alliance-missing-miles",
  CLAIM_MISSING_STAR_API: "/loyalty/retro-claim/tg-missing-miles",
  CRM_CLAIM_TERMS_CONDITION: "/homepage/json/claimmissing-termscondition/jcr:content/root/responsivegrid/claimmissing.model.json",
  // CLAIM_BOOKING_CLASS_API: "/claim-booking-classes.json",
  CLAIM_AIRLINE_API: "/common/airline/list",
  MEMBER_AWARD: "/loyalty/membertier/award/eligibility",
  MY_THAI_MY_REQUEST_API: "/mythai/my-requests",
  CRM_MILEAGE_PURCHASE_TERMS_CONDITION: "/homepage/json/mileagepurchaseterms/jcr:content/root/responsivegrid/mileagepurchase.model.json",
  REVIEW_SUMMARY_GIFT_MILES: "/loyalty/miles/gift/review",
  PROCESS_PAYMENT_GIFT_MILES: "/loyalty/mileage-purchase/processPayment",
  GIFT_MILES_DOWNLOAD_RECEIPT: "/loyalty/mileage-purchase/paymentReceipt",
  CRM_TEAMSITE_TRANSFER_MILES_TERMS_CONDITION: "/homepage/json/transfermiles-terms/jcr:content/root/responsivegrid/transfermiles.model.json",
  TRANSFER_MILES_REVIEW: "/loyalty/miles/transfer/review",
  TRANSFER_MILES_PAYMENT_INIT: "/loyalty/miles/transfer/payment-init",
  TRANSFER_MILES_DOWNLOAD_RECEIPT: "loyalty/miles/transfer/receipt",
  EXTEND_MILES_PAYMENT_INIT: "/loyalty/miles/extend/paymentInit",
  EXTEND_MILES_DOWNLOAD_RECEIPT: "/loyalty/miles/extend/paymentReceipt",
  EARNED_MILES_API: "/loyalty/miles-calculator/earned-miles",
  REDEEM_MILES_API: "/loyalty/miles-calculator/redeem-miles",
  STAR_ALLIANCE_AIR_LINE_LIST_API: "/common/airline/list",
  EXCESS_BAGGAGE_API: "/loyalty/miles-calculator/excess-baggage-awards",
  UPGRADE_SEAT_API: "/loyalty/miles-calculator/upgrade-seat-awards",
  BAGGAGE_ALLOWANCE: "common/more-services/excess-baggage-info",
  SEAT_PREFERENCE: "common/more-services/preferred-seat",
  BARCODE_SCANNING: "common/barcode/retrieve",
  BAGGAGE_SEARCH: "loyalty/baggage/bagJourney",
  MILES_SEARCH: "loyalty/miles-calculator/air-awards-miles",
  VIEW_MICE_OFFERS: "adminportal/mice/event-validate",
  EVENTS_LIST: "adminportal/mice/events",
  SUBMIT_EVENT: "adminportal/mice/proposal-form",
  VERIFY_BOOKING: "loyalty/upgrade-seat/verify-booking",
  UPGRADE_FLIGHT: "loyalty/upgrade-seat/review",
  REACT_APP_CREDIT_CARD_PARTNERS: "homepage/earn-miles/credit-card-partners.html",
  REACT_APP_STAR_ALLIANCE: "https://www.staralliance.com/en/members",
  //STAR ALLIANCE REDEMPTION
  STAR_ALLIANCE_AIRPORT_LIST: "/airaward-flights/star-airports/route?multiCity=false",
  STAR_ALLIANCE_AIRPORT_LIST_MULTI_CITY: "/airaward-flights/star-airports/route?multiCity=true",
  STAR_ALLIANCE_SEARCH: "airaward-flights/star-get-flight-info",
  DOWNLOAD_AIRPORT_BOOKING_PDF: "airaward-review/report/payment-summary",
  CRM_CHANGE_PASSWORD: "/mythai/profile/changePassword/otp",
  CRM_CHANGE_PASSWORD_OTP_RESEND: "/mythai/profile/changePassword/otp/resend",
  CRM_CHANGE_PASSWORD_SUBMIT: "/mythai/profile/changePassword/otp/submit",
  MILES_BEYOND_BANGKOK: "pdf/Star_Alliance_Awards_Chart_2019-One_way.pdf",
  POWER_OF_ATTORNEY: "pdf/flight-disruption/POWER_OF_ATTORNEY.pdf",
  UMNR_FORM: "pdf/service-request/UMNR_Form.pdf",
  MEDIF_FORM: "pdf/service-request/MEDIF-FORM-2018.pdf",
  HELP_FAQ: "/mythai/feedback",
  REACT_APP_ADDITIONAL_BAGGAGE: "/more-services/online-excess-baggage",
  REACT_APP_PREFERRED_SEATS: "/more-services/preferred-Seats",
  REACT_APP_PREFERRED_MEALS: "/more-services/pre-select-meals",
  REACT_APP_ELIBRARY: "/in-flight-experience/e-library",
  REACT_APP_ROP_MAIL: "mailto:ropsvc@thaiairways.com",
  REACT_APP_ERECEIPT_CONTACT_MAIL: "mailto:digital-accounting@thaiairways.com",
  REACT_APP_STORE_URL: "https://apps.apple.com/us/app/thai-airways/id1393912966",
  REACT_PLAY_STORE_URL: "https://play.google.com/store/apps/details?id=com.thaiairways.mobile",
  REACT_HUAWEI_STORE_URL: "https://appgallery.huawei.com/app/C101200261",
  REACT_APP_SILVER_BENEFITS_URL: "https://www.thaiairways.com/en_TH/rop/Status_Benefits/BenefitStatus-02-silver.page?",
  REACT_APP_GOLD_BENEFITS_URL: "https://www.thaiairways.com/en_TH/rop/Status_Benefits/BenefitStatus-03-gold.page?",
  REACT_APP_PLATINUM_BENEFITS_URL: "https://www.thaiairways.com/en_TH/rop/Status_Benefits/BenefitStatus-04-platinum.page?",
  REACT_APP_STAUS_BENEFITS_URL: "https://www.thaiairways.com/en_TH/rop/Status_Benefits/BenefitStatus-01.page?",
  S3_REDEMPTION_LOGO_URL: "/images/airaward/logos/airlines/",
  REACT_APP_UPGRADE_SEATS_STAR_ALLAIANCE_URL: "https://www.thaiairways.com/en_TH/rop/Redeeming/Redeeming_Award_UpgradeAwards_onStarAlliance.page?",
  PRESELECT_MEAL: "/ancillaries/preselect-meal/retrieve",
  MEALS_LIST: "/ancillaries/preselect-meal/meal-list",
  Travel_Class_API: "/flight/flight-schedule",
  PRESELECT_SUBMIT_MEAL: "/ancillaries/preselect-meal/update",
  // TEAMSITE_PRESELECT_TERMS: "/preselectTermsConditions.json",
  ROP_SIGNUP_API_URL: "profile/enroll/add-rop-member",
  ROP_OTP_URL: "/loyalty/otp/generate",
  REDIRECTIONURL: "https://sawasdee.thaiairways.com/",
  SWASDEEPOCKETGUIDEURL: "https://sawasdee.thaiairways.com/pocket-guides/",
  // DAPI
  ADD_PAX_CHECK_IN_API: "/check-in/passengers/merge",
  DAPI_CANCEL_CHECKIN: "/check-in/cancel",
  AIR_AWARD_CONFIRMATION: "/airaward-review/pnr/confirmation/summary",
  DAPI_CANCEL_RETRIEV_CHECKIN: "check-in/journey",
  REMOVE_SELECT_PASSENGER_CHECKIN: "/check-in/passengers",
  SELECT_PASSENGER_REGULATORY_CHECKIN: "/check-in/passengers/regulatory-details",
  UPDATE_PASSENGER_DETAILS: "/check-in/passengers/regulatory-details/upsert",
  FLIGHT_STATUS_NUMBER: "flight/flight-status/by-number",
  PASSENGER_DETAILS_FFP: "/custom_config/frequent_flyer_program/frequent_flyer_pgmlist.json",
  PASSENGER_DETAILS_COUNTRY: "/custom_config/country-city-state/dapi/en/checkin-country-list.json",
  CHECKIN_PASSENGER_DETAILS_JSON: "/custom_config/dapi/dapi-checkin-regulatory-config.json",
  CHECKIN_BP_SAVE_PDF: "/check-in/boarding-passes/pdf",
  EXPIRING_MILES_REVIEW: "/loyalty/miles/extendExpiringMiles/review",
  MYTHAI_CRM_FORM_FLIGTH_SUPPORT: "/mythai/flight-support",
  MYTHAI_ROP_FORM_FLIGTH_SUPPORT: "/mythai/rop-services",
  CHECKIN_AUTO_SEAT_SELECTION: "/ancillaries/checkin/seat",
  SEAT_MAP_CHECK_IN_API: "/ancillaries/checkin/seatmap",
  CRM_SERVICE_REQUEST: "mythai/special-service-request",
  MYTHAI_CRM_LOST_FOUND: "mythai/special-request/item-loss",
  FlIGHT_DISRUPTION_CERT: "mythai/flight-disruption-certificate",
  BAGGAGE_CLAIM: "mythai/special-request/property-claim",
  CHECKIN_SEND_OTP: "/check-in/otp",
  CHECKIN_RESEND_OTP: "/check-in/resend-otp",
  CHECKIN_ADD_FFP: "/check-in/addffp",
  CHARTER_FLIGTH_FORM_API: "/common/charter-flight",
  COMPLETE_CHECKIN: "/check-in/acceptance",
  FFP_PROGRAM_LIST: "/custom_config/frequent_flyer_program/ffp-program_with_airlinecode.json",
  CMS_DANGEROUS_GOODS_API: "/homepage/json/dangerous-goods/jcr:content/root/container/container/dangerous_goods.model.json",
  MICE_BOOKING_API: "miceBooking",
  PROMO_CODE_INFO: "adminportal/promo/info",
  CRM_EARNEDMILESTERMS_TERMS_CONDITION: "homepage/json/earnedmilesterms/jcr:content/root/responsivegrid/earnedmilesterms.model.json",
  CRM_GET_MY_THAI: "/homepage/json/mythaicrm/jcr:content/root/responsivegrid/mythaicrm.model.json",
  CRM_REDEEM_MILES_CLASSES: "homepage/json/redeemmilesclasses/jcr:content/root/responsivegrid/redeemmilesclasses.model.json",
  AEM_SUB_DIRECTORY: "/content",
  STAR_ALLIANCE_REDIRECTION_URL: "https://www.staralliance.com/en/",
  POPULAR_FARE: "/common/calendarPricing/popular-fares",
  CRM_DIRECT_FLIGHT_AWARDS: "/homepage/json/directflightawards/jcr:content/root/responsivegrid/directflightawards.model.json",
  CRM_GET_EXCESS_BAGGAGE_URL: "/homepage/json/excess-baggage/jcr:content/root/responsivegrid/excess_baggage.model.json",
  CRM_GET_UPGRADE_SEAT_URL: "/homepage/json/upgradeseat/jcr:content/root/responsivegrid/upgradeseat.model.json",
  CRM_GET_ERECEIPT_HOW_TO_GO_URL: "/homepage/json/ereceipt-howtoget/jcr:content/root/responsivegrid/getereceipt.model.json",
  CRM_TEAMSITE_BAGGAGE_ALLOWANCE: "/content/dam/thaiairways/json/common/baggageAllowanceOSCI.json",
  //REACT_AIRCRAFT_URL: "content/thaiairways/",

  REACT_AIRCRAFT: "/homepage/our-aircraft",
  REACT_INFIGHT_EXP: "/homepage/in-flight-experience.html",
  PROMOCODE_API: "promocode/booking",
  GOLD_TRACK: "common/airport/list/by_filter",
  GOLT_TRACK_INFORMATION: "common/more-services/goldTrack",
  LOUNGE_FINDER: "common/more-services/loungeFinder",
  LOUNGE_AMENITY: "content/dam/thaiairways/images/ground-services/lounge/amenity-icons/",
  AIRPORT_INFORMATION_URL: "/homepage/airport-information.html",
  CRM_MyPERSONAL_API: "/homepage/json/travelclassjson/jcr:content/root/responsivegrid/travelclassjson.model.json",
  INFLIGHT_IMAGE: "/content/dam/thaiairways/images/travel-class/travelexperience/in-flight-service.png",
  THAI_LOUNGES_IMAGE: "/content/dam/thaiairways/images/travel-class/travelexperience/thai-lounges.png",
  THAI_LOUNGES_IMAGEONE: "/content/dam/thaiairways/images/travel-class/travelexperience/thai-lounges-card.png",
  CRM_TERMSANDCONDISTION_CRM: "/homepage/json/termsandconditionscrm/jcr:content/root/responsivegrid/termsandconditionscr.model.json",
  CRM_FOOD: "content/dam/thaiairways/json/common/food.json",
  CRM_HOBBIES_AND_INTERESTS: "content/dam/thaiairways/json/common/hobbiesAndInterests.json",
  CRM_MAGAZINE: "content/dam/thaiairways/json/common/magazine.json",
  CRM_SPORTS: "content/dam/thaiairways/json/common/sports.json",
  CRM_NEWSPAPER: "content/dam/thaiairways/json/common/newspapers.json",
  CRM_TRAVEL: "content/dam/thaiairways/json/common/travel.json",
  CRM_TICKET_RESTRICTION: "/homepage/json/ticketrestriction/jcr:content/root/responsivegrid/ticketrestriction.model.json",
  PROMO_CODE_TERMS: "/homepage/json/promocodejson/jcr:content/root/responsivegrid/promocodejson.model.json",
  SAWASDHEE_INFORMATION: "common/feeds",
  TOKEN_EXPIRED: "/profile/user/refreshToken",
  CRM_MEAL: "content/dam/thaiairways/json/common/meal.json",
  CRM_TERM_CONDITIONS_PROFILE_COMPONENTS: "/homepage/json/termconditionsprofile/jcr:content/root/responsivegrid/termconditionsprofil.model.json",
  CRM_TEAMSITE_PRESELECT_TERMS: "/homepage/json/preselecttermsconditions/jcr:content/root/responsivegrid/preselecttermscondit.model.json",
  CRM_PRIVILEGE: "homepage/json/privilege/jcr:content/root/responsivegrid/privilege.model.json",
  CRM_SUBSCRIBE: "homepage/json/preferencetermsconditions/jcr:content/root/responsivegrid/preferencetermscondi.model.json",
  CRM_BOOKFLIGHT: "homepage/json/bookflightservices/jcr:content/root/responsivegrid/bookflightservices.model.json",
  CRM_REDEMPTION_PARTNER_CARD: "homepage/json/redemptionpartnercard/jcr:content/root/responsivegrid/redemptionpartnercar.model.json",
  CRM_TNCREVIEW_INFO: "homepage/json/tncreviewinfo/jcr:content/root/responsivegrid/tncreviewinfo.model.json",
  CRM_PRESELECTMEALS: "homepage/json/preselectmealwidgetdata/jcr:content/root/responsivegrid/preselectmealwidgetd.model.json",
  CRM_EARM_MILES: "homepage/json/claimbookingclasses/jcr:content/root/responsivegrid/claimbookingclasses.model.json",
  CRM_MY_THAI_CRM: "homepage/json/mythaicrm/jcr:content/root/responsivegrid/mythaicrm.model.json",
  CRM_LOUNGE_GOLD_TRACK: "homepage/json/loungeandgoldtrack/jcr:content/root/responsivegrid/loungegoldtrack.model.json"
};