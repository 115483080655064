import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { crmServiceRequest, crmServiceRequestFailure, submitFormSuccess } from "../slice/crmServiceRequestSlice";
import { config } from "../../../../src/config/global";
const CRM_Service_URL = config?.CRM_SERVICE_REQUEST;
const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const CrmServiceRequestData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
      memberId
    },
    loader: true
  };
  let CrmRequestPayload = {
    memberId: payload?.memberId,
    bookingEmail: payload?.bookingEmail,
    pnr: payload?.pnr,
    flightNumber: payload?.flightNumber,
    flightDate: payload?.flightDate,
    ticketNumber: payload?.ticketNumber,
    description: payload?.description,
    multipleRequest: payload?.multipleRequest,
    secondTopic: payload?.secondTopic,
    secondDescription: payload?.secondDescription,
    // portalCaseTopic:payload?.portalCaseTopic,
    portalCaseSubTopic: payload?.portalCaseSubTopic ? payload?.portalCaseSubTopic : payload?.portalCaseTopic,
    attachmentList: payload?.attachmentList
  };
  const response = await api.post(CRM_Service_URL, CrmRequestPayload, data);
  return response;
};
function* getCrmServiceData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(CrmServiceRequestData, payload);
    yield put(submitFormSuccess(response.data));
  } catch (error) {
    yield put(crmServiceRequestFailure(error));
  }
}
export function* watchServiceRequestDate() {
  yield takeLatest(crmServiceRequest.type, getCrmServiceData);
}