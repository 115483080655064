import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { getMainStarAllianceAirportListFailure, getMainStarAllianceAirportListRequest, getMainStarAllianceAirportListSuccess, getStarAllianceAirportListFailure, getStarAllianceAirportListRequest, getStarAllianceAirportListSuccess, getStarAllianceMultiCityAirportListFailure, getStarAllianceMultiCityAirportListRequest, getStarAllianceMultiCityAirportListSuccess } from "../slice/starAllianceRedemptionSlice";
const accessToken = localStorage.getItem("accesstoken");
const starAirportListDataCallback = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.get(config.STAR_ALLIANCE_AIRPORT_LIST, data);
  return response;
};
function* getStarAirportListData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(starAirportListDataCallback, payload);
    yield put(getStarAllianceAirportListSuccess(response.data));
  } catch (error) {
    yield put(getStarAllianceAirportListFailure(error.message));
  }
}
export function* watchStarAirportList() {
  yield takeLatest(getStarAllianceAirportListRequest.type, getStarAirportListData);
}
const starAirportListMultiCityDataCallback = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.get(config.STAR_ALLIANCE_AIRPORT_LIST_MULTI_CITY, data);
  return response;
};
function* getStarAirportListMultiCityData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(starAirportListMultiCityDataCallback, payload);
    yield put(getStarAllianceMultiCityAirportListSuccess(response.data));
  } catch (error) {
    yield put(getStarAllianceMultiCityAirportListFailure(error.message));
  }
}
export function* watchStarAirportListMultiCity() {
  yield takeLatest(getStarAllianceMultiCityAirportListRequest.type, getStarAirportListMultiCityData);
}
function* getMainStarAirportData(action) {
  try {
    let data = {
      mode: "no-cors",
      headers: {
        "content-type": "application/json",
        "Access-Control-Expose-Headers": "accessToken",
        Authorization: accessToken
      },
      loader: true,
      isGenericErr: true
    };
    const API_URL = config?.GOLD_TRACK + "?isStarRedemption=true";
    const response = yield call(api.get, API_URL, data);
    yield put(getMainStarAllianceAirportListSuccess(response.data));
  } catch (error) {
    yield put(getMainStarAllianceAirportListFailure(error.message));
  }
}
export function* watchActiveStarAirportList() {
  yield takeLatest(getMainStarAllianceAirportListRequest.type, getMainStarAirportData);
}