import { call, put, takeLatest } from "redux-saga/effects";
import { boardingPassDataRequest, getBoardingPassDataSuccess, getBoardingPassDataFailure, addToGooglePassWalletRequest, getAddToGooglePassWalleSuccess, getAddToGooglePassWalleFailure, addToAppleWalletRequest, getAddToAppleWalleSuccess, getAddToAppleWalleFailure } from "../slice/boardingPassSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const headerData = {
  mode: "no-cors",
  headers: {
    Authorization: localStorage.getItem("accesstoken"),
    "Content-Type": "application/json"
  },
  loader: true
};
const fetchBoardingPassData = async payload => {
  const BOARDING_PASS_API_URL = config.BOARDING_PASS;
  let payloadData = {
    tokenRequired: true,
    closeSession: true,
    boardingPassIds: payload
  };
  const response = await api.post(BOARDING_PASS_API_URL, payloadData, headerData);
  return response;
};
function* getBoardingPassData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBoardingPassData, payload);
    yield put(getBoardingPassDataSuccess(response?.data?.response?.boardingPassData));
  } catch (error) {
    yield put(getBoardingPassDataFailure(error?.response));
  }
}
export function* watchBoardingPassData() {
  yield takeLatest(boardingPassDataRequest.type, getBoardingPassData);
}
const fetchAddToGooglePassWalletData = async payload => {
  const BOARDING_PASS_API_URL = config.ADDTO_GOOGLE_WALLET;
  let payloadData = {
    tokenRequired: true,
    closeSession: true,
    boardingPassId: payload
  };
  const response = await api.post(BOARDING_PASS_API_URL, payloadData, headerData);
  return response;
};
function* addToGooglePassWalletData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAddToGooglePassWalletData, payload);
    yield put(getAddToGooglePassWalleSuccess(response.data));
  } catch (error) {
    yield put(getAddToGooglePassWalleFailure(error?.response));
  }
}
export function* watchAddToGooglePassWallet() {
  yield takeLatest(addToGooglePassWalletRequest.type, addToGooglePassWalletData);
}
const fetchAddToAppleWalletData = async payload => {
  const BOARDING_PASS_API_URL = config.ADDTO_IPHONE_WALLET;
  let payloadData = {
    tokenRequired: true,
    closeSession: true,
    boardingPassId: payload
  };
  const response = await api.post(BOARDING_PASS_API_URL, payloadData, headerData);
  return response;
};
function* addToAppleWalletData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAddToAppleWalletData, payload);
    yield put(getAddToAppleWalleSuccess(response?.data));
  } catch (error) {
    yield put(getAddToAppleWalleFailure(error?.response));
  }
}
export function* watchAddToAppleWallet() {
  yield takeLatest(addToAppleWalletRequest.type, addToAppleWalletData);
}