import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  crmLoginDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  crmSocialLoginValidationDetails: {},
  crmSocialLoginValidationErrors: "",
  crmSocialLoginValidationLoading: false,
  crmSocialLoginType: "",
  email: ""
};
const crmLoginSlice = createSlice({
  name: "crmLogin",
  initialState,
  reducers: {
    getCrmLoginDataPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getCrmLoginDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmLoginDetails = action.payload;
      state.email = action?.payload?.emailAddress;
      state.codeStatus = "success";
      sessionStorage.setItem("refNo", action.payload.refNo);
      localStorage.setItem("isROPLoggedIn", JSON.stringify(false));
    },
    getCrmLoginDataFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload;
    },
    resetCrmLoginData: state => {
      state.codeStatus = "pending";
      state.error = "";
      state.crmLoginDetails = {};
      state.hasData = false;
    },
    getCrmSocialMediaValidationPending: (state, action) => {
      state.crmSocialLoginValidationLoading = true;
      state.crmSocialLoginType = action.payload.socialMediaType;
    },
    getCrmSocialMediaValidationSuccess: (state, action) => {
      state.crmSocialLoginValidationLoading = false;
      let fullName = "";
      if (!action.payload.newUser) {
        fullName = action.payload.profileDetails?.salutation + " " + action.payload.profileDetails?.firstName + " " + action.payload?.profileDetails?.lastName;
        sessionStorage.setItem("FullName", fullName);
        sessionStorage.setItem("FirstName", action.payload.profileDetails?.firstName);
        sessionStorage.setItem("LastName", action.payload?.profileDetails?.lastName);
        sessionStorage.setItem("Salutation", action.payload?.profileDetails?.salutation);
        sessionStorage.setItem("profileName", action.payload.profileDetails?.firstName);
        sessionStorage.setItem("customerID", action.payload?.profileDetails?.customerID);
        localStorage.setItem("isROPLoggedIn", "false");
      }
      state.crmSocialLoginValidationDetails = action.payload;
      state.crmSocialLoginValidationErrors = "";
    },
    getCrmSocialMediaValidationFailure: (state, action) => {
      state.crmSocialLoginValidationLoading = false;
      state.crmSocialLoginValidationErrors = action.payload;
    },
    resetCrmSocialValidationData: state => {
      state.crmSocialLoginValidationDetails = {};
    }
  }
});
export const {
  getCrmLoginDataPending,
  getCrmLoginDataSuccess,
  getCrmLoginDataFailure,
  resetCrmLoginData,
  getCrmSocialMediaValidationPending,
  getCrmSocialMediaValidationSuccess,
  getCrmSocialMediaValidationFailure,
  resetCrmSocialValidationData
} = crmLoginSlice.actions;
export default crmLoginSlice.reducer;