import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { barcodeRequest, barcodeSuccess, barcodeFailure, baggageRequest, baggageSuccess, baggageFailure, baggageRequestMyTrips } from "../slice/baggageTrackingSlice";
const headerData = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true
};
const barcodeheader = {
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true
};

//api handling for barcode
const fetchBarcode = async payload => {
  const BARCODE_URL = config?.BARCODE_SCANNING;
  const formdata = new FormData();
  formdata.append("file", payload.base64);
  const response = await api.post(BARCODE_URL, formdata, barcodeheader);
  return response;
};
function* getBarcode(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBarcode, payload);
    yield put(barcodeSuccess(response?.data));
  } catch (error) {
    console.log("barcode error", error);
    console.error("barcode error", error);
    yield put(barcodeFailure(error?.response?.data));
  }
}
export function* watchBarcodeData() {
  yield takeEvery(barcodeRequest.type, getBarcode);
}

//api handling for baggage search
const fetchBaggage = async payload => {
  const BAGGAGE_SEARCH_URL = config?.BAGGAGE_SEARCH;
  let payloadData = [{
    bagTagNumber: payload.bagTagNumber,
    callingAirline: payload.callingAirline,
    departureDate: payload.departureDate,
    flightNumber: payload.flightNumber
  }];
  const response = await api.post(BAGGAGE_SEARCH_URL, payloadData, headerData);
  return response;
};
function* getBaggage(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBaggage, payload);
    yield put(baggageSuccess(response?.data));
  } catch (error) {
    yield put(baggageFailure(error?.response?.data));
  }
}
export function* watchBaggageData() {
  yield takeEvery(baggageRequest.type, getBaggage);
}
const fetchBaggageMyTrips = async payload => {
  const BAGGAGE_SEARCH_URL = config?.BAGGAGE_SEARCH;
  const response = await api.post(BAGGAGE_SEARCH_URL, payload, headerData);
  return response;
};
function* getBaggageMyTrips(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBaggageMyTrips, payload);
    yield put(baggageSuccess(response?.data));
  } catch (error) {
    yield put(baggageFailure(error?.response?.data));
  }
}
export function* watchBaggageDataMyTrips() {
  yield takeEvery(baggageRequestMyTrips.type, getBaggageMyTrips);
}