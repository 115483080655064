import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  removeSelectPassengerInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  removeselectPassengerStatus: {
    status: false,
    message: ""
  }
};
const removeSelectPassengerSlice = createSlice({
  name: "removeSelectPassenger",
  initialState,
  reducers: {
    removeSelectPassengerRequest: (state, action) => {
      state.isLoading = true;
    },
    removeSelectPassengerSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.removeSelectPassengerInfo = action.payload;
      state.removeselectPassengerStatus.status = true;
      state.removeselectPassengerStatus.message = action?.payload?.message;
    },
    removeSelectPassengerFailure: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.response?.data;
      state.removeSelectPassengerInfo = {};
      state.removeselectPassengerStatus.status = false;
      state.removeselectPassengerStatus.message = "";
    },
    resetRemoveSelectedPassengers: state => {
      state.removeSelectPassengerInfo = {};
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.removeselectPassengerStatus.status = false;
      state.removeselectPassengerStatus.message = "";
    }
  }
});
export const {
  removeSelectPassengerRequest,
  removeSelectPassengerSuccess,
  removeSelectPassengerFailure,
  resetRemoveSelectedPassengers
} = removeSelectPassengerSlice.actions;
export default removeSelectPassengerSlice.reducer;