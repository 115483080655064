import api from "../../../config/api";
import { submitCharterFormRequest, submitCharterFormFailure, submitCharterFormSuccess } from "../slice/charterRequestFormSlice";
import { put, takeLatest, call } from "redux-saga/effects";
import { config } from "../../../config/global";
const charterFormData = async payload => {
  const POST_API_URL = config.CHARTER_FLIGTH_FORM_API;
  const headerData = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  let formSubmitPayload = {
    service: payload?.service,
    name: payload?.name,
    companyName: payload?.companyName,
    tripType: payload?.tripType,
    origin: payload?.origin,
    destination: payload?.destination,
    noOfPassengers: payload?.noOfPassengers,
    countryCode: payload?.countryCode,
    contactNumber: payload?.contactNumber,
    email: payload?.email,
    departureDate: payload?.departureDate,
    returnDate: payload?.returnDate,
    purpose: payload?.purpose
  };
  const response = await api.post(POST_API_URL, formSubmitPayload, headerData);
  return response;
};
function* postCharterFormSubmitData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(charterFormData, payload);
    yield put(submitCharterFormSuccess(response?.data));
  } catch (error) {
    yield put(submitCharterFormFailure(error));
  }
}
export function* watchCharterFormData() {
  yield takeLatest(submitCharterFormRequest.type, postCharterFormSubmitData);
}