import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  upgradeSeatData: [],
  isLoading: false,
  hasData: false,
  error: {},
  isError: false,
  upgradeSeatDetails: '',
  upgradeSeatDetailsError: ''
};
const upgradeSeatSlice = createSlice({
  name: "upgradeSeat",
  initialState,
  reducers: {
    upgradeSeatRequest: state => {
      state.isLoading = true;
    },
    upgradeSeatSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = {};
      state.upgradeSeatData = action.payload.data;
      state.isError = false;
    },
    upgradeSeatFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.data;
      state.upgradeSeatData = [];
      console.log(action);
      if (state.error?.status === 400) {
        state.isError = true;
      }
    },
    clearUpgradeSeatData: state => {
      state.upgradeSeatData = [];
      state.isError = false;
    },
    getUpgradeSeatRequest: state => {
      state.isLoading = true;
    },
    getUpgradeSeatSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.upgradeSeatDetails = action.payload;
      state.upgradeSeatDetailsError = "";
    },
    getUpgradeSeatFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.upgradeSeatDetailsError = action.payload;
    }
  }
});
export const {
  upgradeSeatRequest,
  upgradeSeatSuccess,
  upgradeSeatFailure,
  clearUpgradeSeatData,
  getUpgradeSeatRequest,
  getUpgradeSeatSuccess,
  getUpgradeSeatFailure
} = upgradeSeatSlice.actions;
export default upgradeSeatSlice.reducer;