import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  promoCodeDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  promoStatus: {
    status: false,
    message: ""
  },
  promoModal: false,
  promoValues: "",
  promoData: {},
  promoDelete: false,
  promoTerms: {}
};
const promoSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {
    getPromoCodePending: state => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.error = "";
    },
    getPromoCodeSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.promoCodeDetails = action.payload;
      console.log("promo payload", state.promoCodeDetails, action.payload);
      console.log("promo payload success", state.promoCodeDetails.success);
      if (state.promoCodeDetails.success) {
        console.log("eneter success");
        state.promoStatus.message = "";
        state.promoStatus.status = true;
      } else {
        console.log("enetred failure");
        console.log("message", state.promoCodeDetails.message);
        if (state.promoCodeDetails.message === "Promotion code error :Promotion code not found") {
          console.log("enter not found");
          state.promoStatus.message = "Code is invalid";
          state.promoStatus.status = false;
        } else if (state.promoCodeDetails.message === "Promotion code error : Enter the correct origin city.,Enter the correct destination city.,Wrong Condition") {
          console.log("origin");
          state.promoStatus.message = "Code is invalid";
          state.promoStatus.status = false;
        } else if (state.promoCodeDetails.message === "Promotion code error: Promotion code expired") {
          console.log("expired");
          state.promoStatus.message = "Code expired";
          state.promoStatus.status = false;
        }
      }
      console.log("state", state.promoCodeDetails);
      state.codeStatus = "success";
    },
    getPromoCodeFail: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload;
    },
    setPromoModalOpen: state => {
      state.promoModal = true;
      state.promoDelete = false;
    },
    setPromoModalClose: state => {
      state.promoModal = false;
    },
    setPromoCodeValue: (state, action) => {
      state.promoValues = action.payload;
    },
    clearPromoCodeValue: state => {
      state.promoValues = "";
      state.promoData = {};
      state.error = "";
    },
    getPromoCodeRequest: state => {
      state.isLoading = true;
    },
    getPromoCodeDataSuccess: (state, action) => {
      state.isLoading = false;
      state.promoData = action.payload;
      state.error = "";
    },
    getPromoCodetDataFailure: (state, action) => {
      state.isLoading = false;
      state.promoData = {};
      state.error = action.payload;
    },
    clearPromoCodeValues: state => {
      state.promoValues = "";
      state.promoData = {};
      state.error = "";
      state.promoDelete = true;
    },
    getPromoCodeTermSuccess: (state, action) => {
      state.isLoading = false;
      state.promoTerms = action.payload;
      state.error = "";
    },
    getPromoCodetTermFailure: (state, action) => {
      state.isLoading = false;
      state.promoTerms = {};
      state.error = "";
    }
  }
});
export const {
  getPromoCodeSuccess,
  getPromoCodeFail,
  getPromoCodePending,
  setPromoModalOpen,
  setPromoModalClose,
  setPromoCodeValue,
  clearPromoCodeValue,
  getPromoCodeRequest,
  getPromoCodeDataSuccess,
  getPromoCodetDataFailure,
  clearPromoCodeValues,
  getPromoCodeTermSuccess,
  getPromoCodetTermFailure
} = promoSlice.actions;
export default promoSlice.reducer;