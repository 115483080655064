import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { helpFaqRequest, helpFaqSuccess, helpFaqFailure } from "../slice/helpFaqSlice";
import { config } from "../../../../src/config/global";
const Help_FAQ_URL = config?.HELP_FAQ;
const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const HelpFaqData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
      memberId
    },
    loader: true
  };
  let HelpFaqDataPayload = {
    memberId: payload?.memberId,
    feedbackType: payload?.feedbackType,
    description: payload?.description,
    isThaiServicesFeedback: payload?.isThaiServicesFeedback,
    isParticularFlight: payload?.isParticularFlight,
    customerContactNo: payload?.customerContactNo,
    customerEmail: payload?.customerEmail
  };
  const response = await api.post(Help_FAQ_URL, payload, data);
  console.log(payload, "line44");
  return response;
};
function* getHelpFaqData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(HelpFaqData, payload);
    yield put(helpFaqSuccess(response.data));
  } catch (error) {
    yield put(helpFaqFailure(error));
  }
}
export function* watchHelpFaqDate() {
  yield takeLatest(helpFaqRequest.type, getHelpFaqData);
}