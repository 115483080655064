import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ropSignupDetails: {},
  ropOTPDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  ropStatus: {
    status: false,
    message: ""
  }
};
const ropSignupSlice = createSlice({
  name: "ropSignup",
  initialState,
  reducers: {
    getRopSignUpDataPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getRopSignUpSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.ropSignupDetails = action.payload;
      if (state.ropSignupDetails.code === "I110") {
        state.ropStatus.message = "Member Added";
        state.ropStatus.status = true;
        sessionStorage.setItem("memberId", state.ropSignupDetails.memberId);
      }
      state.codeStatus = "success";
    },
    getRopSignupFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.ropSignupDetails = action.payload.response.data;
      if (state.ropSignupDetails.code === "I850") {
        state.ropStatus.message = "Duplicate";
        state.ropStatus.status = false;
      } else {
        state.ropStatus.message = "Something went wrong";
        state.ropStatus.status = false;
      }
    },
    updateSignInState: (state, action) => {
      const {
        updatedState
      } = action.payload;
      state.isSignin = updatedState;
    },
    getRopOTPPending: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getRopOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.ropOTPDetails = action.payload;
      if (state.ropOTPDetails.code === "I110") {
        state.ropStatus.message = "Member Added";
        state.ropStatus.status = true;
        sessionStorage.setItem("memberId", state.ropSignupDetails.memberId);
      }
      state.codeStatus = "success";
    },
    getRopOTPFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.ropOTPDetails = action.payload.response.data;
      if (state.ropOTPDetails.code === "I850") {
        state.ropStatus.message = "Duplicate";
        state.ropStatus.status = false;
      } else {
        state.ropStatus.message = "Something went wrong";
        state.ropStatus.status = false;
      }
      state.ropStatus.status = false;
    },
    getRopOTPReset: state => {
      state.codeStatus = "";
      state.ropSignupDetails = {};
      state.ropOTPDetails = {};
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.ropStatus = {
        status: false,
        message: ""
      };
    }
  }
});
export const {
  getRopSignUpDataPending,
  getRopSignupFailure,
  getRopSignUpSuccess,
  updateSignInState,
  getRopOTPPending,
  getRopOTPSuccess,
  getRopOTPFailure,
  getRopOTPReset
} = ropSignupSlice.actions;
export default ropSignupSlice.reducer;