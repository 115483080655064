import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  earnedMilesInfo: [],
  earnedMilesInfoData: {},
  savedWidgetData: null,
  savedWidgetDataDup: null,
  isRedeem: "",
  noMilesArray: [],
  isLoading: false,
  hasData: false,
  error: "",
  updatedEarnMiles: [],
  saveAirlineCodeReq: "",
  saveAirlineDataReq: "",
  termsConditionData: "",
  termsConditionError: "",
  redeemMilesClassesData: "",
  redeemMilesClassesError: "",
  earnMilesClassesData: "",
  earnMilesClassesError: ""
};
const earnedMilesSlice = createSlice({
  name: "earnedMiles",
  initialState,
  reducers: {
    earnedMilesRequest: state => {
      state.isLoading = true;
    },
    earnedMilesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.earnedMilesInfo = action.payload.data;
    },
    earnedMilesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.earnedMilesInfo = [];
      state.earnedMilesInfoData = {};
    },
    earned_miles_info_data: (state, action) => {
      state.earnedMilesInfoData = action.payload;
    },
    noMilesArrayState: (state, action) => {
      state.noMilesArray = action.payload;
    },
    savedWidgetDataRequest: (state, action) => {
      state.savedWidgetData = action.payload;
    },
    savedWidgetDataDupRequest: (state, action) => {
      state.savedWidgetDataDup = action.payload;
    },
    clearSavedWidgetDataRequest: (state, action) => {
      state.savedWidgetData = null;
    },
    clearEarnedMilesInfoData: state => {
      state.earnedMilesInfo = [];
      state.earnedMilesInfoData = {};
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
    },
    handleRedeemptionState: (state, action) => {
      state.isRedeem = action.payload;
    },
    clearHandleRedeemptionState: (state, action) => {
      state.isRedeem = "";
    },
    updatedEarnMilesData: (state, action) => {
      state.updatedEarnMiles = action.payload;
    },
    clearUpdatedEarnMilesData: state => {
      state.updatedEarnMiles = [];
    },
    store_airline_code: (state, action) => {
      state.saveAirlineCodeReq = action.payload;
    },
    store_airline_data: (state, action) => {
      state.saveAirlineDataReq = action.payload;
    },
    milesCalculatorTermsConditionRequest: state => {
      state.isLoading = true;
    },
    milesCalculatorTermsConditionSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsConditionData = action.payload;
      state.termsConditionError = "";
    },
    milesCalculatorTermsConditionFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsConditionError = action.payload;
    },
    redeemMilesClassesRequest: state => {
      state.isLoading = true;
    },
    redeemMilesClassesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.redeemMilesClassesData = action.payload;
      state.redeemMilesClassesError = "";
    },
    redeemMilesClassesFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.redeemMilesClassesError = action.payload;
    },
    earnMilesClassesRequest: state => {
      state.isLoading = true;
    },
    earnMilesClassesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.earnMilesClassesData = action.payload;
      state.earnMilesClassesError = "";
    },
    earnMilesClassesFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.earnMilesClassesError = action.payload;
    }
  }
});
export const {
  earnedMilesRequest,
  earnedMilesSuccess,
  earnedMilesFailure,
  earned_miles_info_data,
  clearEarnedMilesInfoData,
  savedWidgetDataRequest,
  savedWidgetDataDupRequest,
  clearSavedWidgetDataRequest,
  handleRedeemptionState,
  clearHandleRedeemptionState,
  noMilesArrayState,
  updatedEarnMilesData,
  clearUpdatedEarnMilesData,
  store_airline_code,
  store_airline_data,
  milesCalculatorTermsConditionRequest,
  milesCalculatorTermsConditionSuccess,
  milesCalculatorTermsConditionFailure,
  redeemMilesClassesRequest,
  redeemMilesClassesSuccess,
  redeemMilesClassesFailure,
  earnMilesClassesRequest,
  earnMilesClassesSuccess,
  earnMilesClassesFailure
} = earnedMilesSlice.actions;
export default earnedMilesSlice.reducer;