import { call, put, takeEvery } from "redux-saga/effects";
import { config } from "../../../config/global";
import { preSelectSubmitMealRequest, preSelectSubmitMealSuccess, preSelectSubmitMealFailure } from "../slice/preSelectMealSubmitSlice";
import api from "../../../config/api";
const PRESELECT_SUBMIT_API = config.PRESELECT_SUBMIT_MEAL;
const preSelectSubmitData = async payload => {
  const data = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.post(PRESELECT_SUBMIT_API, payload, data);
  return response;
};
function* fetchPreSelectSubmitMeal(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(preSelectSubmitData, payload);
    yield put(preSelectSubmitMealSuccess(response));
  } catch (error) {
    yield put(preSelectSubmitMealFailure(error?.response));
  }
}
export function* watchPreSelectMealSubmit() {
  yield takeEvery(preSelectSubmitMealRequest.type, fetchPreSelectSubmitMeal);
}