import axios from "axios";
import { store } from "../store";
import { setError } from "../OSCI/src/slice/errorModalSlice";
import { getApiBaseUrl } from "../utils/helper";
import i18n from "../i18n";
//process.env variable to be added
//apiconfig/ apiconstants to be added
const api = axios.create({
  baseURL: getApiBaseUrl("api")
});
let numberOfAjaxCAllPending = 0;

// Request interceptor
api.interceptors.request.use(config => {
  console.log("config", config?.url);
  // Modify the request config here (e.g., add headers, authentication tokens)
  //const accessToken:any = JSON.parse(sessionStorage.getItem("token") || '{}') ;
  // ** If token is present add it to request's Authorization Header
  // if (Object.keys(accessToken).length > 0) {
  //   if (config.headers) {
  //     config.headers.token = `Bearer ${accessToken}`;
  //   }
  // }
  if (config?.loader) {
    numberOfAjaxCAllPending++;
    document.documentElement.style.setProperty("--suspense-loading-spinner", "none");
    document.documentElement.style.setProperty("--loading-spinner", "block");
  }
  if (config.headers) {
    config.headers.source = `website`;
    config.headers.hostName = window.location.origin;
    config.headers["Accept-Language"] = i18n?.language;
  }
  const authToken = typeof window !== "undefined" && window?.localStorage?.getItem("accesstoken");
  console.log("authorization", config?.headers?.Authorization);
  if (authToken) {
    config.headers.Authorization = authToken;
  }
  if (config?.headers?.Authorization?.includes("Basic") || config?.url?.startsWith("/custom_config")) {
    console.log("Basic auth", config?.headers?.Authorization);
    delete config.headers.Authorization;
  }
  const checkInAuthToken = typeof window !== "undefined" && window?.sessionStorage?.getItem("checkintoken");
  if (checkInAuthToken && window.location?.pathname?.toLowerCase()?.includes("checkin")) {
    config.headers.AccessToken = checkInAuthToken;
  }
  return config;
}, error => {
  // Handle request errors here

  return Promise.reject(error);
});

// Response interceptor
api.interceptors.response.use(response => {
  // Modify the response data here (e.g., parse, transform)
  if (response?.config?.loader) {
    numberOfAjaxCAllPending--;
  }
  if (numberOfAjaxCAllPending === 0) {
    document.documentElement.style.setProperty("--loading-spinner", "none");
    document.documentElement.style.setProperty("--suspense-loading-spinner", "block");
  }

  // document.documentElement.style.setProperty("--loading-spinner", "none");
  return response;
}, error => {
  if (error?.config?.isGenericErr) {
    const errorStatus = error?.response?.status || error?.status;
    // Handle response errors here
    if (error.code === "ERR_NETWORK") {
      store.dispatch(setError({
        message: i18n.t("error_network_failed"),
        heading: "Network Error",
        buttonText: i18n.t("button_too_many_codes_ok")
      }));
    } else if (errorStatus === 401 || errorStatus === 403) {
      // Handle unauthorised scenario
      store.dispatch(setError({
        message: i18n.t("error_unauthorised"),
        heading: errorStatus === 401 ? i18n.t("label_unauthorised") : i18n.t("error_login_session_expired"),
        buttonText: i18n.t("label_enroll_sign_in"),
        errCode: errorStatus,
        isLoginReq: true,
        type: "sessionExpired"
      }));
    } else if (errorStatus === 500 || errorStatus === 501 || errorStatus === 502 || errorStatus === 503 || errorStatus === 504) {
      store.dispatch(setError({
        message: i18n.t("error_request_failed"),
        heading: "Server Error",
        buttonText: i18n.t("button_too_many_codes_ok")
      }));
    } else if (errorStatus === 404 || errorStatus === 405 || errorStatus === 408 || errorStatus === 429) {
      store.dispatch(setError({
        message: i18n.t("error_request_failed"),
        heading: "Client Error",
        buttonText: i18n.t("button_too_many_codes_ok")
      }));
    }
  }
  if (error?.config?.loader) {
    numberOfAjaxCAllPending--;
  }
  if (numberOfAjaxCAllPending === 0) {
    document.documentElement.style.setProperty("--loading-spinner", "none");
    document.documentElement.style.setProperty("--suspense-loading-spinner", "block");
  }
  return Promise.reject(error);
});
export default api;