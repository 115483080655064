import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getMileageStatementDatePending, getMileageStatementFailure, getMileageStatementPending, getMileageStatementSuccess, getMilesAwardDetailDataPending, getMilesAwardDetailFailure, getMilesAwardDetailSuccess, getMilesAwardListDataPending, getMilesAwardListFailure, getMilesAwardListSuccess, getMilesStatementReportFailure, getMilesStatementReportPending, getMilesStatementReportSuccess } from "../slice/mileageStatementSlice";
import { config } from "../../../config/global";
const accessToken = localStorage.getItem("accesstoken");
const MileageStatementPeriod = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  let mileageStatementPayload = {
    filterBy: payload.filterData,
    month: payload.monthData,
    filterType: payload?.filterBy
  };
  const response = await api.post(config.MILEAGE_STATEMENT, mileageStatementPayload, data);
  return response;
};
function* getMileageStatementByPeriodData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(MileageStatementPeriod, payload);
    yield put(getMileageStatementSuccess(response.data));
  } catch (error) {
    yield put(getMileageStatementFailure(error.message));
  }
}
const MileageAwardListHandler = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  let mileageStatementPayload = {};
  if (payload.filterData === "ByPeriod") {
    mileageStatementPayload = {
      filterBy: payload.filterData,
      month: payload.monthData,
      filterType: payload?.filterBy
    };
  } else {
    mileageStatementPayload = {
      filterBy: payload.filterBy,
      startMonth: payload.startMonth,
      endMonth: payload.endMonth,
      month: payload.month,
      //need to check
      filterType: payload.filterType
    };
  }
  const response = await api.post(config.MILEAGE_AWARD_LIST, mileageStatementPayload, data);
  return response;
};
function* getMileageAwardListDataGenerator(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(MileageAwardListHandler, payload);
    yield put(getMilesAwardListSuccess(response.data));
  } catch (error) {
    yield put(getMilesAwardListFailure(error.message));
  }
}
export function* watchMileageStatementAwardListData() {
  yield takeLatest(getMilesAwardListDataPending.type, getMileageAwardListDataGenerator);
}
const MileageAwardDetailHandler = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  let mileageStatementPayload = {
    requestNumber: payload.requestNo
  };
  const response = await api.post(config.MILEAGE_AWARD_DETAIL, mileageStatementPayload, data);
  response.data.requestNumber = payload.requestNo;
  return response;
};
function* getMileageAwardDetailGenerator(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(MileageAwardDetailHandler, payload);
    yield put(getMilesAwardDetailSuccess(response.data));
  } catch (error) {
    yield put(getMilesAwardDetailFailure(error.message));
  }
}
export function* watchMileageStatementAwardDetailData() {
  yield takeLatest(getMilesAwardDetailDataPending.type, getMileageAwardDetailGenerator);
}
const MileageReportDetailHandler = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  let mileageStatementPayload = {};
  if (payload.filterData === "ByPeriod") {
    mileageStatementPayload = {
      filterBy: payload.filterData,
      month: payload.monthData
    };
  } else {
    if (payload?.filterType) {
      mileageStatementPayload = {
        filterBy: payload.filterData,
        startMonth: payload.startMonth,
        endMonth: payload.endMonth,
        month: payload.monthData,
        filterType: payload.filterType
      };
    } else {
      mileageStatementPayload = {
        filterBy: payload.filterData,
        startMonth: payload.startMonth,
        endMonth: payload.endMonth,
        month: payload.monthData
      };
    }
  }
  const response = await api.post(config.MILEAGE_REPORT, mileageStatementPayload, data);
  return response;
};
function* getMileageReportDetailGenerator(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(MileageReportDetailHandler, payload);
    yield put(getMilesStatementReportSuccess(response.data));
  } catch (error) {
    yield put(getMilesStatementReportFailure(error.message));
  }
}
export function* watchMileageStatementReportData() {
  yield takeLatest(getMilesStatementReportPending.type, getMileageReportDetailGenerator);
}
export function* watchMileageStatementData() {
  yield takeLatest(getMileageStatementPending.type, getMileageStatementByPeriodData);
}
const MileageStatementDate = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  let mileageStatementPayload = {
    filterBy: payload.filterBy,
    startMonth: payload.startMonth,
    endMonth: payload.endMonth,
    month: payload.month,
    // need to check
    filterType: payload.filterType
  };
  const response = await api.post(config.MILEAGE_STATEMENT, mileageStatementPayload, data);
  return response;
};
function* getMileageStatementByDateData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(MileageStatementDate, payload);
    yield put(getMileageStatementSuccess(response.data));
  } catch (error) {
    yield put(getMileageStatementFailure(error.message));
  }
}
export function* watchMileageStatementByDateData() {
  yield takeLatest(getMileageStatementDatePending.type, getMileageStatementByDateData);
}