import { takeLatest } from "redux-saga/effects";
// import api from "../config/api";

import { thaiCardDataRequest } from "../slice/thaiCardApplicationSlice";

// const THAICARDURL = "./osci/json/thaiCardApplication.json";

function* getThaiCardData() {
  // try {
  //   // const response = yield call(axios.get, THAICARDURL);
  //   // yield put(getThaiCardDataSuccess(response.data));
  // } catch (error: any) {
  //   yield put(getThaiCardDataFailure(error.message));
  // }
}
export function* watchThaiCardData() {
  yield takeLatest(thaiCardDataRequest.type, getThaiCardData);
}