import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  preSelectMeals: [],
  flightDetails: [],
  isLoading: false,
  hasData: false,
  error: "",
  termsConditionData: "",
  termsConditionError: ""
};
const preSelectSlice = createSlice({
  name: "preSelect",
  initialState,
  reducers: {
    preselectTermsConditionRequest: state => {
      state.isLoading = true;
    },
    preselectTermsConditionSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsConditionData = action.payload;
      state.termsConditionError = "";
    },
    preselectTermsConditionFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsConditionError = action.payload;
    },
    preSelectMealRequest: state => {
      state.isLoading = true;
    },
    preSelectMealSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.preSelectMeals = action.payload;
    },
    preSelectMealFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetPreSelectMeal: state => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.preSelectMeals = [];
      state.flightDetails = [];
    },
    updateMeals: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.preSelectMeals.data.flightDetails = state.preSelectMeals.data.flightDetails.map((flight, index) => ({
        ...flight,
        paxDetails: flight.paxDetails.map(pax => action.payload.index === index && pax?.paxRef === action.payload.paxRef ? {
          ...pax,
          meals: action.payload.newMeals,
          mealIds: action.payload.mealIds
        } : pax)
      }));
    }
  }
});
export const {
  preSelectMealRequest,
  preSelectMealSuccess,
  preSelectMealFailure,
  updateMeals,
  preselectTermsConditionRequest,
  preselectTermsConditionSuccess,
  preselectTermsConditionFailure,
  resetPreSelectMeal
} = preSelectSlice.actions;
export default preSelectSlice.reducer;