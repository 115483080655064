import { createSlice } from "@reduxjs/toolkit";
// const initialState: PreferenceDataState = {
//   countryList: [],
//   isLoading: false, 
//   error: "",
//   hasData: false,
// };

const initialState = {
  isLoading: false,
  hasData: false,
  isError: false,
  error: "",
  goldtrackinformation: []
};
const goldtrackinfoSlice = createSlice({
  name: "goldtrackinfo",
  initialState,
  reducers: {
    goldtrackFromDataRequest: state => {
      state.isLoading = true;
      state.error = "";
    },
    getgoldtrackFromDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      let preference = [];
      preference = action.payload.map(item => ({
        cityName: item.cityName,
        countryName: " - " + item.countryName,
        airportCode: "[" + item.airportCode + "]",
        airportName: item.airportName
      }));
      state.countryList = preference;
    },
    getgoldtrackFromDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    goldtrackrequest: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.isError = false;
      state.goldtrackinformation = [];
    },
    goldtracksuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.goldtrackinformation = action.payload;
      state.error = "";
      state.isError = false;
    },
    goldtrackFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.isError = true;
    }
  }
});
export const {
  goldtrackFromDataRequest,
  getgoldtrackFromDataSuccess,
  getgoldtrackFromDataFailure,
  goldtrackrequest,
  goldtracksuccess,
  goldtrackFailure
} = goldtrackinfoSlice.actions;
export default goldtrackinfoSlice.reducer;