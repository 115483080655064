import api from "../../../config/api";
import { tripDataRequest, getTripDataSuccess, getTripDataFailure, updateTripsPnrDetailsSuccess, updateTripsPnrDetailsFailure, updateTripsPnrDetailsRequest } from "../slice/tripSlice";
import { put, takeLatest, call } from "redux-saga/effects";
import { config } from "../../../config/global";
const fetchHeaderData = async payload => {
  const FETCH_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    loader: false
  };
  const response = await api.get(FETCH_API_URL, data);
  return response;
};
const updateHeaderData = async payload => {
  const RETRIVE_PNR_API_URL = config.TEAMSITE_RETRIEVE_PNR_API;
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    loader: true
  };
  let pnrPayload = {
    pnr: payload.pnr,
    lastName: payload.lastName,
    pastTrip: payload.pastTrip
  };
  const response = await api.post(RETRIVE_PNR_API_URL, pnrPayload, headerData);
  return response;
};
function* getTripsData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchHeaderData, payload);
    let modifiedResponse = {
      ...response.data,
      pnrDetails: response.data.pnrDetails.map((pnrDetail, index) => {
        if (index > 2) {
          return {
            ...pnrDetail,
            flightDetails: []
          };
        }
        return pnrDetail;
      })
    };
    yield put(getTripDataSuccess(modifiedResponse));
  } catch (error) {
    yield put(getTripDataFailure(error.message));
  }
}
function* updatePNRDetailsData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(updateHeaderData, payload);
    yield put(updateTripsPnrDetailsSuccess({
      payload: response.data.flightDetails,
      index: payload.cardId
    }));
  } catch (error) {
    yield put(updateTripsPnrDetailsFailure(error.message));
  }
}
export function* watchManageBookingData() {
  yield takeLatest(tripDataRequest.type, getTripsData);
}
export function* watchUpdatePNRDetailsSaga() {
  yield takeLatest(updateTripsPnrDetailsRequest.type, updatePNRDetailsData);
}