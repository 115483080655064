import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { flightStatusByNumberFailure, flightStatusByNumberSuccess, flightStatusByNumberRequest } from "../slice/flightStatusSlice";
import { config } from "../../../config/global";
const FLIGHT_STATUS_NUMBER_URL = config?.FLIGHT_STATUS_NUMBER;
const accessToken = sessionStorage.getItem("accesstoken");
const FlightStatusNumberData = async (payload, isArray) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true
  };
  if (!isArray) {
    let flightStatusPayload = {
      departureDate: payload.date,
      flightNum: payload.flightNumber,
      callingAirline: payload.callingAirline
    };
    const response = await api.post(FLIGHT_STATUS_NUMBER_URL, flightStatusPayload, data);
    return response;
  } else {
    const result = [];
    for (const param of payload) {
      let flightStatusPayload = {
        departureDate: param.departureDate,
        flightNum: param.flightNum,
        callingAirline: param.callingAirline
      };
      const response = await api.post(FLIGHT_STATUS_NUMBER_URL, flightStatusPayload, data);
      result.push(response.data);
    }
    return result;
  }
};
function* getFlightStatusByNumberData(action) {
  try {
    const {
      payload
    } = action;
    if (Array.isArray(payload)) {
      const response = yield call(FlightStatusNumberData, payload, Array.isArray(payload));
      yield put(flightStatusByNumberSuccess(response));
    } else {
      const response = yield call(FlightStatusNumberData, payload, false);
      yield put(flightStatusByNumberSuccess(response.data));
    }
  } catch (error) {
    yield put(flightStatusByNumberFailure(error.message));
  }
}
export function* watchFlightStatusByNumber() {
  yield takeLatest(flightStatusByNumberRequest.type, getFlightStatusByNumberData);
}