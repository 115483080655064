import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectMealsList: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const mealsListSlice = createSlice({
  name: "mealsList",
  initialState,
  reducers: {
    mealsListRequest: state => {
      state.isLoading = true;
    },
    mealsListSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.selectMealsList = action.payload;
    },
    mealsListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  mealsListRequest,
  mealsListSuccess,
  mealsListFailure
} = mealsListSlice.actions;
export default mealsListSlice.reducer;