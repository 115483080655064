import { createSlice } from "@reduxjs/toolkit";
// import interlineJson from "../../../../../../../interlineNotCheckedin.json";
// import multiCityJson from "../../../../../../../../Downloads/Multicity.json"

const initialState = {
  checkinDetails: {},
  isLoading: false,
  hasData: false,
  checkInSuccess: false,
  checkinPnr: {},
  selectedJourney: [],
  headerData: {},
  error: ""
};
const fetchCheckInSlice = createSlice({
  name: "checkin",
  initialState,
  reducers: {
    retrieveJourneyRequest: (state, action) => {
      state.isLoading = true;
      state.checkInSuccess = false;
      state.error = "";
    },
    retrieveJourneySuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.checkInSuccess = true;
      state.checkinDetails = action.payload;
    },
    retrieveJourneyFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      state.checkInSuccess = false;
      state.checkinDetails = {};
    },
    resetJourneyDetails: state => {
      state.isLoading = false;
      state.checkinDetails = {};
      state.checkInSuccess = false;
      state.hasData = false;
      state.error = "";
    },
    setPNRandLastName: (state, action) => {
      state.checkinPnr = action.payload;
    },
    resetPNRDetails: state => {
      state.checkinPnr = {};
    },
    updateJourneyResponse: (state, action) => {
      state.checkinDetails = state?.checkinDetails?.map(item => item?.id === action?.payload?.id ? {
        ...item,
        ...action.payload
      } : item);
    },
    setHeaderData: (state, action) => {
      state.headerData = action.payload;
    }
  }
});
export const {
  retrieveJourneyRequest,
  retrieveJourneySuccess,
  retrieveJourneyFailure,
  resetJourneyDetails,
  resetPNRDetails,
  setPNRandLastName,
  updateJourneyResponse,
  setHeaderData
} = fetchCheckInSlice.actions;
export default fetchCheckInSlice.reducer;