import { call, put, takeEvery } from "redux-saga/effects";
import { excessBaggageRequest, excessBaggageSuccess, excessBaggageFailure } from "../slice/excessBaggageSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config?.EXCESS_BAGGAGE_API;
const ExcessBaggageDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true
  };
  const response = await api.post(API_URL, payload, headerData);
  return response;
};
function* fetchExcessBaggageDetails(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(ExcessBaggageDetail, payload);
    yield put(excessBaggageSuccess(response));
  } catch (error) {
    yield put(excessBaggageFailure(error?.response));
  }
}
export function* watchExcessBaggage() {
  yield takeEvery(excessBaggageRequest.type, fetchExcessBaggageDetails);
}