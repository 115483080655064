import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getResubscribeDataPending, getReSubscribeSuccess, getReSubscribeFailure } from "../slice/resubscribeSlice";
const RESUBSCRIBE_URL = "common/subscription/resubscribe";
const fetchResubscribeData = async UnsubscribePayload => {
  let resubscribePayloadData = {
    email: UnsubscribePayload.email
  };
  let headerData = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const response = await api.put(`${RESUBSCRIBE_URL}?email=${resubscribePayloadData.email}`, headerData);
  return response;
};
function* getResubscribeData(action) {
  try {
    const response = yield call(fetchResubscribeData, action.payload);
    yield put(getReSubscribeSuccess(response.data));
  } catch (error) {
    yield put(getReSubscribeFailure(error));
  }
}
export function* watchResubscribeData() {
  yield takeLatest(getResubscribeDataPending.type, getResubscribeData);
}