import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  MiceOffersDetails: [],
  EventList: [],
  eventSuccess: false,
  isLoading: false,
  error: "",
  isError: false,
  hasData: false
};
const miceOffersSlice = createSlice({
  name: "miceOffer",
  initialState,
  reducers: {
    miceOffersDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getMiceOffersDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.MiceOffersDetails = action.payload;
      state.isError = false;
    },
    getMiceOffersDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    eventListRequest: state => {
      state.isLoading = true;
    },
    eventListSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.EventList = action.payload;
      state.isError = false;
    },
    eventListFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    submitEventRequest: (state, action) => {
      state.isLoading = true;
    },
    submitEventSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.eventSuccess = true;
      state.isError = false;
    },
    submitEventFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    }
  }
});
export const {
  miceOffersDataRequest,
  getMiceOffersDataSuccess,
  getMiceOffersDataFailure,
  eventListRequest,
  eventListSuccess,
  eventListFailure,
  submitEventRequest,
  submitEventSuccess,
  submitEventFailure
} = miceOffersSlice.actions;
export default miceOffersSlice.reducer;