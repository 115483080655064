import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { travelClassRequest, travelClassSuccess, travelClassFailure } from "../slice/travelClassSlice";
import { config } from "../../../../src/config/global";
const Travel_Class_URL = config.Travel_Class_API;
const accessToken = sessionStorage.getItem("accesstoken");
const TravelClassData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true,
    isGenericErr: true
  };
  let TravelClassPayload = {
    departure: payload?.departure,
    arrival: payload?.arrival,
    departureDate: payload?.departureDate,
    mc: payload?.mc,
    flightNum: payload?.flightNum
  };
  const response = await api.post(Travel_Class_URL, payload, data);
  return response;
};
function* getTravelClassData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(TravelClassData, payload);
    yield put(travelClassSuccess(response.data));
  } catch (error) {
    yield put(travelClassFailure(error));
  }
}
export function* watchTravelClassData() {
  yield takeLatest(travelClassRequest.type, getTravelClassData);
}