import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { swMagDataRequest, getSWDataSuccess, getSWDataFailure } from "../slice/sawasdeeMagSlice";
import { adCarouselDataRequest, getAdCarouselDataSuccess, getAdCarouselDataFailure } from "../slice/adCarouselSlice";
import i18n from "../../../i18n";
const accessToken = localStorage.getItem("accesstoken");
const headerData = {
  mode: "no-cors",
  headers: {
    Authorization: accessToken,
    "Content-Type": "application/json"
  },
  loader: true
  // isGenericErr: true,
};
const API_URL = config.SAWASDHEE_INFORMATION + '?post_type=pocket_guide';
const sawasdeeInit = async payload => {
  const response = await api.get(API_URL, headerData);
  return response;
};
function* getsawasdeeinformation(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(sawasdeeInit, payload);
    yield put(getSWDataSuccess(response.data));
  } catch (error) {
    yield put(getSWDataFailure(error.message));
  }
}
const getcarusole = async payload => {
  const API_URLS = config.SAWASDHEE_INFORMATION;
  const headerData = {
    mode: "no-cors",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": `${i18n.language !== "th-th" ? i18n.language : "th"}`
    },
    loader: true
    // isGenericErr: true,
  };
  const response = await api.get(API_URLS, headerData);
  return response;
};
function* getcaruoselinformation(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(getcarusole, payload);
    yield put(getAdCarouselDataSuccess(response.data));
  } catch (error) {
    yield put(getAdCarouselDataFailure(error.message));
  }
}
export function* watchsawasdeeinformationdata() {
  yield takeLatest(swMagDataRequest.type, getsawasdeeinformation);
}
export function* watchcaruoselinformationdata() {
  yield takeLatest(adCarouselDataRequest.type, getcaruoselinformation);
}