import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { getRopSignUpDataPending, getRopSignUpSuccess, getRopSignupFailure, getRopOTPPending, getRopOTPSuccess, getRopOTPFailure } from "../slice/ropSignUpSlice";
const fetchRopSignupData = async ropPayload => {
  let RopSignupPayloadData = {
    configId: ropPayload?.configId,
    otpRefKey: ropPayload?.otpRefKey,
    otp: ropPayload?.otp,
    salutation: ropPayload.title,
    firstName: ropPayload.firstName,
    lastName: ropPayload.lastName,
    gender: ropPayload.gender,
    address: {
      addressType: "H",
      addressLine1: ropPayload.addressLine1,
      ...(ropPayload.addressLine2 && {
        addressLine2: ropPayload.addressLine2
      }),
      city: ropPayload.city,
      state: ropPayload.state,
      countryCode: ropPayload.countryCodeSecondary,
      postalCode: ropPayload.postalCode
    },
    phone: {
      countryCode: ropPayload.countryCode,
      areaCode: "0",
      number: ropPayload.contactNumber?.replaceAll("-", "")
    },
    emailAddress: ropPayload.email,
    birthDate: ropPayload.dob,
    enrollSource: "INET",
    language: ropPayload.languagePreference,
    sponsorID: ropPayload.referringMemberID,
    SSRSeat: ropPayload.seatPreference,
    SSRMeal: ropPayload.mealPreference,
    thaiOfferIndicator: ropPayload.ropNewsCheck,
    partnerOfferIndicator: ropPayload.thirdPartyCheck,
    pinNumber: ropPayload.createPassword
  };
  let RopParentLoadPayloadData = {
    configId: ropPayload?.configId,
    otpRefKey: ropPayload?.otpRefKey,
    otp: ropPayload?.otp,
    salutation: ropPayload.title,
    firstName: ropPayload.firstName,
    lastName: ropPayload.lastName,
    gender: ropPayload.gender,
    address: {
      addressType: "H",
      addressLine1: ropPayload.addressLine1,
      ...(ropPayload.addressLine2 && {
        addressLine2: ropPayload.addressLine2
      }),
      city: ropPayload.city,
      countryCode: ropPayload.countryCodeSecondary,
      postalCode: ropPayload.postalCode
    },
    phone: {
      countryCode: ropPayload.countryCode,
      areaCode: "0",
      number: ropPayload.contactNumber
    },
    consent: {
      consentFlag: true,
      consentParentName: `${ropPayload.consentParentFirstName} ${ropPayload.consentParentLastName}`
    },
    emailAddress: ropPayload.email,
    birthDate: ropPayload.dob,
    enrollSource: "INET",
    language: ropPayload.languagePreference,
    sponsorID: "",
    SSRSeat: ropPayload.seatPreference,
    SSRMeal: ropPayload.mealPreference,
    thaiOfferIndicator: ropPayload.ropNewsCheck,
    partnerOfferIndicator: ropPayload.thirdPartyCheck,
    pinNumber: ropPayload.createPassword,
    airportCode: ropPayload.cityCode
  };
  const response = await api.post(config.ROP_SIGNUP_API_URL, ropPayload.consentParentFirstName != null ? RopParentLoadPayloadData : RopSignupPayloadData);
  if (response.status === 200) {
    sessionStorage.setItem("password", RopSignupPayloadData.pinNumber);
    sessionStorage.setItem("DOB", ropPayload.dob);
    sessionStorage.setItem("ropProName", ropPayload.firstName + ropPayload.lastName);
  }
  return response;
};
function* getROpSIgnupData(action) {
  try {
    const response = yield call(fetchRopSignupData, action.payload);
    yield put(getRopSignUpSuccess(response.data));
  } catch (error) {
    yield put(getRopSignupFailure(error));
  }
}
export function* watchRopSIgnupData() {
  yield takeLatest(getRopSignUpDataPending.type, getROpSIgnupData);
}
const fetchRopOTPData = async data => {
  const response = await api.post(config.ROP_OTP_URL, data);
  return response;
};
function* getROpOTPData(action) {
  try {
    const response = yield call(fetchRopOTPData, action.payload);
    yield put(getRopOTPSuccess(response.data));
  } catch (error) {
    yield put(getRopOTPFailure(error));
  }
}
export function* watchRopOTPData() {
  yield takeLatest(getRopOTPPending.type, getROpOTPData);
}