import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  fares: [],
  isLoading: false,
  hasData: false,
  error: ""
};
const PopularFareSlice = createSlice({
  name: "popularFare",
  initialState,
  reducers: {
    popularFareDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getPopularFareDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.fares = action.payload;
    },
    getPopularFareDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  popularFareDataRequest,
  getPopularFareDataSuccess,
  getPopularFareDataFailure
} = PopularFareSlice.actions;
export default PopularFareSlice.reducer;