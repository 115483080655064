import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isPanelClosed: false,
  userName: ""
};
const LoginPanelSlice = createSlice({
  name: "loginpanel",
  initialState,
  reducers: {
    setIsPanelClosed: (state, action) => {
      state.isPanelClosed = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    clearUserName: state => {
      state.userName = "";
    }
  }
});
export const {
  setIsPanelClosed,
  setUserName,
  clearUserName
} = LoginPanelSlice.actions;
export default LoginPanelSlice.reducer;