import { call, put, takeLatest } from "redux-saga/effects";
import { preferenceFromDataRequest, getPreferenceFromDataSuccess, getPreferenceFromDataFailure } from "../slice/newsletterPreferenceSlice";
import { config } from "../../../config/global";
import api from "../../../config/api";
const API_URL = config.NEWSLETTER_PROMOTIONS_FROM_OPTIONS_API;
const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true
};
const fetchPreferenceList = async () => {
  const response = await api.get(API_URL, data);
  return response;
};
function* getPreferenceFromData() {
  try {
    const response = yield call(fetchPreferenceList);
    yield put(getPreferenceFromDataSuccess(response.data));
  } catch (error) {
    yield put(getPreferenceFromDataFailure(error.message));
  }
}
export function* watchPrefernceFromData() {
  yield takeLatest(preferenceFromDataRequest.type, getPreferenceFromData);
}