import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { verifyBookingRequest, verifyBookingFailure, verifyBookingSuccess, reviewinformationfailure, reviewinformationrequest, reviewinformationsuccess, upgradeSeatMilesRequest, getUpgradeSeatMilesSuccess, getUpgradeSeatMilesFailure } from "../slice/flightupgradeinformation";
const accessToken = localStorage.getItem("accesstoken");
const headerData = {
  mode: "no-cors",
  headers: {
    Authorization: accessToken,
    "Content-Type": "application/json"
  },
  loader: true
  // isGenericErr: true,
};

//Verify Booking
const verifyBooking = async payload => {
  const POST_API_URL = config.VERIFY_BOOKING;
  let payloadData = {
    pnr: payload?.pnr
  };
  const response = await api.post(POST_API_URL, payloadData, headerData);
  return response;
};
function* fetchVerifyBooking(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(verifyBooking, payload);
    yield put(verifyBookingSuccess(response?.data));
  } catch (error) {
    yield put(verifyBookingFailure(error));
  }
}

//Get Miles details
const getUpgradeSeatMiles = async payload => {
  const POST_API_URL = config.UPGRADE_FLIGHT;
  let payloadData = {
    pnr: payload?.pnr,
    paxInfo: payload?.paxInfo,
    flightDetails: payload?.flightDetails
  };
  const response = await api.post(POST_API_URL, payloadData, headerData);
  return response;
};
function* getUpgradeSeatMilesData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(getUpgradeSeatMiles, payload);
    yield put(getUpgradeSeatMilesSuccess(response?.data));
  } catch (error) {
    yield put(getUpgradeSeatMilesFailure(error));
  }
}

// review information functionality

const reviewinformation = async payload => {
  const DOWNLOAD_URL = config?.GIFT_MILES_DOWNLOAD_RECEIPT;
  const response = await api.post(DOWNLOAD_URL, payload);
  return response;
};
function* reviewinfo(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(reviewinformation, payload);
    yield put(reviewinformationsuccess(response?.data));
  } catch (error) {
    yield put(reviewinformationfailure(error));
  }
}
export function* watchflightupgrade() {
  yield takeEvery(verifyBookingRequest.type, fetchVerifyBooking);
}
export function* watchgetredeemmiles() {
  yield takeEvery(upgradeSeatMilesRequest.type, getUpgradeSeatMilesData);
}
export function* watchreviewinformation() {
  yield takeEvery(reviewinformationrequest.type, reviewinfo);
}