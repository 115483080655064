import { call, put, takeLatest } from "redux-saga/effects";
import { getPromoCodeDataSuccess, getPromoCodeRequest, getPromoCodetDataFailure } from "../slice/promoCode-Slice";
import api from "../../../config/api";
import moment from "moment";
function resetTime(date) {
  return new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
}
const getBetweenDates = (start, end) => {
  let dates = [];
  let currentDates = start?.clone();
  while (currentDates?.isSameOrBefore(end)) {
    dates?.push(currentDates?.format("YYYY-MM-DD"));
    currentDates?.add(1, "day");
  }
  return dates;
};
function* fetcPromoCodeSaga(action) {
  try {
    const response = yield call(api.get, action.payload, {
      method: "GET"
    });
    const dd = [];
    const period = response?.data?.data?.saleBlackoutDates;
    const limit = response?.data?.data?.usageLimit;
    period?.forEach(data => {
      const fdf = getBetweenDates(moment(data?.start), moment(data?.expire));
      dd.push(...fdf);
    });
    const dates = [...new Set(dd)];
    const target = new Date()?.toISOString()?.split("T")[0];
    const start = response?.data?.data?.salePeriod?.start?.split(" ")[0];
    const end = response?.data?.data?.salePeriod?.expire?.split(" ")[0];
    const targetDate = new Date();
    const isBetween = resetTime(targetDate) >= resetTime(new Date(start)) && resetTime(targetDate) <= resetTime(new Date(end));
    if (dates?.includes(target)) {
      throw new Error("sale-not");
    } else if (!isBetween || limit === 0) {
      throw new Error("promo-expired");
    }
    yield put(getPromoCodeDataSuccess(response?.data));
  } catch (error) {
    yield put(getPromoCodetDataFailure(error?.response?.data?.description || error?.message));
  }
}
export function* watchFetchPromoCodeSaga() {
  yield takeLatest(getPromoCodeRequest.type, fetcPromoCodeSaga);
}