import { call, put, takeLatest } from "redux-saga/effects";
import { seatMapRequest, seatMapSuccess, seatMapFailure } from "../../slice/seatMap/seatMapSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchSeatMapData = async seatMapCheckIn => {
  const SEAT_MAP_API_URL = config.SEAT_MAP_CHECK_IN_API;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(`${SEAT_MAP_API_URL}`, seatMapCheckIn, data);
  return response;
};
function* getSeatMapData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchSeatMapData, payload);
    yield put(seatMapSuccess(response.data));
  } catch (error) {
    yield put(seatMapFailure(error));
  }
}
export function* watchSeatMapData() {
  yield takeLatest(seatMapRequest.type, getSeatMapData);
}