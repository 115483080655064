import { createSlice } from "@reduxjs/toolkit";
// const initialState: SawasdeeDataState = {
//   SawasdeeMagDetails: [],
//   isLoading: false,
//   error: "",
//   hasData: false,
// };

const initialState = {
  isLoading: false,
  hasData: false,
  isError: false,
  error: "",
  sawasdeeFeedsList: []
};
const sawasdeeMagSlice = createSlice({
  name: "sawasdeeMagzine",
  initialState,
  reducers: {
    swMagDataRequest: state => {
      state.isLoading = true;
    },
    getSWDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.sawasdeeFeedsList = action.payload;
    },
    getSWDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  swMagDataRequest,
  getSWDataSuccess,
  getSWDataFailure
} = sawasdeeMagSlice.actions;
export default sawasdeeMagSlice.reducer;