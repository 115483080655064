import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  myLifeStyleSubmitData: {},
  isLoading: false,
  error: "",
  hasData: false,
  foodError: "",
  foodData: "",
  hobbiesData: [],
  hobbiesError: "",
  magazineDate: [],
  magazineError: "",
  sportsData: [],
  sportsError: "",
  newspapersData: [],
  newspapersError: "",
  travelData: [],
  travelError: "",
  mealsData: [],
  mealsError: "",
  termsandconditionsprofilecomponentsData: [],
  termsandconditionsprofilecomponentsError: '',
  bookflightservicesData: [],
  bookflightservicesError: '',
  redemptionpartnercardData: [],
  redemptionpartnercardError: '',
  tncreviewinfoData: [],
  tncreviewinfoError: '',
  preSelectMealsData: [],
  preSelectMealsError: '',
  mythaiCrmData: [],
  mythaiCrmError: "",
  loungegoldtrackData: [],
  loungegoldtrackError: ''
};
const fetchLifeStyleSlice = createSlice({
  name: "MyLifeStyleDeails",
  initialState,
  reducers: {
    fetchLifeStyleRequest: state => {
      state.isLoading = true;
    },
    getLifeStyleSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myLifeStyleSubmitData = action.payload;
    },
    getLifeStyleFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.myLifeStyleSubmitData = {};
    },
    getFoodRequest: state => {
      state.isLoading = true;
    },
    getFoodSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.foodData = action.payload;
      state.foodError = "";
    },
    getFoodFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.foodError = action.payload;
    },
    getHobbiesAndInterestsRequest: state => {
      state.isLoading = true;
    },
    getHobbiesAndInterestsSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.hobbiesData = action.payload;
      state.hobbiesError = "";
    },
    getHobbiesAndInterestsFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.hobbiesError = action.payload;
    },
    getMagazineRequest: state => {
      state.isLoading = true;
    },
    getMagazineSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.magazineDate = action.payload;
      state.magazineError = "";
    },
    getMagazineFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.magazineError = action.payload;
    },
    getSportsRequest: state => {
      state.isLoading = true;
    },
    getSportsSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.sportsData = action.payload;
      state.sportsError = "";
    },
    getSportsFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.sportsError = action.payload;
    },
    getNewspaperRequest: state => {
      state.isLoading = true;
    },
    getNewspaperSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.newspapersData = action.payload;
      state.newspapersError = "";
    },
    getNewspaperFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.newspapersError = action.payload;
    },
    getTravelRequest: state => {
      state.isLoading = true;
    },
    getTravelSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.travelData = action.payload;
      state.travelError = "";
    },
    getTravelFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.travelError = action.payload;
    },
    getMealsRequest: state => {
      state.isLoading = true;
    },
    getMealsSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.mealsData = action.payload;
      state.mealsError = "";
    },
    getMealsFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.mealsError = action.payload;
    },
    getTermsandconditionsprofilecomponentsRequest: state => {
      state.isLoading = true;
    },
    getTermsandconditionsprofilecomponentsSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsandconditionsprofilecomponentsData = action.payload;
      state.termsandconditionsprofilecomponentsError = "";
    },
    getTermsandconditionsprofilecomponentsFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsandconditionsprofilecomponentsError = action.payload;
    },
    getBookflightservicesRequest: state => {
      state.isLoading = true;
    },
    getBookflightservicesSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.bookflightservicesData = action.payload;
      state.bookflightservicesError = "";
    },
    getBookflightservicesFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.bookflightservicesError = action.payload;
    },
    getRedemptionpartnercardRequest: state => {
      state.isLoading = true;
    },
    getRedemptionpartnercardSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.redemptionpartnercardData = action.payload;
      state.redemptionpartnercardError = "";
    },
    getRedemptionpartnercardFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.redemptionpartnercardError = action.payload;
    },
    getTncreviewinfoRequest: state => {
      state.isLoading = true;
    },
    getTncreviewinfoSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.tncreviewinfoData = action.payload;
      state.tncreviewinfoError = "";
    },
    getTncreviewinfoFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.tncreviewinfoError = action.payload;
    },
    getPreSelectMealsRequest: state => {
      state.isLoading = true;
    },
    getPreSelectMealsSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.preSelectMealsData = action.payload;
      state.preSelectMealsError = "";
    },
    getPreSelectMealsFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.preSelectMealsError = action.payload;
    },
    getmyThaiCRMRequest: state => {
      state.isLoading = true;
    },
    getmyThaiCRMSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.mythaiCrmData = action.payload;
      state.mythaiCrmError = "";
    },
    getmyThaiCRMFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.mythaiCrmError = action.payload;
    },
    getloungegoldtrackRequest: state => {
      state.isLoading = true;
    },
    getloungegoldtrackSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.loungegoldtrackData = action.payload;
      state.loungegoldtrackError = "";
    },
    getloungegoldtrackFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.loungegoldtrackError = action.payload;
    }
  }
});
export const {
  fetchLifeStyleRequest,
  getLifeStyleSuccess,
  getLifeStyleFailure,
  getFoodRequest,
  getFoodSuccess,
  getFoodFailure,
  getHobbiesAndInterestsRequest,
  getHobbiesAndInterestsSuccess,
  getHobbiesAndInterestsFailure,
  getMagazineRequest,
  getMagazineSuccess,
  getMagazineFailure,
  getSportsRequest,
  getSportsSuccess,
  getSportsFailure,
  getNewspaperRequest,
  getNewspaperSuccess,
  getNewspaperFailure,
  getTravelRequest,
  getTravelSuccess,
  getTravelFailure,
  getMealsRequest,
  getMealsSuccess,
  getMealsFailure,
  getTermsandconditionsprofilecomponentsRequest,
  getTermsandconditionsprofilecomponentsSuccess,
  getTermsandconditionsprofilecomponentsFailure,
  getBookflightservicesRequest,
  getBookflightservicesSuccess,
  getBookflightservicesFailure,
  getRedemptionpartnercardRequest,
  getRedemptionpartnercardSuccess,
  getRedemptionpartnercardFailure,
  getTncreviewinfoRequest,
  getTncreviewinfoSuccess,
  getTncreviewinfoFailure,
  getPreSelectMealsRequest,
  getPreSelectMealsSuccess,
  getPreSelectMealsFailure,
  getmyThaiCRMRequest,
  getmyThaiCRMSuccess,
  getmyThaiCRMFailure,
  getloungegoldtrackRequest,
  getloungegoldtrackSuccess,
  getloungegoldtrackFailure
} = fetchLifeStyleSlice.actions;
export default fetchLifeStyleSlice.reducer;