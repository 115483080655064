import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { getProfileLifeStyleInfoPending, getProfileLifeStyleInfoSuccess, getProfileLifeStyleInfoFailure } from "../slice/profileLifeStyleSlice";
const PROFILE_LIFESTYLE_INFO_API_URL = config.FETCH_MY_LIFESTYLE;
const fetchProfileLifeStyleInfoData = async ProfileLifeStyleInfoPayload => {
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    isGenericErr: true,
    loader: true
  };
  let profileLifeStyleInfoPayloadData = {
    maritalStatus: ProfileLifeStyleInfoPayload.maritalStatus,
    children: ProfileLifeStyleInfoPayload.children,
    education: ProfileLifeStyleInfoPayload.education,
    occupation: ProfileLifeStyleInfoPayload.occupation,
    income: ProfileLifeStyleInfoPayload.income,
    lifestyleCodeList: ProfileLifeStyleInfoPayload.lifestyleCodeList
  };
  const response = await api.post(PROFILE_LIFESTYLE_INFO_API_URL, profileLifeStyleInfoPayloadData, headerData);
  // if (response.status == 200) {
  //   sessionStorage.setItem("password", profileLifeStyleInfoPayloadData.pinNumber);
  //   console.log("fetchProfileContactInfoData_rESP",response.status)
  // }

  return response;
};
function* submitProfileLifeStyleInfoData(action) {
  try {
    const response = yield call(fetchProfileLifeStyleInfoData, action.payload);
    yield put(getProfileLifeStyleInfoSuccess(response.data));
  } catch (error) {
    yield put(getProfileLifeStyleInfoFailure(error));
  }
}
export function* watchProfileLifeStyleInfoData() {
  yield takeLatest(getProfileLifeStyleInfoPending.type, submitProfileLifeStyleInfoData);
}