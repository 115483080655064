import { call, put, takeEvery } from "redux-saga/effects";
import { airAwardReviewPnrRMRequest, airAwardReviewPnrRMSuccess, airAwardReviewPnrRMFailure } from "../slice/airAwardReviewPnrRMSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config?.AIR_AWARD_REVIEW_PNR_RM_API;
const fetchAirAwardReviewPnrRMDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(API_URL, payload, headerData);
  return response;
};
function* fetchAirAwardReviewPnrRM(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardReviewPnrRMDetail, payload);
    yield put(airAwardReviewPnrRMSuccess(response));
  } catch (error) {
    yield put(airAwardReviewPnrRMFailure(error?.response));
  }
}
export function* watchAirAwardReviewPnrRM() {
  yield takeEvery(airAwardReviewPnrRMRequest.type, fetchAirAwardReviewPnrRM);
}