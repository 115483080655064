import { put, takeLatest } from "redux-saga/effects";
import { getPopularFareDataFailure, getPopularFareDataSuccess, popularFareDataRequest } from "../slice/popularFareSlice";
import { config } from "../../../config/global";
import api from "../../../config/api";
const URL = config.POPULAR_FARE;
function* getPopularFareData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield api.post(URL, payload);
    yield put(getPopularFareDataSuccess(response.data));
  } catch (error) {
    yield put(getPopularFareDataFailure(error.message));
  }
}
export function* watchPopularFareSaga() {
  yield takeLatest(popularFareDataRequest, getPopularFareData);
}