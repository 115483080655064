import { createSlice } from "@reduxjs/toolkit";
import { genderNameValidation } from "../../../utils/helper";
const initialState = {
  crmMemberprofileData: {
    firstName: "",
    middleName: "",
    lastName: "",
    salutation: ""
  },
  isLoading: false,
  error: "",
  hasData: false,
  crmCountryCodeData: [],
  crmnewsletterPreferenceData: [],
  crmresidenceData: [],
  termsandconditionscrmData: "",
  termsandconditionscrmError: '',
  termsandconditionsprofilecomponentsData: "",
  termsandconditionsprofilecomponentsError: ""
};
const fetchCrmProfileSlice = createSlice({
  name: "CrmProfileDeails",
  initialState,
  reducers: {
    fetchCrmMemberProfileRequest: state => {
      state.isLoading = true;
    },
    getCrmProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmMemberprofileData = action.payload;
      const fullName = action.payload?.salutation + " " + action.payload?.firstName + " " + action.payload?.lastName;
      const validateFullName = genderNameValidation(fullName);
      sessionStorage.setItem("FullName", validateFullName);
      sessionStorage.setItem("FirstName", action?.payload?.firstName);
      sessionStorage.setItem("LastName", action?.payload?.lastName);
      sessionStorage.setItem("Salutation", action?.payload?.salutation);
      sessionStorage.setItem("profileName", action?.payload?.firstName);
      sessionStorage.setItem("customerID", action?.payload?.customerId);
    },
    getCrmProfileFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmMemberprofileData = {};
    },
    fetchCRMCountryCodeRequest: state => {
      state.isLoading = true;
    },
    getCRMCountryCodeSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmCountryCodeData = action.payload;
    },
    getCRMCountryCodeFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmCountryCodeData = [];
    },
    fetchCRMnewsletterPreferenceRequest: state => {
      state.isLoading = true;
    },
    getCRMnewsletterPreferenceSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      let preference = [];
      preference = action.payload.map(item => ({
        city: item.city,
        country: item.city + " " + "-" + " " + item.country,
        code: item.code,
        airportCityCode: item.airportCityCode
      }));
      state.crmnewsletterPreferenceData = preference;
    },
    getCRMnewsletterPreferenceFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmnewsletterPreferenceData = [];
    },
    fetchCRMresidenceRequest: state => {
      state.isLoading = true;
    },
    getCRMresidenceSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmresidenceData = action.payload;
    },
    getCRMresidenceFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmresidenceData = [];
    },
    getTermsandconditionscrmRequest: state => {
      state.isLoading = true;
    },
    getTermsandconditionscrmSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsandconditionscrmData = action.payload;
      state.termsandconditionscrmError = "";
    },
    getTermsandconditionscrmFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsandconditionscrmError = action.payload;
    },
    getTermsandconditionsprofilecomponentsRequest: state => {
      state.isLoading = true;
    },
    getTermsandconditionsprofilecomponentsSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.termsandconditionsprofilecomponentsData = action.payload;
      state.termsandconditionsprofilecomponentsError = "";
    },
    getTermsandconditionsprofilecomponentsFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.termsandconditionsprofilecomponentsError = action.payload;
    }
  }
});
export const {
  fetchCrmMemberProfileRequest,
  getCrmProfileSuccess,
  getCrmProfileFailure,
  fetchCRMCountryCodeRequest,
  getCRMCountryCodeSuccess,
  getCRMCountryCodeFailure,
  fetchCRMnewsletterPreferenceRequest,
  getCRMnewsletterPreferenceSuccess,
  getCRMnewsletterPreferenceFailure,
  fetchCRMresidenceRequest,
  getCRMresidenceSuccess,
  getCRMresidenceFailure,
  getTermsandconditionscrmRequest,
  getTermsandconditionscrmSuccess,
  getTermsandconditionscrmFailure,
  getTermsandconditionsprofilecomponentsRequest,
  getTermsandconditionsprofilecomponentsSuccess,
  getTermsandconditionsprofilecomponentsFailure
} = fetchCrmProfileSlice.actions;
export default fetchCrmProfileSlice.reducer;