import { call, put, takeLatest } from "redux-saga/effects";
import { advertisementDataRequest, getAdvertisementDataSuccess, getAdvertisementDataFailure } from "../slice/advertisementSlice";
// import api from "../config/api";
import axios from "axios";
const API_URL = "./osci/json/advertisementData.json";
function* getAdvertisementData() {
  try {
    const response = yield call(axios.get, API_URL, {
      baseURL: "/"
    });
    yield put(getAdvertisementDataSuccess(response.data));
  } catch (error) {
    yield put(getAdvertisementDataFailure(error.message));
  }
}
export function* watchAdvertisementData() {
  yield takeLatest(advertisementDataRequest.type, getAdvertisementData);
}