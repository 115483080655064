import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../../src/config/global";
import { getTokenExpiredDataPending, getTokenExpiredDataSuccess, getTokenExpiredDataFailure } from "../slice/tokenExpiredSlice";
const Token_Expired = config?.TOKEN_EXPIRED;
const fetchLoginData = async () => {
  let data = {
    mode: "no-cors",
    headers: {
      "Authorization": "accesstoken",
      "source": "website"
    },
    loader: true
  };
  const response = await api.get(Token_Expired, data);
  sessionStorage.setItem("temptoken", response.headers.accesstoken);
  return response;
};
function* getLoginData(action) {
  try {
    const response = yield call(api.get, Token_Expired);
    const refreshTokenValues = {
      ...response?.headers
    };
    yield put(getTokenExpiredDataSuccess(refreshTokenValues));
  } catch (error) {
    yield put(getTokenExpiredDataFailure(error));
  }
}
export function* watchTokenExpiredData() {
  yield takeLatest(getTokenExpiredDataPending.type, getLoginData);
}