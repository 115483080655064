import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getCrmSignUpDataPending, getCrmSignUpSocialMediaDataPending, getCrmSignUpSuccess, getCrmSignUpocialMediaSuccess, getCrmSignupFailure } from "../slice/crmSignUpSlice";
import { config } from "../../../config/global";
const CRM_SIGNUP_API_URL = config?.CRM_ENROLL;
const CRM_SIGNUP_SOCIAL_MEDIA_URL = config?.SOCIAL_MEDIA_SIGN_UP;
const fetchCrmSignupData = async crmPayload => {
  let CrmSignupPayloadData = {
    title: crmPayload?.title,
    firstName: crmPayload?.firstName,
    lastName: crmPayload?.lastName,
    dateofbirth: crmPayload?.dob,
    email: crmPayload?.email,
    mobile: crmPayload?.contactNumber,
    countryCode: crmPayload?.countryCode,
    parentDateofbirth: "1000-01-01",
    consent: crmPayload?.consent,
    subscription: crmPayload?.thaiPromoConsent,
    password: crmPayload?.password,
    configId: 13,
    otpRefKey: crmPayload?.otpRefKey,
    otp: crmPayload?.otp
    //thirdPartySubscription: crmPayload?.thaiThirdPartyPromoconsent
  };
  CrmSignupPayloadData = crmPayload?.consent ? {
    ...CrmSignupPayloadData,
    parentFirstName: crmPayload?.parentFirstName,
    parentLastName: crmPayload?.parentLastName,
    parentDateofbirth: crmPayload?.parentDateofbirth,
    parentEmail: crmPayload?.parentEmail,
    parentMobileNumber: crmPayload?.parentMobileNumber
  } : CrmSignupPayloadData;
  CrmSignupPayloadData = crmPayload?.thaiPromoConsent ? {
    ...CrmSignupPayloadData,
    countryOfResidence: crmPayload?.countryOfResidence,
    preferredNews: crmPayload?.preferredNews,
    airportCode: crmPayload?.airportCode
  } : CrmSignupPayloadData;
  CrmSignupPayloadData = crmPayload?.thaiThirdPartyPromoconsent ? {
    ...CrmSignupPayloadData,
    subscriptionPartnersThai: crmPayload?.thaiThirdPartyPromoconsent
  } : CrmSignupPayloadData;
  const response = await api?.post(CRM_SIGNUP_API_URL, CrmSignupPayloadData);
  return response;
};
function* getCrmSignupData(action) {
  try {
    const response = yield call(fetchCrmSignupData, action.payload);
    yield put(getCrmSignUpSuccess(response.data));
  } catch (error) {
    yield put(getCrmSignupFailure(error));
  }
}
const fetchCrmSignupSocialMediaData = async crmPayload => {
  let authorization = localStorage.getItem("accesstoken");
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: authorization
    },
    loader: true
  };
  let CrmSignUpSocialMediaPayloadData = {
    title: crmPayload?.title,
    firstName: crmPayload?.firstName,
    lastName: crmPayload?.lastName,
    dateofbirth: crmPayload?.dob,
    email: crmPayload?.email,
    mobile: crmPayload?.contactNumber,
    subscription: crmPayload?.thaiPromoConsent,
    parentDateofbirth: "1000-01-01",
    clientId: crmPayload?.clientId,
    socialMediaType: crmPayload?.socialMediaType
    // consent: crmPayload?.consent,

    //thirdPartySubscription: crmPayload?.thaiThirdPartyPromoconsent
  };
  CrmSignUpSocialMediaPayloadData = crmPayload?.consent ? {
    ...CrmSignUpSocialMediaPayloadData,
    parentFirstName: crmPayload?.parentFirstName,
    parentLastName: crmPayload?.parentLastName
    // parentEmail:crmPayload?.parentEmail,
    // parentMobileNumber:crmPayload?.parentMobileNumber
  } : CrmSignUpSocialMediaPayloadData;
  CrmSignUpSocialMediaPayloadData = crmPayload?.thaiPromoConsent ? {
    ...CrmSignUpSocialMediaPayloadData,
    // countryOfResidence: crmPayload?.countryOfResidence,
    preferredNews: crmPayload?.preferredNews,
    airportCode: crmPayload?.airportCode
  } : CrmSignUpSocialMediaPayloadData;
  // CrmSignUpSocialMediaPayloadData = crmPayload?.thaiThirdPartyPromoconsent
  //   ? {
  //       ...CrmSignUpSocialMediaPayloadData,
  //       subscriptionPartnersThai: crmPayload?.thaiThirdPartyPromoconsent,
  //     }
  //   : CrmSignUpSocialMediaPayloadData;

  const response = await api?.post(CRM_SIGNUP_SOCIAL_MEDIA_URL, CrmSignUpSocialMediaPayloadData, data);
  response.data.email = crmPayload?.email;
  return response;
};
function* getCrmSignupSocialMediaData(action) {
  try {
    const response = yield call(fetchCrmSignupSocialMediaData, action.payload);
    yield put(getCrmSignUpocialMediaSuccess(response.data));
  } catch (error) {
    yield put(getCrmSignUpocialMediaSuccess(error));
  }
}
export function* watchCrmSignupSocialMediaData() {
  yield takeLatest(getCrmSignUpSocialMediaDataPending.type, getCrmSignupSocialMediaData);
}
export function* watchCrmSignupData() {
  yield takeLatest(getCrmSignUpDataPending.type, getCrmSignupData);
}