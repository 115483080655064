import { call, put, takeLatest } from "redux-saga/effects";
import { retrieveAutoSeatRequest, retrieveAutoSeatSuccess, retrieveAutoSeatFailure } from "../../slice/checkin/autoSeatSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchAutoSeatData = async autoSeatRequest => {
  const AUTO_SEAT_SELECTION_API_URL = config.CHECKIN_AUTO_SEAT_SELECTION;
  const data = {
    mode: "no-cors",
    headers: {
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json"
    },
    loader: true
  };
  const response = await api.post(`${AUTO_SEAT_SELECTION_API_URL}`, autoSeatRequest, data);
  return response;
};
function* getAutoSelectionData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAutoSeatData, payload);
    yield put(retrieveAutoSeatSuccess(response.data));
  } catch (error) {
    yield put(retrieveAutoSeatFailure(error));
  }
}
export function* watchAutoSeatSelectionData() {
  yield takeLatest(retrieveAutoSeatRequest.type, getAutoSelectionData);
}