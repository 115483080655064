import { call, put, takeLatest } from "redux-saga/effects";
import { fetchProfileRequest, getProfileSuccess, getProfileFailure, fetchCRMProfileRequest, getCRMProfileSuccess, getCRMProfileFailure, fetchCountryRequest, getCountrySuccess, getCountryFailure, fetchCityRequest, getCitySuccess, getCityFailure, fetchStateRequest, getStateSuccess, getStateFailure, fetchCurrencyRequest, getCurrencySuccess, getCurrencyFailure } from "../../slice/fetchProfileSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const data = {
  mode: "no-cors",
  headers: {
    // memberId: sessionStorage.getItem("memberId"),
    Authorization: localStorage.getItem("accesstoken"),
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true,
  isGenericErr: true
};
const fetchProfileData = async () => {
  const FETCH_PROFILE_API_URL = config.FETCH_PROFILE;
  const response = await api.get(FETCH_PROFILE_API_URL, data);
  sessionStorage.setItem("privilegeCard", response.data.privilegeCard);
  return response;
};
function* getProfileData() {
  try {
    const response = yield call(fetchProfileData);
    yield put(getProfileSuccess(response.data));
  } catch (error) {
    yield put(getProfileFailure(error.message));
  }
}
const fetchCRMProfileData = async () => {
  const FETCH_CRM_PROFILE_API_URL = config.CRM_FETCH_PROFILE;
  const response = await api.get(FETCH_CRM_PROFILE_API_URL, data);
  return response;
};
function* getCRMProfileData() {
  try {
    const response = yield call(fetchCRMProfileData);
    yield put(getCRMProfileSuccess(response.data));
  } catch (error) {
    yield put(getCRMProfileFailure(error.message));
  }
}
const fetchCountryData = async () => {
  const FETCH_COUNTRIES = config?.COUNTRY_DATA;
  const response = await api?.get(FETCH_COUNTRIES, data);
  return response;
};
function* getCountryData() {
  try {
    const response = yield call(fetchCountryData);
    yield put(getCountrySuccess(response.data));
  } catch (error) {
    yield put(getCountryFailure(error.message));
  }
}
const fetchCityData = async () => {
  const FETCH_CITIES = config.CITY_DATA;
  const response = await api.get(FETCH_CITIES, data);
  return response;
};
function* getCityData() {
  try {
    const response = yield call(fetchCityData);
    yield put(getCitySuccess(response.data));
  } catch (error) {
    yield put(getCityFailure(error.message));
  }
}
const fetchStateData = async () => {
  const FETCH_STATES = config.STATE_DATA;
  const response = await api.get(FETCH_STATES, data);
  return response;
};
function* getStateData() {
  try {
    const response = yield call(fetchStateData);
    yield put(getStateSuccess(response.data));
  } catch (error) {
    yield put(getStateFailure(error.message));
  }
}
const fetchCurrencyData = async () => {
  const FETCH_CURRENCY = config.CURRENCY_DATA;
  const response = await api.get(FETCH_CURRENCY, data);
  return response;
};
function* getCurrencyData() {
  try {
    const response = yield call(fetchCurrencyData);
    yield put(getCurrencySuccess(response.data));
  } catch (error) {
    yield put(getCurrencyFailure(error.message));
  }
}
export function* watchProfileData() {
  yield takeLatest(fetchProfileRequest.type, getProfileData);
}
export function* watchCRMProfileData() {
  yield takeLatest(fetchCRMProfileRequest.type, getCRMProfileData);
}
export function* watchCountry() {
  yield takeLatest(fetchCountryRequest.type, getCountryData);
}
export function* watchCity() {
  yield takeLatest(fetchCityRequest.type, getCityData);
}
export function* watchState() {
  yield takeLatest(fetchStateRequest.type, getStateData);
}
export function* watchCurrency() {
  yield takeLatest(fetchCurrencyRequest.type, getCurrencyData);
}