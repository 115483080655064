import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  metaTagsDetails: {},
  isLoading: false,
  error: "",
  hasData: false
};
const metaTagsSlice = createSlice({
  name: "metaTags",
  initialState,
  reducers: {
    metaTagsDataRequest: state => {
      state.isLoading = true;
    },
    getMetaTagsDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.metaTagsDetails = action.payload;
    },
    getMetaTagsDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  metaTagsDataRequest,
  getMetaTagsDataSuccess,
  getMetaTagsDataFailure
} = metaTagsSlice.actions;
export default metaTagsSlice.reducer;