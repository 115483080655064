import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  boardingPassDetails: null,
  isLoading: false,
  error: "",
  boardingPassID: '',
  GooglePassWalletSuccess: null,
  GooglePassWalleError: null,
  isGoogleSuccess: false,
  ApplePassWalletSuccess: null,
  ApplePassWalleError: null,
  isAppleSuccess: false
};
const fetchBoardingPassSlice = createSlice({
  name: "boardingPass",
  initialState,
  reducers: {
    boardingPassDataRequest: (state, action) => {
      state.isLoading = true;
      state.boardingPassID = action.payload[0]; // saving boardingPassID for add to wallet.
    },
    getBoardingPassDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.boardingPassDetails = action.payload;
    },
    getBoardingPassDataFailure: (state, action) => {
      state.isLoading = false;
      state.boardingPassDetails = [];
      state.error = action.payload;
    },
    clearBoardingPassDetails: state => {
      state.boardingPassDetails = null;
      state.isLoading = false;
      state.error = "";
    },
    addToGooglePassWalletRequest: (state, action) => {
      state.isLoading = true;
    },
    getAddToGooglePassWalleSuccess: (state, action) => {
      state.isLoading = false;
      state.GooglePassWalleError = "";
      state.isGoogleSuccess = true;
      state.GooglePassWalletSuccess = action.payload;
    },
    getAddToGooglePassWalleFailure: (state, action) => {
      state.isLoading = false;
      state.GooglePassWalletSuccess = {};
      state.isGoogleSuccess = false;
      state.GooglePassWalleError = action.payload;
    },
    clearAddToGooglePassWallet: state => {
      state.GooglePassWalletSuccess = null;
      state.isLoading = false;
      state.GooglePassWalleError = "";
      state.isGoogleSuccess = false;
    },
    addToAppleWalletRequest: (state, action) => {
      state.isLoading = true;
    },
    getAddToAppleWalleSuccess: (state, action) => {
      state.isLoading = false;
      state.ApplePassWalleError = "";
      state.isAppleSuccess = true;
      state.ApplePassWalletSuccess = action.payload;
    },
    getAddToAppleWalleFailure: (state, action) => {
      state.isLoading = false;
      state.ApplePassWalletSuccess = {};
      state.isAppleSuccess = false;
      state.ApplePassWalleError = action.payload;
    },
    clearAddToAppleWallet: state => {
      state.ApplePassWalletSuccess = null;
      state.isLoading = false;
      state.ApplePassWalleError = "";
      state.isAppleSuccess = false;
    }
  }
});
export const {
  boardingPassDataRequest,
  getBoardingPassDataSuccess,
  getBoardingPassDataFailure,
  clearBoardingPassDetails,
  addToGooglePassWalletRequest,
  getAddToGooglePassWalleSuccess,
  getAddToGooglePassWalleFailure,
  addToAppleWalletRequest,
  getAddToAppleWalleSuccess,
  getAddToAppleWalleFailure,
  clearAddToGooglePassWallet,
  clearAddToAppleWallet
} = fetchBoardingPassSlice.actions;
export default fetchBoardingPassSlice.reducer;