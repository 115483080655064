import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  eReceiptBlob: "",
  eReceiptUrl: "",
  error: "",
  profileInfo: {},
  limitDetails: {},
  isUpdateSponsorSuccess: false,
  termsData: {}
};
const eReceiptSlice = createSlice({
  name: "eReceipt",
  initialState,
  reducers: {
    resetEreceiptDataRequest: state => {
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = "";
      state.profileInfo = {};
      state.limitDetails = {};
    },
    getEReceiptDataRequest: (state, action) => {
      state.isLoading = true;
      state.error = "";
    },
    getEReceiptDataSuccess: (state, action) => {
      state.isLoading = false;
      state.eReceiptBlob = action.payload.blobUrl;
      state.eReceiptUrl = action.payload.actualUrl;
      state.profileInfo = action.payload.profileInfo;
      state.limitDetails = action.payload.limitDetails;
      state.error = "";
    },
    getEReceiptDataFailure: (state, action) => {
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = action.payload;
    },
    updateEReceiptDataRequest: (state, action) => {
      state.isLoading = true;
    },
    updateEReceiptDataSuccess: (state, action) => {
      state.isLoading = false;
      state.isUpdateSponsorSuccess = true;
      state.error = "";
    },
    updateEReceiptDataFailure: (state, action) => {
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = action.payload;
    },
    getEreceiptTermsSuccess: (state, action) => {
      state.isLoading = false;
      state.termsData = action.payload;
      state.error = "";
    },
    getEreceiptTermsFailure: (state, action) => {
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = action.payload;
    },
    getEReceipteReceiptHowToGoRequest: state => {
      state.isLoading = true;
    },
    getEReceipteReceiptHowToGoSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.ereceiptHowToGoData = action.payload;
      state.ereceiptDetailsError = "";
    },
    getEReceipteReceiptHowToGoFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.ereceiptDetailsError = action.payload;
    }
  }
});
export const {
  getEReceiptDataRequest,
  getEReceiptDataSuccess,
  getEReceiptDataFailure,
  updateEReceiptDataRequest,
  updateEReceiptDataSuccess,
  updateEReceiptDataFailure,
  resetEreceiptDataRequest,
  getEreceiptTermsSuccess,
  getEreceiptTermsFailure,
  getEReceipteReceiptHowToGoRequest,
  getEReceipteReceiptHowToGoSuccess,
  getEReceipteReceiptHowToGoFailure
} = eReceiptSlice.actions;
export default eReceiptSlice.reducer;