import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  regulatoryData: {},
  isLoading: false,
  error: "",
  hasData: false
};
const fetchRegulatorySlice = createSlice({
  name: "regulatoryJson",
  initialState,
  reducers: {
    regulatoryRequest: state => {
      state.isLoading = true;
    },
    regulatorySuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.regulatoryData = action.payload;
    },
    regulatoryFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.error = action?.payload?.response?.data;
      state.regulatoryData = {};
    }
  }
});
export const {
  regulatoryRequest,
  regulatorySuccess,
  regulatoryFailure
} = fetchRegulatorySlice.actions;
export default fetchRegulatorySlice.reducer;