import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { loungeInfoRequest, loungeInfoSuccess, loungeInfoFailure } from "../slice/loungeFinderSlice";
import i18n from "../../../i18n";
const headerData = {
  mode: "no-cors",
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": `${i18n.language}`
  },
  loader: true
};
const API_URLS = config.LOUNGE_FINDER;
const loungeinfo = async payload => {
  const response = await api.get(API_URLS + payload, headerData);
  return response;
};
function* loungeInformation(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(loungeinfo, payload);
    yield put(loungeInfoSuccess(response.data));
  } catch (error) {
    yield put(loungeInfoFailure(error.message));
  }
}
export function* watchLoungeInformationData() {
  yield takeLatest(loungeInfoRequest.type, loungeInformation);
}