import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { retrivePNRFail, retrivePNRRequest, retrivePNRSuccess } from "../slice/retrivePNRSlice";
const fetchRetrivePNR = async payload => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    },
    loader: true,
    isGenericErr: true
  };
  let response;
  if (!payload?.isStarAlliance) {
    let tgURL = config?.RETRIVE_PNR;
    if (payload?.timer) {
      tgURL = config?.RETRIVE_PNR + "?redisplay=true";
    }
    response = await api.post(tgURL, payload, headerData);
  } else {
    let starURL = config?.RETRIVE_PNR + "?starAlliance=true";
    if (payload?.timer) {
      starURL = config?.RETRIVE_PNR + "?redisplay=true&starAlliance=true";
    }
    response = await api.post(starURL, payload, headerData);
  }
  return response;
};
function* retrivePNRSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchRetrivePNR, payload);
    yield put(retrivePNRSuccess({
      ...response
    }));
  } catch (error) {
    yield put(retrivePNRFail({
      ...error?.response
    }));
  }
}
export function* watchRetrivePNRData() {
  yield takeEvery(retrivePNRRequest.type, retrivePNRSaga);
}