import { call, put, takeLatest } from "redux-saga/effects";
import { updatePassengerRequest, updatePassengerSuccess, updatePassengerFailure } from "../../slice/checkin/updatePassengerDetailsSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchUpdatePassengerData = async updatePassengers => {
  const UPDATE_PASSENGER_API_URL = config.UPDATE_PASSENGER_DETAILS;
  const data = {
    mode: "no-cors",
    headers: {
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(`${UPDATE_PASSENGER_API_URL}`, updatePassengers, data);
  return response;
};
function* getUpdatePassengerData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchUpdatePassengerData, payload);
    yield put(updatePassengerSuccess(response.data));
  } catch (error) {
    yield put(updatePassengerFailure(error));
  }
}
export function* watchUpdatePassengerData() {
  yield takeLatest(updatePassengerRequest.type, getUpdatePassengerData);
}