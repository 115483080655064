import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  excessBaggageData: [],
  excessBaggageInfoData: {},
  isLoading: false,
  hasData: false,
  error: {},
  isError: false,
  excessContentData: '',
  excessContentError: ""
};
const excessBaggageSlice = createSlice({
  name: "excessBaggage",
  initialState,
  reducers: {
    excessBaggageRequest: state => {
      state.isLoading = true;
    },
    excessBaggageSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = {};
      state.excessBaggageData = action.payload.data;
      state.isError = false;
    },
    excessBaggageFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.data;
      state.excessBaggageData = [];
      console.log(action);
      if (state.error?.status == 400 || state.error?.status == 401) {
        state.isError = true;
      }
    },
    excess_baggage_info_data: (state, action) => {
      state.excessBaggageInfoData = action.payload;
    },
    getExcessbaggageContentRequest: state => {
      state.isLoading = true;
    },
    getExcessbaggageContentSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.excessContentData = action.payload;
      state.excessContentError = "";
    },
    getExcessbaggageContentFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.excessContentError = action.payload;
    }
  }
});
export const {
  excessBaggageRequest,
  excessBaggageSuccess,
  excessBaggageFailure,
  excess_baggage_info_data,
  getExcessbaggageContentRequest,
  getExcessbaggageContentSuccess,
  getExcessbaggageContentFailure
} = excessBaggageSlice.actions;
export default excessBaggageSlice.reducer;