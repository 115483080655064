import { call, put, takeEvery } from 'redux-saga/effects';
import { getROPDataRequest, getROPDataFail, getROPDataSuccess } from '../../slice/ropSlice';
// import api from '../../config/api';

// import { PayloadAction } from '@reduxjs/toolkit';

const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  body: {}
};
const fetchROPData = async () => {
  // const ROP_URL = config.ROP_URL;
  // const response = await axios.get(ROP_URL, data);
  // return response;
};
function* fetchROPSaga() {
  try {
    const response = yield call(fetchROPData);
    yield put(getROPDataSuccess(response.data));
  } catch (error) {
    yield put(getROPDataFail(error.message));
  }
}
export function* watchFetchROPData() {
  yield takeEvery(getROPDataRequest.type, fetchROPSaga);
}