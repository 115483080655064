import { createSlice } from "@reduxjs/toolkit";
import { BookFlightAirportDropdown } from "../../../utils/enum-helper";
// const location_country = window.location.href.split("/")[3];
// const location = location_country.split("-")[1];

const getDateFormat = (date, type) => {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  const hours = String(date?.getHours()).padStart(2, "0");
  const minutes = String(date?.getMinutes()).padStart(2, "0");
  const seconds = String(date?.getSeconds()).padStart(2, "0");
  if (type) {
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};
const initialState = {
  data: null,
  airportData: null,
  fieldData: null,
  mobileModalVisible: [{
    visible: false,
    label: "from",
    airportDetails: null
  }, {
    visible: false,
    label: "to",
    airportDetails: null
  }],
  isLoading: false,
  selectedRecentSearchData: null,
  selectedRecentSearchDataMobile: null,
  dropdownOption: {},
  error: false,
  fromReset: false,
  tabIndex: []
};
const bookingWidgetSlice = createSlice({
  name: "bookingWidget",
  initialState,
  reducers: {
    airportDataRequest: state => {
      state.isLoading = true;
      state.error = null;
    },
    getAirportDataSuccess: (state, action) => {
      state.airportData = action.payload;
      state.isLoading = false;
    },
    getAirportDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateFieldData: (state, action) => {
      const {
        fieldLabel,
        newValue
      } = action.payload;
      if (state.fieldData === null) {
        state.fieldData = {
          from: "",
          to: "",
          departReturn: [],
          depart: "",
          passengers: {},
          class: "",
          promotionCode: "",
          bookingReferenceTicketNumber: "",
          bookingReferenceTicketNumberRopNumber: "",
          lastName: "",
          flightNumber: "",
          date: ""
        };
      }
      if (fieldLabel === "from") {
        state.fieldData[fieldLabel] = newValue;
      } else if (fieldLabel === "to") {
        state.fieldData[fieldLabel] = newValue;
      } else if (fieldLabel === "departReturn") {
        const formattedDates = newValue.map(date => {
          if (date === null) return null;
          return getDateFormat(date);
        });
        state.fieldData[fieldLabel] = formattedDates;
        state.fieldData["depart"] = formattedDates[0];
      } else if (fieldLabel === "depart") {
        const formattedDate = getDateFormat(newValue[0]);
        state.fieldData[fieldLabel] = formattedDate;
        if (new Date(formattedDate) < new Date(state.fieldData["departReturn"][1])) {
          state.fieldData["departReturn"][0] = formattedDate;
        } else {
          state.fieldData["departReturn"][0] = formattedDate;
          let nextDate = new Date(formattedDate);
          state.fieldData["departReturn"][1] = new Date(nextDate.setDate(nextDate.getDate() + 1));
        }
      } else if (fieldLabel === "date") {
        const formattedDate = getDateFormat(newValue, "simplicity");
        state.fieldData[fieldLabel] = formattedDate;
      } else if (fieldLabel === "passengers" || fieldLabel === "class" || fieldLabel === "promotionCode") {
        state.fieldData[fieldLabel] = newValue;
      } else {
        state.fieldData[fieldLabel] = newValue?.target?.value;
      }
    },
    resetFieldData: state => {
      state.fieldData = {
        from: "",
        to: "",
        departReturn: [],
        depart: "",
        passengers: {},
        class: "",
        promotionCode: "",
        bookingReferenceTicketNumber: "",
        bookingReferenceTicketNumberRopNumber: "",
        lastName: "",
        flightNumber: "",
        date: ""
      };
    },
    resetBookFlightLabel: (state, action) => {
      const {
        fieldLabel
      } = action.payload;
      if (fieldLabel === "passengers") {
        state.fieldData[fieldLabel] = {};
      } else {
        state.fieldData[fieldLabel] = "";
      }
    },
    updateDropdownOptions: (state, action) => {
      const {
        fieldLabel,
        newValue
      } = action.payload;
      if (newValue.length !== 0) {
        if (fieldLabel.toLowerCase() === BookFlightAirportDropdown.From) {
          state.dropdownOption[BookFlightAirportDropdown.To] = ["BKK"];
        } else {
          state.dropdownOption[BookFlightAirportDropdown.From] = ["AKK"];
        }
      }
    },
    displayMobileModal: (state, action) => {
      const {
        newValue,
        label
      } = action.payload;
      const index = state.mobileModalVisible.findIndex(item => item.label === label);
      if (index !== -1) {
        state.mobileModalVisible[index] = {
          ...state.mobileModalVisible[index],
          ...newValue
        };
      }
    },
    updateModalAirportData: (state, action) => {
      const {
        newValue,
        label
      } = action.payload;
      const index = state.mobileModalVisible.findIndex(item => item.label === label);
      if (index !== -1) {
        state.mobileModalVisible[index] = {
          ...state.mobileModalVisible[index],
          ...newValue
        };
      }
    },
    resetModalAirportData: state => {
      state.mobileModalVisible = [{
        visible: false,
        label: "from",
        airportDetails: null
      }, {
        visible: false,
        label: "to",
        airportDetails: null
      }];
    },
    updateSelectedCardData: (state, action) => {
      const {
        cardData
      } = action.payload;
      state.selectedRecentSearchData = cardData;
      if (cardData) {
        state.resetBookFlightTab = false;
      } else {
        state.resetBookFlightTab = true;
      }
    },
    updateSelectedCardDataMobile: (state, action) => {
      const {
        cardData
      } = action.payload;
      state.selectedRecentSearchDataMobile = cardData;
      if (cardData) {
        state.resetBookFlightTab = false;
      } else {
        state.resetBookFlightTab = true;
      }
    },
    updateFromDestinationState: (state, action) => {
      const {
        value
      } = action.payload;
      state.fromReset = value;
    },
    saveAccessedActiveTab: (state, action) => {
      state.tabIndex = action.payload;
    },
    clearAccessedActiveTab: state => {
      state.tabIndex = [];
    },
    saveMulticityData: (state, action) => {
      state.multiCityData = action?.payload;
    },
    clearMulticityData: state => {
      state.multiCityData = [];
    }
  }
});
export const {
  airportDataRequest,
  getAirportDataSuccess,
  getAirportDataFailure,
  updateFieldData,
  resetFieldData,
  resetBookFlightLabel,
  updateDropdownOptions,
  displayMobileModal,
  updateModalAirportData,
  resetModalAirportData,
  updateSelectedCardData,
  updateSelectedCardDataMobile,
  updateFromDestinationState,
  saveAccessedActiveTab,
  clearAccessedActiveTab,
  saveMulticityData,
  clearMulticityData
} = bookingWidgetSlice.actions;
export default bookingWidgetSlice.reducer;