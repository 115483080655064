import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ffpOtpDetails: [],
  error: "",
  isLoading: false,
  hasData: false,
  ffpOtpStatus: {
    status: false,
    message: ""
  }
};
const ffpGenerateOtpSlice = createSlice({
  name: "ffpGenerateOtp",
  initialState,
  reducers: {
    ffpGenerateOtpRefKey: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.ffpOtpStatus.status = "pending";
    },
    ffpGenerateOtpRefKeySuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.OtpRefKeyInfo = action.payload;
      state.ffpOtpStatus.status = "success";
    },
    ffpGenerateOtpRefKeyFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      state.ffpOtpStatus.status = "failure";
      state.OtpRefKeyInfo = {};
      state.hasData = false;
    },
    clearFfpOtpRefKeyResponse: state => {
      state.ffpOtpDetails = [];
    },
    ffpSubmitOtpRef: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.ffpOtpStatus.status = "pending";
    },
    ffpSubmitOtpRefSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.submitOtpRefyKeyInfo = action.payload;
      state.ffpOtpStatus.status = "success";
      state.ffpOtpStatus.message = "FFP Data Added Successfully";
    },
    ffpSubmitOtpRefFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      state.ffpOtpStatus.status = "failure";
      state.OtpRefKeyInfo = {};
      state.hasData = false;
    },
    resetCheckinOTPData: state => {
      state.submitOtpRefyKeyInfo = {};
    },
    generateFfpResendOtp: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.ffpOtpStatus.status = "pending";
    },
    generateFfpResendOtpSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.resendOtpInfo = action.payload;
      state.ffpOtpStatus.status = "success";
    },
    generateFfpResendOtpFailure: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.response?.data;
      state.ffpOtpStatus.status = "failure";
    }
  }
});
export const {
  ffpGenerateOtpRefKey,
  ffpGenerateOtpRefKeySuccess,
  ffpGenerateOtpRefKeyFailure,
  clearFfpOtpRefKeyResponse,
  ffpSubmitOtpRef,
  ffpSubmitOtpRefSuccess,
  ffpSubmitOtpRefFailure,
  resetCheckinOTPData,
  generateFfpResendOtp,
  generateFfpResendOtpSuccess,
  generateFfpResendOtpFailure
} = ffpGenerateOtpSlice.actions;
export default ffpGenerateOtpSlice.reducer;