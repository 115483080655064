import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { airAwardConfirmationFail, airAwardConfirmationRequest, airAwardConfirmationSuccess, airAwardDownloadFailure, airAwardDownloadRequest, airAwardDownloadSuccess } from "../slice/airAwardConfirmationSlice";
const API_URL = config?.AIR_AWARD_CONFIRMATION;
const fetchAirAwardConfirmation = async payload => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    },
    loader: true,
    isGenericErr: true
  };
  let response;
  if (!payload?.isStarAlliance) {
    response = await api.post(API_URL, payload, headerData);
  } else {
    let starURL = config?.AIR_AWARD_CONFIRMATION + "?starAlliance=true";
    response = await api.post(starURL, payload, headerData);
  }
  return response;
};
function* airAwardConfirmationSaga(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardConfirmation, payload);
    yield put(airAwardConfirmationSuccess({
      ...response
    }));
  } catch (error) {
    yield put(airAwardConfirmationFail({
      ...error?.response
    }));
  }
}
export function* watchAirAwardConfirmationData() {
  yield takeEvery(airAwardConfirmationRequest.type, airAwardConfirmationSaga);
}
const fetchAirAwardDownloadCallback = async payload => {
  let headerData = {
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website"
    },
    loader: true,
    isGenericErr: true
  };
  let response;
  if (!payload?.isStarAlliance) {
    let downloadURL = config?.DOWNLOAD_AIRPORT_BOOKING_PDF + "?starAlliance=false";
    response = await api.post(downloadURL, payload, headerData);
  } else {
    let downloadURL = config?.DOWNLOAD_AIRPORT_BOOKING_PDF + "?starAlliance=true";
    response = await api.post(downloadURL, payload, headerData);
  }
  return response;
};
function* airAwardDownloadPDF(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardDownloadCallback, payload);
    yield put(airAwardDownloadSuccess(response.data));
  } catch (error) {
    yield put(airAwardDownloadFailure(error));
  }
}
export function* watchAirAwardDownloadData() {
  yield takeEvery(airAwardDownloadRequest.type, airAwardDownloadPDF);
}