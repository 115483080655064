import { call, put, takeEvery } from "redux-saga/effects";
import { airAwardPnrRequest, airAwardPnrSuccess, airAwardPnrFailure } from "../slice/airAwardPnrSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const API_URL = config?.AIR_AWARD_PNR_API;
const fetchAirAwardPnrDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  let response;
  if (!payload?.isStarAlliance) {
    response = await api.post(API_URL, payload, headerData);
  } else {
    let starURL = config?.AIR_AWARD_PNR_API + "?starAlliance=true";
    response = await api.post(starURL, payload, headerData);
  }
  return response;
};
function* fetchAirAwardPnr(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardPnrDetail, payload);
    yield put(airAwardPnrSuccess(response));
  } catch (error) {
    yield put(airAwardPnrFailure(error?.response));
  }
}
export function* watchAirAwardPnr() {
  yield takeEvery(airAwardPnrRequest.type, fetchAirAwardPnr);
}