import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  marketDeatils: [],
  isLoading: false,
  error: "",
  hasData: false
};
const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    marketListRequest: state => {
      state.isLoading = true;
      console.log("request 1");
    },
    getmarketListSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.marketDeatils = action.payload;
    },
    getmarketListFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  marketListRequest,
  getmarketListFailure,
  getmarketListSuccess
} = marketSlice.actions;
export default marketSlice.reducer;