import { createSlice } from "@reduxjs/toolkit";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";

const initialState = {
  logoutDetails: {},
  crmLogoutData: {},
  isLoading: false,
  error: false,
  codeStatus: "pending",
  navigateRop: false,
  sessionExp: false
};
function handleLogoutState(logoutDetails, codeStatus, navigate) {
  if (codeStatus === "success") {
    if (logoutDetails.code === "200" || logoutDetails?.success) {
      if (navigate) {
        sessionStorage.clear();
        localStorage?.removeItem("accesstoken");
        localStorage?.removeItem("isROPLoggedIn");
        localStorage?.removeItem("isCRMLoggedIn");
        sessionStorage.setItem("navigateRop", "true");
        window.location.reload();
      } else {
        sessionStorage.clear();
        localStorage?.removeItem("accesstoken");
        localStorage?.removeItem("isROPLoggedIn");
        localStorage?.removeItem("isCRMLoggedIn");
        window.location.reload();
      }
    }
  } else if (codeStatus === "failure") {
    sessionStorage.clear();
    localStorage?.removeItem("accesstoken");
    localStorage?.removeItem("isROPLoggedIn");
    localStorage?.removeItem("isCRMLoggedIn");
    window.location.reload();
  }
}
const LogOutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutRequest: state => {
      state.isLoading = true;
      state.error = null;
      state.codeStatus = "pending";
      state.sessionExp = true;
    },
    getLogOutSuccess: (state, action) => {
      state.logoutDetails = action.payload;
      state.isLoading = false;
      state.codeStatus = "success";
      handleLogoutState(action.payload, "success", state.navigateRop);
      window.location.href = window.location.origin;
    },
    getLogOutFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.codeStatus = "failure";
      handleLogoutState(action.payload, "failure");
    },
    crmLogoutRequest: state => {
      state.isLoading = true;
      state.error = null;
      state.codeStatus = "pending";
    },
    crmGetLogOutSuccess: (state, action) => {
      state.crmLogoutData = action.payload;
      state.isLoading = false;
      state.codeStatus = "success";
      handleLogoutState(action.payload, "success");
    },
    crmGetLogOutFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.codeStatus = "failure";
      handleLogoutState(action.payload, "failure");
    },
    setNavigateRop: (state, action) => {
      state.navigateRop = action.payload;
    },
    logoutRequestSession: state => {
      state.isLoading = true;
      state.error = null;
      state.codeStatus = "pending";
      state.sessionExp = true;
    },
    setSessionExp: state => {
      state.sessionExp = false;
    }
  }
});
export const {
  logoutRequest,
  getLogOutSuccess,
  getLogOutFailure,
  crmLogoutRequest,
  crmGetLogOutSuccess,
  crmGetLogOutFailure,
  setNavigateRop,
  logoutRequestSession,
  setSessionExp
} = LogOutSlice.actions;
export default LogOutSlice.reducer;