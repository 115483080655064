import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cancelCheckinInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  cancelCheckinStatus: {
    status: false,
    message: ""
  }
};
const cancelCheckinSlice = createSlice({
  name: "cancelCheckin",
  initialState,
  reducers: {
    cancelCheckinRequest: (state, action) => {
      state.isLoading = true;
    },
    cancelCheckinSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.cancelCheckinInfo = action.payload;
      state.cancelCheckinStatus.status = true;
      state.cancelCheckinStatus.message = action?.payload?.message;
    },
    cancelCheckinFailure: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.response?.data;
      state.cancelCheckinInfo = {};
      state.cancelCheckinStatus.status = false;
      state.cancelCheckinStatus.message = "";
    },
    resetCancelCheckin: state => {
      state.cancelCheckinInfo = {};
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.cancelCheckinStatus.status = false;
      state.cancelCheckinStatus.message = "";
    },
    resetCancelStatus: state => {
      state.cancelCheckinStatus.status = false;
    }
  }
});
export const {
  cancelCheckinRequest,
  cancelCheckinSuccess,
  cancelCheckinFailure,
  resetCancelCheckin,
  resetCancelStatus
} = cancelCheckinSlice.actions;
export default cancelCheckinSlice.reducer;