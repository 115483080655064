import { call, put, takeEvery } from "redux-saga/effects";
import { airAwardPaymentInitRequest, airAwardPaymentInitSuccess, airAwardPaymentInitFailure } from "../slice/airAwardPaymentInitSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
import i18n from "../../../i18n";
const API_URL = config?.AIR_AWARD_PAYMENT_INIT;
const fetchAirAwardPaymentInitDetail = async payload => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      "Accept-Language": `${i18n.language}`
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post(API_URL, payload, headerData);
  return response;
};
function* fetchAirAwardPaymentInit(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchAirAwardPaymentInitDetail, payload);
    yield put(airAwardPaymentInitSuccess(response));
  } catch (error) {
    yield put(airAwardPaymentInitFailure(error?.response));
  }
}
export function* watchAirAwardPaymentInit() {
  yield takeEvery(airAwardPaymentInitRequest.type, fetchAirAwardPaymentInit);
}