import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  getDataSuccess: false,
  myThaiMyReqError: {},
  myThaiMyReqData: [],
  myThaiCrmData: "",
  myThaiCrmDataError: ""
};
const myThaiMyRequestsSlice = createSlice({
  name: "myThaiMyRequests",
  initialState,
  reducers: {
    getMyThaiMyReqPending: state => {
      state.isLoading = true;
      state.getDataSuccess = false;
      state.myThaiMyReqError = {};
      state.myThaiMyReqData = [];
    },
    getMyThaiMyReqFailure: (state, action) => {
      state.isLoading = false;
      state.getDataSuccess = false;
      state.myThaiMyReqError = action.payload;
      state.myThaiMyReqData = [];
    },
    getMyThaiMyReqSuccess: (state, action) => {
      state.isLoading = false;
      state.myThaiMyReqData = action.payload;
      state.getDataSuccess = true;
      state.myThaiMyReqError = {};
    },
    getMyThaiCrmRequest: state => {
      state.isLoading = true;
    },
    getMyThaiCrmSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.myThaiCrmData = action.payload;
      state.myThaiCrmDataError = "";
    },
    getMyThaiCrmtFailure: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.myThaiCrmDataError = action.payload;
    }
  }
});
export const {
  getMyThaiMyReqPending,
  getMyThaiMyReqSuccess,
  getMyThaiMyReqFailure,
  getMyThaiCrmRequest,
  getMyThaiCrmSuccess,
  getMyThaiCrmtFailure
} = myThaiMyRequestsSlice.actions;
export default myThaiMyRequestsSlice.reducer;