import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { config } from "../../../config/global";
import { reviewTransferMilesFailure, reviewTransferMilesRequest, reviewTransferMilesSuccess, transferMilesPaymentInitRequest, transferMilesPaymentInitSuccess, transferMilesPaymentInitFailure, getTransferMilesReceiptDownloadRequest, getTransferMilesReceiptDownloadSuccess, getTransferMilesReceiptDownloadFailed } from "../slice/transferMilesSlice";
const accessToken = localStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const headerData = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: accessToken,
    memberId
  },
  loader: true
};
// payload generate functions
const fetchReviewSummary = async payload => {
  const REVIEW_SUMMARY_URL = config?.TRANSFER_MILES_REVIEW;
  let payloadData = {
    firstName: payload?.firstName,
    lastName: payload?.lastName,
    miles: payload?.transferMiles,
    currencyCode: payload?.currencyCode,
    sponseeMemberId: payload?.receipientROPId
  };
  const response = await api.post(REVIEW_SUMMARY_URL, payloadData, headerData);
  return response;
};
const fetchTransferMilesPaymentInit = async payload => {
  const url = config.TRANSFER_MILES_PAYMENT_INIT;
  let payloadData = {
    miles: payload.miles,
    currencyCode: payload.currencyCode,
    sponseeMemberId: payload.sponseeMemberId
  };
  const response = await api.post(url, payloadData, headerData);
  return response;
};
const fetchTransferMilesReceiptDownload = async payload => {
  const DOWNLOAD_URL = config.TRANSFER_MILES_DOWNLOAD_RECEIPT;
  const response = await api.post(DOWNLOAD_URL, payload, headerData);
  return response;
};
function* getReviewSummary(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchReviewSummary, payload);
    yield put(reviewTransferMilesSuccess(response?.data));
  } catch (error) {
    yield put(reviewTransferMilesFailure(error?.response?.data));
  }
}
function* getTransferMilesPaymentInit(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchTransferMilesPaymentInit, payload);
    yield put(transferMilesPaymentInitSuccess(response?.data));
  } catch (error) {
    yield put(transferMilesPaymentInitFailure(error));
  }
}
function* getTransferMilesReceiptDownload(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchTransferMilesReceiptDownload, payload);
    yield put(getTransferMilesReceiptDownloadSuccess(response?.data));
  } catch (error) {
    yield put(getTransferMilesReceiptDownloadFailed(error));
  }
}
export function* watchTransferMilesReviewSummaryData() {
  yield takeEvery(reviewTransferMilesRequest.type, getReviewSummary);
}
export function* watchTransferMilesPaymentInit() {
  yield takeEvery(transferMilesPaymentInitRequest.type, getTransferMilesPaymentInit);
}
export function* watchTransferMilesReceiptDownload() {
  yield takeEvery(getTransferMilesReceiptDownloadRequest.type, getTransferMilesReceiptDownload);
}