import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loginDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  expireTime: "",
  accesstoken: "",
  tokenExpireTime: ""
};
const tokenExpiredSlice = createSlice({
  name: "tokenExpired",
  initialState,
  reducers: {
    getTokenExpiredDataPending: state => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.expireTime = true;
      state.tokenExpireTime = true;
      state.sessionExp = false;
    },
    getTokenExpiredDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.loginDetails = action.payload;
      state.codeStatus = "success";
      localStorage.setItem("expireTime", action.payload.tokenexpiry);
      localStorage.setItem("accesstoken", action.payload.authorization);
      sessionStorage.setItem("tokenExpireTime", action.payload.tokenExpireTime);
    },
    getTokenExpiredDataFailure: (state, action) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload;
    },
    resetLoginData: state => {
      state.codeStatus = "pending";
      state.error = "";
      state.loginDetails = {};
      state.hasData = false;
    }
  }
});
export const {
  getTokenExpiredDataPending,
  getTokenExpiredDataSuccess,
  getTokenExpiredDataFailure,
  resetLoginData
} = tokenExpiredSlice.actions;
export default tokenExpiredSlice.reducer;