import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectPassengerInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  selectPassengerStatus: {
    status: false,
    message: ""
  },
  selectedPassengerData: {},
  editMode: false
};
const selectPassengerSlice = createSlice({
  name: "selectPassenger",
  initialState,
  reducers: {
    selectPassengerRequest: (state, action) => {
      state.isLoading = true;
    },
    selectPassengerSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.selectPassengerInfo = action.payload;
      state.selectPassengerStatus.status = true;
      state.selectPassengerStatus.message = action?.payload?.message;
    },
    selectPassengerFailure: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.response?.data;
      state.selectPassengerInfo = {};
      state.selectPassengerStatus.status = false;
      state.selectPassengerStatus.message = "";
    },
    resetRemovePassengers: state => {
      state.selectPassengerInfo = {};
      state.selectedPassengerData = {};
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.selectPassengerStatus.status = false;
      state.selectPassengerStatus.message = "";
    },
    setSelectPassengerData: (state, action) => {
      state.selectedPassengerData = action.payload;
    },
    enableEditMode: state => {
      state.editMode = true;
    },
    disableEditMode: state => {
      state.editMode = false;
    }
  }
});
export const {
  selectPassengerRequest,
  selectPassengerSuccess,
  selectPassengerFailure,
  resetRemovePassengers,
  setSelectPassengerData,
  enableEditMode,
  disableEditMode
} = selectPassengerSlice.actions;
export default selectPassengerSlice.reducer;